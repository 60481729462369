.laboix-btn-custom{
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    border: none;
    outline: none !important;
    background-color: #034854;
    color: #fff;
    font-family: var(--laboix-font, "Lexend", sans-serif);
    padding: 10px 23.5px;
    transition: 500ms;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 112.5%;
    border-radius: 5px;
    
}
.laboix-btn-custom:hover{
    transition: 500ms;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 112.5%;
    background-color: #13a8c2;
    color: #fff;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    text-align: center;
    z-index: 1;
}
.container-custom{
    padding-left: 15px;
  padding-right: 15px;
  width: 100%;
    text-align: center;
  &__buttons{
    display: inline-flex;
    flex-wrap: wrap;
    gap: 30px;
    width: fit-content;
    justify-content: space-evenly;
    margin-top: 10px;
    border: 1px solid #c0c0eb;
    padding: 10px;

  }
  }
  @media (min-width: 1200px) {
    .container-custom {
      max-width: 100vw;
    }
  }

  .solution1-page {
    padding: 20px 0px;
    position: relative;
    z-index: 1;
    // background: var(--laboix-white, #fff);
  }
  @media (max-width: 991px) {
    .solution1-page {
      padding: 10px 0px;
    }
  }
  @media (max-width: 991px) {
    .solution1-page {
      padding: 10px 0px;
    }
  }
  .solution1-page--one {
    position: relative;
  }
  .solution1-page--one::after {
    position: absolute;
    top: 0;
    content: "";
    left: 0;
    width: 100%;
    height: 45%;
    z-index: 2;
    // background: var(--laboix-border-color2, #F3F3F3);
  }

  .flex{
    display: flex;
  }
  .displayData{
    border: 1px thin #0EBD66;
    background-color: #c0c0eb;
  }
  .DataAnalytics{
    &__body{
        display: block;
        width: 100vw;
        &__banner{
            height: 65vh;
            background-repeat: round;
        background-image: url("../../images/data_analytics.jpg");
        }
    }
  }

  @media screen and (max-width:500px) {
    .DataAnalytics{
        &__body{
            display: block;
            width: 100vw;
            &__banner{
                height: 30vh;
                background-repeat: round;
            background-image: url("../../images/data_analytics.jpg");
            }
        }
      }
    
  }
  .modal-div{
    cursor: auto;
    font-family: "RobotoDraft", "Roboto", "Helvetica Neue, Helvetica, Arial", sans-serif;
    position: fixed;
    z-index: 1000;
    padding-top: 70px;
    /* left: 0; */
    /* top: 0; */
    width: 100%;
    border-radius: 10px;
    height: 100%;
    text-align: -webkit-center;
    justify-content: center;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  }
  .modal-content{
    background-color: white;
    width: fit-content;
  }
  .modal-header{
    display: flex;
    padding-left: 10px;
    color: var(--laboix-base, #021936);
    font-weight: 700;
    padding-right: 10px;
    border-bottom: 2px solid gray;
  }

  .modal-content-patient-info{
    background-color: white;
    width: 30vw;
  }

  .sec-title-ip {
    margin-top: -1px;
    padding-bottom: 50px;
    animation: topAni 4s ease-in-out infinite;
  }
  @media (min-width: 768px) {
    .sec-title-ip {
      padding-bottom: 15px;
      padding-top: 30px;
    }
  }
  .sec-title-ip--two .sec-title-ip__title {
    color: var(--laboix-white, #fff);
    font-weight: 700;
  }
  .sec-title-ip__img {
    object-fit: cover;
    margin-right: 8px;
  }
  .sec-title-ip__tagline {
    margin: 0;
    color: var(--laboix-secondary, #0EBD66);
    font-size: 14px;
    font-weight: 500;
    line-height: 185.714%;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 4px;
  }
  .sec-title-ip__title {
    margin: 0;
    color: var(--laboix-base, #021936);
    font-size: 40px;
    font-weight: 700;
    line-height: 117.5%;
    letter-spacing: -0.8px;
  }
  @media (max-width: 768px) {
    .sec-title-ip__title {
      font-size: 30px;
    }
  }
  .models{
    display: flex;
    flex-wrap: wrap;
  }
  .model{
    margin: 20px;
    // width: 50%;
    display: block;

  }
  .matrixlabel{
    color: var(--laboix-base, #021936);
    font-size: 16px;
    font-weight: 600;
  }
  .accuracyLabel{
    font-size: 15px;
    font-weight: 500;
    color: green;
  }