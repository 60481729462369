/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Utility
# Cards
# Common
# Form
# Navigations
# Animations
# Mobile Nav
# Search Popup
# Page Header
# Google Map
# Client Carousel
--------------------------------------------------------------*/
:root {
  --laboix-font: "Lexend", sans-serif;
  --laboix-heading-font: "Lexend", sans-serif;
  --laboix-special-font: "Urbanist", sans-serif;
  --laboix-text: #6A6A6A;
  --laboix-text-rgb: 106, 106, 106;
  --laboix-text-gray: #97A5B7;
  --laboix-text-gray-rgb: 151, 165, 183;
  --laboix-base: #021936;
  --laboix-base-rgb: 2, 25, 54;
  --laboix-secondary: #0EBD66;
  --laboix-secondary-rgb: 14, 189, 102;
  --laboix-gray: #6A6A6A;
  --laboix-gray-rgb: 106, 106, 106;
  --laboix-white: #fff;
  --laboix-white-rgb: 255, 255, 255;
  --laboix-black: #000;
  --laboix-black-rgb: 0, 0, 0;
  --laboix-black2: #011126;
  --laboix-black2-rgb: 1, 17, 38;
  --laboix-black3: #111117;
  --laboix-black3-rgb: 17, 17, 23;
  --laboix-border-color: #DBDBDB;
  --laboix-border-color-rgb: 219, 219, 219;
  --laboix-border-color2: #F3F3F3;
  --laboix-border-color2-rgb: 243, 243, 243;
  --laboix-letter-space: 0.1em;
  --laboix-letter-space-xl: 0.2em;
}

/*--------------------------------------------------------------
# Utility
--------------------------------------------------------------*/
.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-120 {
  margin-top: 120px;
}

.mt--60 {
  margin-top: -60px;
}

.mt--120 {
  margin-top: -120px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb--60 {
  margin-bottom: -60px;
}

.mb--120 {
  margin-bottom: -120px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-142 {
  padding-top: 142px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.laboix-btn {
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  border: none;
  outline: none !important;
  background-color: var(--laboix-secondary, #0EBD66);
  color: #fff;
  font-family: var(--laboix-font, "Lexend", sans-serif);
  padding: 19px 41.5px;
  transition: 500ms;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 112.5%;
  background-color: var(--laboix-secondary, #0EBD66);
  color: #fff;
  position: relative;
  overflow: hidden;
  text-align: center;
  z-index: 1;
}
.laboix-btn:hover {
  color: var(--laboix-secondary, #0EBD66);
  background-color: #fff;
}
.laboix-btn::before {
  content: "";
  height: 0px;
  width: 0px;
  background-color: var(--laboix-base, #021936);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 0.5s ease-in-out;
  z-index: -1;
}
.laboix-btn:hover {
  color: var(--laboix-white, #fff);
}
.laboix-btn:hover::before {
  height: 400px;
  width: 200%;
  top: -70%;
  left: -50%;
}
.laboix-btn--submite:hover::before {
  height: 200%;
  width: 200%;
  top: -50%;
  left: -50%;
}
.laboix-btn--base {
  background: var(--laboix-base, #021936);
}
.laboix-btn--base::before {
  background-color: var(--laboix-secondary, #0EBD66);
}
.laboix-btn--base:hover {
  color: var(--laboix-base, #021936);
}
.laboix-btn--white::before {
  background-color: var(--laboix-white, #fff);
}
.laboix-btn--white:hover {
  color: var(--laboix-base, #021936);
}

/*--------------------------------------------------------------
# Common
--------------------------------------------------------------*/
body {
  font-family: var(--laboix-font, "Lexend", sans-serif);
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  line-height: 187.5%;
  font-weight: 500;
  counter-reset: courseNumber;
}

body.locked {
  overflow: hidden;
}

a {
  color: var(--laboix-white, #fff);
  transition: all 400ms ease;
}

a,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--laboix-heading-font, "Lexend", sans-serif);
  color: var(--laboix-base, #021936);
}
@media (max-width: 575px) {
  h1 br,
  h2 br,
  h3 br,
  h4 br,
  h5 br,
  h6 br {
    display: none;
  }
}

@media (max-width: 575px) {
  p br {
    display: none;
  }
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

.container-fluid,
.container {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
.row {
  --bs-gutter-x: 30px;
}

.gutter-y-10 {
  --bs-gutter-y: 10px;
}

.gutter-y-15 {
  --bs-gutter-y: 15px;
}

.gutter-y-20 {
  --bs-gutter-y: 20px;
}

.gutter-y-30 {
  --bs-gutter-y: 30px;
}

.gutter-y-60 {
  --bs-gutter-y: 60px;
}

.gutter-x-20 {
  --bs-gutter-x: 20px;
}

.tabs-box .tabs-content .tab:not(.active-tab) {
  display: none;
}

.bootstrap-select .dropdown-menu {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0;
}
.bootstrap-select .dropdown-item.active,
.bootstrap-select .dropdown-item:active {
  background-color: var(--laboix-base, #021936);
}

.tns-outer .tns-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.tns-outer .tns-controls button {
  width: 45px;
  height: 45px;
  border: 2px solid #f4f4f4;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--laboix-text, #6A6A6A);
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
}

.block-title {
  margin-top: -8px;
  margin-bottom: 50px;
}
.block-title__decor {
  width: 21px;
  height: 14px;
  /* background-image: url(../media/shapes/leaf-1-1.png); */
  background-repeat: no-repeat;
  background-position: top center;
  display: inline-block;
  line-height: 1;
  margin-bottom: -5px;
  position: relative;
  top: -7px;
}
.block-title p {
  margin: 0;
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  line-height: 1;
  margin-bottom: 7px;
}
@media (min-width: 768px) {
  .block-title p {
    font-size: 18px;
  }
}
@media (min-width: 992px) {
  .block-title p {
    font-size: 20px;
  }
}
.block-title h3 {
  margin: 0;
  font-size: 35px;
  color: var(--laboix-black, #000);
  font-family: var(--laboix-special-font, "Urbanist", sans-serif);
}
@media (min-width: 768px) {
  .block-title h3 {
    font-size: 42px;
  }
}
@media (min-width: 992px) {
  .block-title h3 {
    font-size: 50px;
  }
}

.ul-list-one {
  margin-bottom: 0;
}
.ul-list-one li {
  position: relative;
  padding-left: 45px;
  font-size: 16px;
  font-weight: 500;
  color: var(--laboix-black, #000);
}
@media (min-width: 481px) {
  .ul-list-one li {
    font-size: 20px;
  }
}
.ul-list-one li::before {
  content: "\e907";
  color: var(--laboix-base, #021936);
  font-size: 26px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-family: "azino-icon";
}

.preloader {
  position: fixed;
  /* background-color: var(--laboix-black, #000); */
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9991;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
}
.preloader__image {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 60px auto;
  width: 100%;
  height: 100%;
}

/* scroll to top */
.scroll-to-top {
  display: flex;
  align-items: center;
  width: auto;
  height: 35px;
  background: transparent;
  position: fixed;
  bottom: 60px;
  right: -12px;
  z-index: 99;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transform: rotate(-90deg);
  cursor: pointer;
  transition: all 0.2s ease;
}
.scroll-to-top__text {
  display: inline;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 700;
  margin-right: 8px;
  color: var(--laboix-secondary, #0EBD66);
}
.scroll-to-top__wrapper {
  display: inline-block;
  width: 30px;
  height: 4px;
  background-color: var(--laboix-base, #021936);
  position: relative;
  overflow: hidden;
}
.scroll-to-top__inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--laboix-secondary, #0EBD66);
}
.scroll-to-top.show {
  opacity: 1;
  visibility: visible;
  bottom: 70px;
}

/* post paginations */
.post-pagination {
  margin-bottom: 0;
  margin-top: 0px;
}
@media (min-width: 992px) {
  .post-pagination {
    margin-top: 0px;
  }
}
.post-pagination a {
  display: flex;
  width: 45px;
  height: 45px;
  background-color: #eff2f6;
  align-items: center;
  justify-content: center;
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  border-radius: 50%;
  transition: 500ms ease;
}
@media (min-width: 992px) {
  .post-pagination a {
    width: 60px;
    height: 60px;
    font-size: 18px;
  }
}
.post-pagination a:hover {
  background-color: var(--laboix-base, #021936);
  color: #fff;
}
.post-pagination li:first-child a {
  background-color: var(--laboix-base, #021936);
  color: #fff;
}
.post-pagination li:last-child a {
  background-color: var(--laboix-black, #000);
  color: #fff;
}
.post-pagination li + li {
  margin-left: 10px;
}

.laboix-owl__carousel--with-shadow .owl-stage-outer {
  overflow: visible;
}
.laboix-owl__carousel--with-shadow .owl-item {
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease, visibility 500ms ease;
}
.laboix-owl__carousel--with-shadow .owl-item.active {
  opacity: 1;
  visibility: visible;
}

.laboix-owl__carousel--basic-nav.owl-carousel .owl-nav {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 60px;
}
.laboix-owl__carousel--basic-nav.owl-carousel .owl-nav button {
  border: none;
  outline: none;
  border-radius: 50%;
  margin: 0;
  padding: 0;
}
.laboix-owl__carousel--basic-nav.owl-carousel .owl-nav button span {
  border: none;
  outline: none;
  width: 50px;
  height: 50px;
  background-color: var(--laboix-gray, #f9f6f1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--laboix-text, #6A6A6A);
  border-radius: 50%;
  font-size: 14px;
  color: var(--laboix-text, #6A6A6A);
  transition: all 500ms ease;
}
.laboix-owl__carousel--basic-nav.owl-carousel .owl-nav button span:hover {
  background-color: var(--laboix-black, #000);
  color: var(--laboix-white, #fff);
}
.laboix-owl__carousel--basic-nav.owl-carousel .owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 60px;
}
.laboix-owl__carousel--basic-nav.owl-carousel .owl-dots .owl-dot span {
  background-color: var(--laboix-black, #000);
  border: 2px solid var(--laboix-white, #fff);
  box-shadow: 0 0 1px rgba(var(--laboix-black-rgb, 0, 0, 0), 1);
  margin: 0;
}
.laboix-owl__carousel--basic-nav.owl-carousel .owl-dots .owl-dot:hover span, .laboix-owl__carousel--basic-nav.owl-carousel .owl-dots .owl-dot.active span {
  background-color: var(--laboix-black, #000);
  border: 2px solid var(--laboix-black, #000);
  box-shadow: 0 0 1px rgba(var(--laboix-black-rgb, 0, 0, 0), 1);
}
.laboix-owl__carousel--basic-nav.owl-carousel .owl-nav.disabled + .owl-dots {
  margin-top: 60px;
}

.sec-title {
  margin-top: -1px;
  padding-bottom: 50px;
}
@media (min-width: 768px) {
  .sec-title {
    padding-bottom: 46px;
  }
}
.sec-title--two .sec-title__title {
  color: var(--laboix-white, #fff);
  font-weight: 700;
}
.sec-title__img {
  object-fit: cover;
  margin-right: 8px;
}
.sec-title__tagline {
  margin: 0;
  color: var(--laboix-secondary, #0EBD66);
  font-size: 14px;
  font-weight: 500;
  line-height: 185.714%;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 4px;
}
.sec-title__title {
  margin: 0;
  color: var(--laboix-base, #021936);
  font-size: 40px;
  font-weight: 700;
  line-height: 117.5%;
  letter-spacing: -0.8px;
}
@media (max-width: 768px) {
  .sec-title__title {
    font-size: 30px;
  }
}

.ui-datepicker .ui-datepicker-header {
  background-image: none;
  background-color: var(--laboix-black, #000);
  color: var(--laboix-white, #fff);
  font-family: var(--laboix-font, "Lexend", sans-serif);
}

.ui-datepicker-calendar th span {
  font-family: var(--laboix-font, "Lexend", sans-serif);
}
.ui-datepicker-calendar td {
  background-color: var(--laboix-gray, #f9f6f1);
  background-image: none;
  font-family: var(--laboix-font, "Lexend", sans-serif);
  color: var(--laboix-text, #6A6A6A);
}
.ui-datepicker-calendar td a {
  border-color: var(--laboix-border-color, #DBDBDB);
  background-color: var(--laboix-gray, #f9f6f1);
  background-image: none;
}
.ui-datepicker-calendar .ui-state-default,
.ui-datepicker-calendar .ui-widget-content .ui-state-default,
.ui-datepicker-calendar .ui-widget-header .ui-state-default {
  border-color: var(--laboix-border-color, #DBDBDB);
  background-color: var(--laboix-gray, #f9f6f1);
  background-image: none;
  color: var(--laboix-text, #6A6A6A);
  padding: 10px 5px;
  text-align: center;
  line-height: 1em;
}
.ui-datepicker-calendar .ui-state-default:hover,
.ui-datepicker-calendar .ui-widget-content .ui-state-default:hover,
.ui-datepicker-calendar .ui-widget-header .ui-state-default:hover {
  color: var(--laboix-white, #fff);
  background-color: var(--laboix-base, #021936);
}
.ui-datepicker-calendar .ui-state-highlight,
.ui-datepicker-calendar .ui-widget-content .ui-state-highlight,
.ui-datepicker-calendar .ui-widget-header .ui-state-highlight {
  color: var(--laboix-white, #fff);
  background-color: var(--laboix-base, #021936);
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  background-image: none;
  background-color: var(--laboix-white, #fff);
  color: var(--laboix-black, #000);
}
.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:hover {
  background-color: var(--laboix-base, #021936);
  color: var(--laboix-white, #fff);
  top: 2px;
}

.ui-datepicker .ui-datepicker-prev:hover {
  left: 2px;
}

.ui-datepicker .ui-datepicker-next:hover {
  right: 2px;
}

.logo-laboix img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -ms-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

/*--------------------------------------------------------------
# Cards
--------------------------------------------------------------*/
.video-one {
  position: relative;
  background-color: var(--laboix-black, #000);
  padding: 100px 0;
}
.video-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--laboix-black, #000);
  background-size: cover;
  background-position: center center;
  opacity: 0.5;
}
.video-one .container {
  position: relative;
  text-align: center;
}
.video-one__btn {
  width: 145px;
  height: 145px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
}
.video-one__btn .video-popup {
  font-size: 24px;
  color: var(--laboix-white, #fff);
  transition: all 500ms ease;
  position: relative;
  z-index: 10;
}
.video-one__btn .video-popup:hover {
  color: var(--laboix-base, #021936);
}
.video-one__btn .curved-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 145px;
  height: 145px;
  transform-origin: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: textRotate 15s linear 0s forwards infinite alternate;
}
.video-one__btn .curved-circle--item {
  width: 145px;
}
.video-one__btn .curved-circle--item span {
  text-transform: uppercase;
  font-size: 14px;
  color: var(--laboix-white, #fff);
  letter-spacing: 0.4em;
}
.video-one__title {
  margin: 0;
  text-transform: uppercase;
  color: var(--laboix-white, #fff);
  font-size: 40px;
  line-height: 1.2em;
  margin-bottom: 40px;
  margin-top: 30px;
}
@media (min-width: 768px) {
  .video-one__title {
    font-size: 50px;
  }
}
@media (min-width: 992px) {
  .video-one__title {
    font-size: 60px;
    margin-top: 20px;
    margin-bottom: 35px;
  }
}
.video-one__link::before {
  background-color: var(--laboix-base, #021936);
}

.video-two {
  position: relative;
  background-color: var(--laboix-black, #000);
  padding: 143px 0 320px;
}
@media (max-width: 767px) {
  .video-two {
    padding: 100px 0 270px;
  }
  .video-two .text-end {
    text-align: left !important;
  }
}
.video-two__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--laboix-black, #000);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.5;
}
.video-two__shape {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: auto;
}
@media (max-width: 1199px) {
  .video-two__shape {
    display: none;
  }
}
.video-two .container {
  position: relative;
}
.video-two__btn {
  width: 145px;
  height: 145px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  margin-top: 42px;
}
.video-two__btn .video-popup {
  font-size: 24px;
  color: var(--laboix-white, #fff);
  transition: all 500ms ease;
  position: relative;
  z-index: 10;
}
.video-two__btn .video-popup:hover {
  color: var(--laboix-base, #021936);
}
.video-two__btn .curved-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 145px;
  height: 145px;
  transform-origin: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: textRotate 15s linear 0s forwards infinite alternate;
}
.video-two__btn .curved-circle--item {
  width: 145px !important;
  height: 145px !important;
}
.video-two__btn .curved-circle--item span {
  text-transform: uppercase;
  font-size: 14px;
  color: var(--laboix-white, #fff);
  letter-spacing: 0.4em;
}
.video-two__title {
  margin: 0;
  text-transform: uppercase;
  color: var(--laboix-white, #fff);
  font-size: 40px;
  line-height: 1.2em;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .video-two__title {
    font-size: 50px;
  }
}
@media (min-width: 992px) {
  .video-two__title {
    font-size: 60px;
    margin-bottom: 35px;
  }
}
.video-two__link::before {
  background-color: var(--laboix-base, #021936);
}

.team-card {
  position: relative;
  width: 100%;
  margin-bottom: 17px;
}
.team-card__inner {
  position: relative;
  z-index: 1;
}
.team-card__image {
  position: relative;
  z-index: 1;
}
.team-card__image img {
  object-fit: cover;
  width: 100%;
}
.team-card__image::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.7);
  opacity: 0;
  transition: all 0.4s ease-in-out;
  visibility: hidden;
}
.team-card__content {
  position: relative;
  z-index: 1;
  margin-top: -75px;
  margin-left: 54px;
  margin-right: 39px;
}
@media (max-width: 1199px) and (min-width: 768px) {
  .team-card__content {
    margin-left: 15px;
    margin-right: 0px;
  }
}
.team-card__content__inner {
  padding: 28px 30px 27px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
  background: var(--laboix-white, #fff);
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.team-card__content::after {
  width: 80px;
  height: 100px;
  content: "";
  position: absolute;
  background: var(--laboix-base, #021936);
  z-index: -1;
  bottom: -16px;
  left: -16px;
  transition: all 0.4s ease-in-out;
}
.team-card__content__title {
  color: var(--laboix-base, #021936);
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
}
.team-card__content__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.team-card__content__title a:hover {
  background-size: 100% 1px;
}
.team-card__content__designation {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.team-card__content__hover {
  position: relative;
}
.team-card__content__hover__icon {
  display: inline-block;
  width: 45px;
  height: 51px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in-out;
  background-color: var(--laboix-secondary, #0EBD66);
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.000000 52.000000"> <g transform="translate(0.000000,52.000000) scale(0.050000,-0.050000)" > <path d="M215 895 l-215 -123 0 -251 1 -251 204 -114 c113 -62 216 -116 229 -120 14 -3 124 48 245 114 l220 120 0 251 1 251 -218 124 c-259 148 -206 148 -467 -1z"/> </g> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.000000 52.000000"> <g transform="translate(0.000000,52.000000) scale(0.050000,-0.050000)" > <path d="M215 895 l-215 -123 0 -251 1 -251 204 -114 c113 -62 216 -116 229 -120 14 -3 124 48 245 114 l220 120 0 251 1 251 -218 124 c-259 148 -206 148 -467 -1z"/> </g> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
}
.team-card__content__hover__icon i {
  color: var(--laboix-white, #fff);
  font-size: 16.66px;
}
.team-card__content__hover__icon:hover {
  background-color: var(--laboix-base, #021936);
}
.team-card__content__hover__social {
  background: var(--laboix-base, #021936);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 14px;
  position: absolute;
  bottom: 70px;
  left: 5px;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  transition: 1000ms ease;
  transform-origin: bottom top;
}
.team-card__content__hover__social a {
  color: var(--laboix-white, #fff);
  line-height: 1;
  font-size: 9px;
}
.team-card__content__hover__social a:hover {
  color: var(--laboix-secondary, #0EBD66);
}
.team-card__content__hover__social a + a {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid rgba(249, 244, 232, 0.2);
}
.team-card__content__hover__social::after {
  position: absolute;
  bottom: -8px;
  left: 36%;
  content: "";
  width: 9.526px;
  height: 8.25px;
  background: var(--laboix-base, #021936);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}
.team-card__content__hover:hover .team-card__content__hover__icon {
  background-color: var(--laboix-base, #021936);
}
.team-card__content__hover:hover .team-card__content__hover__social {
  transform: translateY(0%);
  opacity: 1;
  visibility: visible;
}
.team-card:hover .team-card__content::after {
  background: var(--laboix-secondary, #0EBD66);
}
.team-card:hover .team-card__image::after {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767px) {
  .team-card {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

.team-one {
  padding: 120px 0px;
  background: var(--laboix-white, #fff);
}
@media (max-width: 991px) {
  .team-one {
    padding: 100px 0px;
  }
}
@media (max-width: 767px) {
  .team-one {
    padding: 80px 0px;
  }
}
.team-one--home {
  padding-bottom: 0px;
}
.team-one--home .container {
  max-width: 1570px;
  margin-left: auto;
  margin-right: auto;
}
.team-one__carousel .owl-nav {
  display: none !important;
}

.team-details {
  padding-top: 80px;
  padding-bottom: 80px;
  border-bottom: 1px solid var(--laboix-border-color, #DBDBDB);
}
@media (min-width: 992px) {
  .team-details {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
.team-details__image {
  display: inline-block;
  position: relative;
  margin-right: -30px;
}
.team-details__image img {
  max-width: 100%;
}
@media (max-width: 1199px) {
  .team-details__image {
    margin-right: 0px;
  }
}
@media (max-width: 768px) {
  .team-details__content {
    margin-top: 30px;
  }
}
@media (min-width: 1199px) {
  .team-details__content {
    padding-left: 70px;
  }
}
.team-details__content__subtitle {
  margin-top: -6px;
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.team-details__content__title {
  margin-top: -5px;
  font-size: 30px;
  margin-bottom: 22px;
  color: var(--laboix-base, #021936);
  font-weight: 700;
  line-height: 117.5%;
  letter-spacing: -0.8px;
}
@media (min-width: 992px) {
  .team-details__content__title {
    font-size: 40px;
  }
}
.team-details__content__text {
  color: var(--laboix-text, #6A6A6A);
  margin-bottom: 22px;
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
}
@media (min-width: 992px) {
  .team-details__content__text {
    margin-bottom: 32px;
  }
}
.team-details__content__highlight {
  background-color: var(--laboix-border-color2, #F3F3F3);
  position: relative;
  padding: 10px 20px;
  margin-bottom: 30px;
  transition: all 0.4s ease-in-out;
  border-left: 6px solid var(--laboix-secondary, #0EBD66);
}
.team-details__content__highlight__text {
  max-width: 492px;
  display: block;
  width: 100%;
  color: var(--laboix-base, #021936);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  transition: all 0.4s ease-in-out;
}
@media (min-width: 992px) {
  .team-details__content__highlight {
    padding: 22px 40px;
    margin-bottom: 38px;
  }
  .team-details__content__highlight__text {
    font-size: 20px;
  }
}
.team-details__content__highlight:hover {
  background: var(--laboix-text, #6A6A6A);
}
.team-details__content__highlight:hover .team-details__content__highlight__text {
  color: var(--laboix-white, #fff);
}
.team-details__list {
  margin-bottom: 32px;
}
.team-details__list > li {
  margin-bottom: 11px;
  color: var(--laboix-base, #021936);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.team-details__list > li a {
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
  color: var(--laboix-base, #021936);
}
.team-details__list > li a:hover {
  background-size: 100% 1px;
}
.team-details__list > li span {
  display: inline-block;
  color: var(--laboix-text, #6A6A6A);
}
.team-details__list > li:last-child {
  margin-bottom: 0;
}
.team-details .team-skills {
  margin-bottom: 40px;
}
.team-details .team-skills__progress + .team-skills__progress {
  margin-top: 15px;
}
@media (min-width: 992px) {
  .team-details .team-skills__progress + .team-skills__progress {
    margin-top: 18px;
  }
}
.team-details .team-skills__progress__title {
  color: var(--laboix-base, #021936);
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 12px;
  text-shadow: 0 0 0 0.1px currentColor;
}
.team-details .team-skills__progress__bar {
  width: 100%;
  height: 10px;
  position: relative;
  background-color: var(--laboix-border-color2, #F3F3F3);
}
.team-details .team-skills__progress__inner {
  position: absolute;
  height: 10px;
  background-color: var(--laboix-secondary, #0EBD66);
  transition: all 700ms linear;
  width: 0px;
}
.team-details .team-skills__progress__number {
  position: absolute;
  bottom: calc(100% + 5px);
  right: 0;
  margin-bottom: 8px;
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.team-details__social {
  display: flex;
  flex-wrap: wrap;
  gap: 19px;
}
.team-details__social a {
  width: 36px;
  height: 36px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--laboix-border-color2, #F3F3F3);
  font-size: 14px;
  color: var(--laboix-base, #021936);
  line-height: 1;
  transition: all 500ms ease;
}
.team-details__social a:hover {
  background-color: var(--laboix-base, #021936);
  color: var(--laboix-white, #fff);
}

.team-skills-one {
  padding: 120px 0 110px 0px;
}
@media (max-width: 767px) {
  .team-skills-one {
    padding: 60px 0;
  }
}
.team-skills-one__title {
  font-size: 25px;
  margin-bottom: 10px;
  color: var(--laboix-base, #021936);
  font-weight: 700;
  line-height: 117.5%;
  letter-spacing: -0.8px;
}
@media (min-width: 768px) {
  .team-skills-one__title {
    font-size: 30px;
  }
}
@media (min-width: 992px) {
  .team-skills-one__title {
    font-size: 40px;
    margin-bottom: 20px;
  }
}
.team-skills-one__text {
  max-width: 542PX;
  width: 100%;
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  line-height: 187.5%;
  font-size: 16px;
  font-weight: 500;
}
.team-skills-one__certificates {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 21px;
}
.team-skills-one__certificates__item {
  max-width: 176px;
  width: 100%;
  cursor: pointer;
}
.team-skills-one__certificates__item img {
  object-fit: cover;
  filter: grayscale(100%);
  transition: all 0.5s ease-in-out;
}
.team-skills-one__certificates__item:hover img {
  object-fit: cover;
  filter: grayscale(0%);
}

.team-form-one {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 767px) {
  .team-form-one {
    padding: 80px 0;
  }
}
.team-form-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
}
.team-form-one .container {
  position: relative;
  max-width: 898px;
}
.team-form-one .sec-title {
  text-align: center;
}
.team-form-one .form-one .bootstrap-select > .dropdown-toggle,
.team-form-one .form-one input[type=text],
.team-form-one .form-one input[type=email],
.team-form-one .form-one textarea {
  background-color: var(--laboix-white, #fff);
}
.team-form-one .form-one textarea {
  height: 176px;
}
.team-form-one .form-one .laboix-btn {
  padding: 19px 40.5px;
}

.blog-card {
  background: var(--laboix-white, #fff);
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
}
.blog-card__image {
  position: relative;
  z-index: 1;
}
.blog-card__image__item {
  max-height: 240px;
  width: 100%;
  overflow: hidden;
}
.blog-card__image__item img {
  object-fit: cover;
  width: 100%;
}
.blog-card__image__link {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.7);
  visibility: hidden;
  opacity: 0;
}
.blog-card__image__link::before {
  content: "";
  width: 32px;
  height: 2px;
  background-color: var(--laboix-white, #fff);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.blog-card__image__link::after {
  content: "";
  width: 2px;
  height: 32px;
  background-color: var(--laboix-white, #fff);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.blog-card__date {
  position: absolute;
  bottom: -26px;
  right: 31px;
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.000000 99.000000"> <g transform="translate(0.000000,99.000000) scale(0.050000,-0.050000)"> <path d="M424 1713 l-423 -243 4 -489 5 -489 410 -236 c226 -129 419 -235 430 -235 11 0 205 106 430 237 l410 237 5 487 5 488 -314 180 c-173 99 -364 208 -426 243 l-112 62 -424 -242z"/> </g> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.000000 99.000000"> <g transform="translate(0.000000,99.000000) scale(0.050000,-0.050000)"> <path d="M424 1713 l-423 -243 4 -489 5 -489 410 -236 c226 -129 419 -235 430 -235 11 0 205 106 430 237 l410 237 5 487 5 488 -314 180 c-173 99 -364 208 -426 243 l-112 62 -424 -242z"/> </g> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  background: var(--laboix-secondary, #0EBD66);
  display: inline-block;
  padding: 30px 30px 20px 30px;
  color: var(--laboix-white, #fff);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.4px;
  z-index: 1;
  transition: all 0.5s ease-in-out;
}
.blog-card__date__day {
  margin-top: -7px;
  margin-bottom: -8px;
  display: block;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
}
.blog-card__content {
  padding: 30px 40px 40px 40px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1199px) and (min-width: 991px) {
  .blog-card__content {
    padding: 30px 30px 40px;
  }
}
.blog-card__content__btn {
  background: var(--laboix-border-color2, #F3F3F3);
  position: relative;
  z-index: 1;
}
.blog-card__content__btn a {
  padding: 10px 30px;
  display: block;
  color: var(--laboix-text, #6A6A6A);
  font-size: 14px;
  font-weight: 600;
  line-height: 178.571%;
  text-transform: capitalize;
  position: relative;
  transition: all 0.5s ease-in-out;
}
.blog-card__content__btn a i {
  top: 35%;
  position: absolute;
  font-size: 12px;
  right: 30px;
}
.blog-card__content__btn::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 100%;
  background-color: var(--laboix-base, #021936);
  transition: all 0.5s ease-in-out;
  z-index: -1;
  left: 0%;
  top: 0;
}
.blog-card__content__btn:hover a {
  color: var(--laboix-white, #fff);
}
.blog-card__content__btn:hover::before {
  width: 100%;
}
.blog-card__content::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: var(--laboix-secondary, #0EBD66);
  transition: all 0.5s ease-in-out;
  z-index: -1;
  left: -100%;
  top: 0;
}
.blog-card__content::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: var(--laboix-secondary, #0EBD66);
  transition: all 0.5s ease-in-out;
  z-index: -1;
  right: -100%;
  top: 0;
}
.blog-card__author {
  margin-bottom: 5px;
  margin-top: -4px;
}
.blog-card__author__item {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
.blog-card__author__thumb {
  max-width: 35px;
  width: 100%;
  height: 35px;
  overflow: hidden;
  border-radius: 50%;
}
.blog-card__author__thumb img {
  object-fit: cover;
  width: 100%;
}
.blog-card__author__name {
  color: var(--laboix-base, #021936);
  font-size: 16px;
  font-weight: 700;
  line-height: 162.5%;
  margin-bottom: -9px;
  transition: all 0.4s ease-in-out;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.blog-card__author__name:hover {
  background-size: 100% 1px;
}
.blog-card__author__deg {
  color: var(--laboix-text, #6A6A6A);
  font-size: 12px;
  font-weight: 500;
  line-height: 208.333%;
  text-transform: capitalize;
  transition: all 0.4s ease-in-out;
}
.blog-card__title {
  color: var(--laboix-base, #021936);
  font-size: 24px;
  font-weight: 700;
  line-height: 145.833%;
  letter-spacing: -0.48px;
  margin-bottom: 25px;
}
@media (max-width: 1199px) and (min-width: 758px) {
  .blog-card__title {
    font-size: 20px;
  }
}
.blog-card__title a {
  color: currentColor;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.blog-card__title a:hover {
  background-size: 100% 1px;
}
.blog-card__title a:hover {
  color: var(--laboix-white, #fff);
}
.blog-card:hover .blog-card__content__btn {
  background: var(--laboix-white, #fff);
}
.blog-card:hover .blog-card__content::before {
  left: 0%;
}
.blog-card:hover .blog-card__content::after {
  right: 0%;
}
.blog-card:hover .blog-card__author__name, .blog-card:hover .blog-card__author__deg {
  color: var(--laboix-white, #fff);
}
.blog-card:hover .blog-card__title {
  color: var(--laboix-white, #fff);
}
.blog-card:hover .blog-card__date {
  background: var(--laboix-base, #021936);
}
.blog-card:hover .blog-card__image__link {
  visibility: visible;
  opacity: 1;
}

.blog-card__two {
  margin-bottom: 60px;
}
.blog-card__two__image {
  max-height: 449px;
  width: 100%;
  position: relative;
  z-index: 1;
  margin-bottom: 25px;
}
.blog-card__two__image img {
  object-fit: cover;
  width: 100%;
  max-height: 449px;
}
.blog-card__two__image__item {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.7);
  visibility: hidden;
  opacity: 0;
}
.blog-card__two__image__item::before {
  content: "";
  width: 32px;
  height: 2px;
  background-color: var(--laboix-white, #fff);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.blog-card__two__image__item::after {
  content: "";
  width: 2px;
  height: 32px;
  background-color: var(--laboix-white, #fff);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.blog-card__two__image:hover .blog-card__two__image__item {
  visibility: visible;
  opacity: 1;
}
.blog-card__two__image:hover .blog-card__two__date {
  background: var(--laboix-base, #021936);
}
.blog-card__two__date {
  position: absolute;
  bottom: -24px;
  right: 38px;
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.000000 99.000000"> <g transform="translate(0.000000,99.000000) scale(0.050000,-0.050000)"> <path d="M424 1713 l-423 -243 4 -489 5 -489 410 -236 c226 -129 419 -235 430 -235 11 0 205 106 430 237 l410 237 5 487 5 488 -314 180 c-173 99 -364 208 -426 243 l-112 62 -424 -242z"/> </g> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.000000 99.000000"> <g transform="translate(0.000000,99.000000) scale(0.050000,-0.050000)"> <path d="M424 1713 l-423 -243 4 -489 5 -489 410 -236 c226 -129 419 -235 430 -235 11 0 205 106 430 237 l410 237 5 487 5 488 -314 180 c-173 99 -364 208 -426 243 l-112 62 -424 -242z"/> </g> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  background: var(--laboix-secondary, #0EBD66);
  display: inline-block;
  padding: 30px 30px 20px 30px;
  color: var(--laboix-white, #fff);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.56px;
  z-index: 1;
  transition: all 0.5s ease-in-out;
}
.blog-card__two__date__day {
  margin-top: -7px;
  margin-bottom: -8px;
  display: block;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
}
.blog-card__two__meta {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.blog-card__two__meta__item {
  color: var(--laboix-text, #6A6A6A);
  font-size: 14px;
  font-weight: 500;
  line-height: 214.286%;
}
.blog-card__two__meta__item:last-child::before {
  content: "|";
  margin-left: 13px;
  margin-right: 13px;
  font-weight: 300;
}
.blog-card__two__meta__item a {
  color: inherit;
}
.blog-card__two__meta__item a i {
  color: var(--laboix-secondary, #0EBD66);
  font-size: 15px;
  margin-right: 2px;
}
.blog-card__two__meta__item a:hover {
  color: var(--laboix-base, #021936);
}
.blog-card__two__title {
  color: var(--laboix-black3, #111117);
  font-family: var(--laboix-special-font, "Urbanist", sans-serif);
  font-size: 30px;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 15px;
  text-shadow: 0 0 0.2px currentColor;
}
.blog-card__two__title--two {
  font-family: var(--laboix-heading-font, "Lexend", sans-serif);
  font-weight: 700;
  text-shadow: 0 0 0px currentColor;
}
.blog-card__two__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.blog-card__two__title a:hover {
  background-size: 100% 1px;
}
.blog-card__two__title a:hover {
  color: var(--laboix-secondary, #0EBD66);
}
.blog-card__two__text {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
  margin-bottom: 30px;
}
.blog-card__two__text--two {
  margin-bottom: 0;
}
.blog-card__two__link {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 16px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-one {
  padding: 120px 0px;
  background: var(--laboix-white, #fff);
}
@media (max-width: 991px) {
  .blog-one {
    padding: 100px 0px;
  }
}
@media (max-width: 768px) {
  .blog-one {
    padding: 80px 0px;
  }
}
.blog-one .owl-nav {
  display: none !important;
}

.blog-two {
  padding: 120px 0px;
}
@media (max-width: 991px) {
  .blog-two {
    padding: 100px 0;
  }
}
@media (max-width: 767px) {
  .blog-two {
    padding: 80px 0;
  }
}
.blog-two__card {
  position: relative;
  z-index: 1;
}
.blog-two__card__list__item {
  display: flex;
  align-items: center;
  gap: 30px;
}
.blog-two__card__list__item:hover .blog-two__card__list__thumb__link {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 575px) {
  .blog-two__card__list__item {
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }
}
.blog-two__card__list__item + .blog-two__card__list__item {
  margin-top: 30px;
}
.blog-two__card__list__thumb {
  max-width: 170px;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 1;
}
.blog-two__card__list__thumb__link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.7);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}
.blog-two__card__list__content__meta {
  display: flex;
  align-items: center;
  gap: 20px;
}
.blog-two__card__list__content__meta__item {
  color: var(--laboix-text, #6A6A6A);
  font-size: 14px;
  font-weight: 500;
  line-height: 214.286%;
}
.blog-two__card__list__content__meta__item a {
  color: inherit;
}
.blog-two__card__list__content__meta__item a:hover {
  color: var(--laboix-base, #021936);
}
.blog-two__card__list__content__meta__item i {
  color: var(--laboix-secondary, #0EBD66);
  margin-right: 8px;
  font-size: 16px;
}
.blog-two__card__list__content__title {
  color: var(--laboix-base, #021936);
  font-size: 24px;
  font-weight: 700;
  line-height: 145.833%;
  letter-spacing: -0.48px;
  margin-bottom: 0;
  padding-bottom: 0;
}
.blog-two__card__list__content__title a {
  color: inherit;
}
.blog-two__card__list__content__title a:hover {
  color: var(--laboix-secondary, #0EBD66);
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.blog-two__card__list__content__title a:hover:hover {
  background-size: 100% 1px;
}
.blog-two__card__list__content__link {
  position: relative;
  z-index: 1;
}
.blog-two__card__list__content__link__btn {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: 218.75%;
}
.blog-two__card__list__content__link__btn:hover {
  color: var(--laboix-secondary, #0EBD66);
}
.blog-two__card__list__content__link::after {
  content: "";
  width: 60%;
  height: 1px;
  background: var(--laboix-border-color, #DBDBDB);
  position: absolute;
  top: 48%;
  right: 0;
}
.blog-two__card:hover .blog-two__thumb__link {
  visibility: visible;
  opacity: 1;
}
.blog-two__thumb {
  position: relative;
  z-index: 1;
}
.blog-two__thumb img {
  width: 100%;
  object-fit: cover;
}
@media (max-width: 767px) {
  .blog-two__thumb img {
    height: 250px;
    width: 100%;
  }
}
.blog-two__thumb__link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.7);
  transition: all 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
}
.blog-two__content {
  background: var(--laboix-white, #fff);
  padding: 1px 40px 40px;
  display: inline-block;
  position: absolute;
  z-index: 1;
  bottom: 50px;
}
@media (max-width: 767px) {
  .blog-two__content {
    box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
    position: relative;
    bottom: 0;
  }
}
@media (max-width: 575px) {
  .blog-two__content {
    padding: 1px 20px 20px;
  }
}
.blog-two__content__date {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.000000 99.000000"> <g transform="translate(0.000000,99.000000) scale(0.050000,-0.050000)"> <path d="M424 1713 l-423 -243 4 -489 5 -489 410 -236 c226 -129 419 -235 430 -235 11 0 205 106 430 237 l410 237 5 487 5 488 -314 180 c-173 99 -364 208 -426 243 l-112 62 -424 -242z"/> </g> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.000000 99.000000"> <g transform="translate(0.000000,99.000000) scale(0.050000,-0.050000)"> <path d="M424 1713 l-423 -243 4 -489 5 -489 410 -236 c226 -129 419 -235 430 -235 11 0 205 106 430 237 l410 237 5 487 5 488 -314 180 c-173 99 -364 208 -426 243 l-112 62 -424 -242z"/> </g> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 85px;
  height: 98px;
  margin-right: 0;
  margin-left: auto;
  margin-top: -48px;
  position: relative;
  z-index: 1;
  background: var(--laboix-secondary, #0EBD66);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--laboix-white, #fff);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.56px;
  text-transform: uppercase;
}
.blog-two__content__date__day {
  color: var(--laboix-white, #fff);
  letter-spacing: 0;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 0;
  margin-bottom: 0;
  display: block;
}
.blog-two__content__comment {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: -15px;
  margin-bottom: 5px;
}
.blog-two__content__comment i {
  color: var(--laboix-secondary, #0EBD66);
}
.blog-two__content__comment__text {
  color: var(--laboix-text, #6A6A6A);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 214.286%;
}
.blog-two__content__title {
  max-width: 440px;
  color: var(--laboix-base, #021936);
  font-size: 30px;
  font-weight: 700;
  line-height: 116.667%;
  letter-spacing: -0.6px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(2, 25, 54, 0.2);
}
.blog-two__content__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.blog-two__content__title a:hover {
  background-size: 100% 1px;
}
.blog-two__content__title a:hover {
  color: var(--laboix-secondary, #0EBD66);
}
@media (max-width: 575px) {
  .blog-two__content__title {
    font-size: 25px;
  }
}
.blog-two__content__author {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -5px;
  margin-top: 15px;
}
.blog-two__content__author__item {
  display: flex;
  align-items: center;
  gap: 10px;
}
.blog-two__content__author__name {
  color: var(--laboix-base, #021936);
  font-size: 16px;
  font-weight: 700;
  line-height: 162.5%;
  padding-bottom: 0;
  margin-bottom: 5px;
  transition: all 0.4s ease-in-out;
}
.blog-two__content__author__name:hover {
  color: var(--laboix-secondary, #0EBD66);
}
.blog-two__content__author__deg {
  color: var(--laboix-text, #6A6A6A);
  font-size: 14px;
  font-weight: 500;
  line-height: 178.571%;
  margin-top: -10px;
  margin-bottom: 0;
  padding-bottom: 0;
  display: block;
}
.blog-two__content__link__btn {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: 218.75%;
}
.blog-two__content__link__btn:hover {
  color: var(--laboix-secondary, #0EBD66);
}

.pronation_widget ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding-left: 0;
  margin-bottom: 0;
}
.pronation_widget ul a {
  display: inline-block;
  padding: 0px 19px;
  border: 1px solid var(--laboix-border-color, #DBDBDB);
  color: var(--laboix-base, #021936);
  font-size: 20px;
  font-weight: 600;
  line-height: 235%;
  transition: all 500ms ease-in-out;
}
.pronation_widget ul a.active, .pronation_widget ul a:hover {
  background-color: var(--laboix-secondary, #0EBD66);
  color: var(--laboix-white, #fff);
  border: 1px solid var(--laboix-secondary, #0EBD66);
}

/*--------------------------------------------------------------
# Form
--------------------------------------------------------------*/
.form-one__group {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin: 0;
}
@media (min-width: 576px) {
  .form-one__group {
    grid-template-columns: repeat(2, 1fr);
  }
}
.form-one__control {
  border: none;
  width: auto;
  height: auto;
  border-radius: 0;
  padding: 0;
  position: relative;
}
.form-one__control__icon {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  font-size: 14px;
}
.form-one__control--full {
  grid-column-start: 1;
  grid-column-end: -1;
}
.form-one .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
}
.form-one .bootstrap-select > .dropdown-toggle {
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  border: none;
  outline: none !important;
  color: var(--laboix-text, #6A6A6A);
  font-size: 14px;
}
.form-one .bootstrap-select > .dropdown-toggle,
.form-one input[type=text],
.form-one input[type=email],
.form-one textarea {
  display: block;
  width: 100%;
  height: 58px;
  background-color: var(--laboix-border-color2, #F3F3F3);
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  border: none;
  outline: none;
  padding-left: 30px;
  padding-right: 30px;
}
.form-one .bootstrap-select > .dropdown-toggle::placeholder,
.form-one input[type=text]::placeholder,
.form-one input[type=email]::placeholder,
.form-one textarea::placeholder {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.form-one textarea {
  height: 210px;
  padding-top: 20px;
}
.form-one .bootstrap-select > .dropdown-toggle {
  display: flex;
  align-items: center;
}
.form-one .bootstrap-select > .dropdown-toggle .filter-option {
  display: flex;
  align-items: center;
}

/*--------------------------------------------------------------
# Custom Cursor
--------------------------------------------------------------*/
.custom-cursor__cursor {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: 1px solid var(--laboix-base, #021936);
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  position: fixed;
  pointer-events: none;
  left: 0;
  top: 0;
  -webkit-transform: translate(calc(-50% + 5px), -50%);
  transform: translate(calc(-50% + 5px), -50%);
  z-index: 999991;
}
.custom-cursor__cursor-two {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--laboix-base, #021936);
  opacity: 0.3;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
  -webkit-transition: width 0.3s, height 0.3s, opacity 0.3s;
  transition: width 0.3s, height 0.3s, opacity 0.3s;
  z-index: 999991;
}
.custom-cursor__hover {
  background-color: var(--laboix-base, #021936);
  opacity: 0.4;
}
.custom-cursor__innerhover {
  width: 25px;
  height: 25px;
  opacity: 0.4;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.main-footer {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.main-footer__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.main-footer__inner {
  padding: 120px 0px 80px 0px;
  position: relative;
  z-index: 1;
}
@media (max-width: 991px) {
  .main-footer__inner {
    padding: 100px 0px 40px 0px;
  }
}
@media (max-width: 768px) {
  .main-footer__inner {
    padding: 80px 0px 20px 0px;
  }
}
.main-footer .container {
  position: relative;
}
.main-footer--one {
  background: var(--laboix-base, #021936);
}
.main-footer__bottom {
  text-align: center;
  background: #02142C;
}
.main-footer__bottom__inner {
  padding: 30px 0;
  position: relative;
  z-index: 1;
}
.main-footer__copyright {
  color: var(--laboix-text-gray, #97A5B7);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
  padding-bottom: 0;
}
.main-footer__shape {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  animation: scale 4s ease-in-out infinite;
}
.main-footer__shape--two {
  display: inline-block;
  left: auto;
  left: 0;
  z-index: 0;
  animation: topAni 4s ease-in-out infinite;
}

.footer-widget {
  margin-bottom: 40px;
}
.footer-widget__logo {
  display: inline-flex;
  margin-bottom: 25px;
}
.footer-widget__newsletter {
  position: relative;
  width: 100%;
  gap: 10px;
  align-items: center;
  display: flex;
  z-index: 1;
}
.footer-widget__newsletter input[type=email] {
  width: 100%;
  display: block;
  border: none;
  outline: none;
  height: 56px;
  border-radius: 6px;
  background-color: var(--laboix-white, #fff);
  color: var(--laboix-text-gray, #97A5B7);
  font-size: 14px;
  font-weight: 500;
  padding-left: 30px;
  padding-right: 20px;
  transition: all 500ms ease;
}
.footer-widget__newsletter input[type=email]::placeholder {
  color: var(--laboix-text-gray, #97A5B7);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
}
.footer-widget__newsletter button[type=submit] {
  background-color: var(--laboix-secondary, #0EBD66);
  width: auto;
  height: auto;
  border: none;
  outline: none;
  color: var(--laboix-white, #fff);
  font-size: 22px;
  transition: all 500ms ease;
  padding: 15px 20px;
  border-radius: 6px;
}
.footer-widget__newsletter button[type=submit]::before {
  background-color: var(--laboix-white, #fff);
}
.footer-widget__newsletter button[type=submit]:hover {
  color: var(--laboix-secondary, #0EBD66);
}
.footer-widget__title {
  color: var(--laboix-white, #fff);
  font-size: 20px;
  font-weight: 500;
  line-height: 147.036%;
  letter-spacing: -0.8px;
  margin-top: -5px;
  margin-bottom: 24px;
}
.footer-widget__links {
  margin-top: -10px;
  margin-bottom: -13px;
}
.footer-widget__links li {
  color: var(--laboix-text-gray, #97A5B7);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
}
.footer-widget__links li a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.footer-widget__links li a:hover {
  background-size: 100% 1px;
}
.footer-widget__links li a:hover {
  color: var(--laboix-white, #fff);
}
.footer-widget__links li + li {
  margin-top: 5px;
}
.footer-widget__info {
  position: relative;
  z-index: 1;
}
.footer-widget__info__item__text {
  margin-top: -7px;
  color: var(--laboix-text-gray, #97A5B7);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
  margin-bottom: 5px;
  display: block;
  max-width: 246px;
  width: 100%;
}
.footer-widget__info__item a {
  color: var(--laboix-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 187.5%;
}
.footer-widget__info__item a:hover {
  color: var(--laboix-secondary, #0EBD66);
}
.footer-widget__info__item i {
  font-size: 16px;
  color: var(--laboix-secondary, #0EBD66);
  margin-right: 15px;
}
.footer-widget__text {
  color: var(--laboix-text-gray, #97A5B7);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 214.286%;
  max-width: 315px;
  width: 100%;
  margin-bottom: 18px;
}
@media (max-width: 575px) {
  .footer-widget__text br {
    display: none;
  }
}
.footer-widget__social {
  display: flex;
  flex-wrap: wrap;
  margin-top: 18px;
  gap: 19px;
}
.footer-widget__social a {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: rgba(157, 157, 157, 0.14);
  font-size: 14px;
  color: var(--laboix-white, #fff);
  transition: all 500ms ease;
}
.footer-widget__social a:hover {
  background-color: var(--laboix-secondary, #0EBD66);
  color: var(--laboix-white, #fff);
}
.footer-widget__time {
  background: #192C46;
  padding: 30px;
}
.footer-widget__time__item {
  margin-top: -7px;
  margin-bottom: -8px;
  display: flex;
  justify-content: space-between;
}
.footer-widget__time__item p {
  color: var(--laboix-text-gray, #97A5B7);
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
}
.footer-widget__time__item p i {
  font-size: 15px;
  color: var(--laboix-secondary, #0EBD66);
}
.footer-widget__time__item + .footer-widget__time__item {
  margin-top: 10.3px;
}
.footer-widget--info {
  margin-right: 35px;
}
@media (max-width: 1199px) {
  .footer-widget--info {
    margin-right: 0;
  }
}
.footer-widget--link {
  margin-left: 8px;
}
@media (max-width: 1199px) {
  .footer-widget--link {
    margin-left: 0px;
  }
}
.footer-widget--about {
  margin-left: -25px;
}
@media (max-width: 1199px) {
  .footer-widget--about {
    margin-left: 0px;
  }
}
/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact-one {
  padding: 120px 0px;
}
@media (max-width: 991px) {
  .contact-one {
    padding: 100px 0px;
  }
}
@media (max-width: 768px) {
  .contact-one {
    padding: 80px 0px;
  }
}
.contact-one .sec-title__tagline {
  letter-spacing: 1.4px;
}
@media (max-width: 991px) {
  .contact-one__top {
    margin-top: 70px;
  }
}
.contact-one__form input[type=date],
.contact-one__form input[type=text],
.contact-one__form input[type=email],
.contact-one__form input[type=tel], .contact-one__form textarea {
  background-color: var(--laboix-border-color2, #F3F3F3);
  height: 60px;
  width: 100%;
  border: none;
  padding-left: 30px;
  padding-right: 20px;
  outline: none;
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.contact-one__form input[type=date]::placeholder,
.contact-one__form input[type=text]::placeholder,
.contact-one__form input[type=email]::placeholder,
.contact-one__form input[type=tel]::placeholder, .contact-one__form textarea::placeholder {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.contact-one__form textarea {
  height: 317px;
}
.contact-one__content {
  background: var(--laboix-secondary, #0EBD66);
  padding: 40px;
  padding-bottom: 0;
  margin-right: 50px;
  position: relative;
  text-align: center;
}
@media (max-width: 991px) {
  .contact-one__content {
    margin-right: auto;
    max-width: 420px;
    margin-left: auto;
  }
}
@media (max-width: 575px) {
  .contact-one__content {
    padding: 25px;
    padding-bottom: 0;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .contact-one__content {
    margin-right: 0;
    padding: 25px;
    padding-bottom: 0;
  }
}
.contact-one__content__thumb img {
  margin-bottom: -70px;
  position: relative;
}
@media (max-width: 1199px) {
  .contact-one__content__thumb img {
    max-width: 100%;
  }
}
.contact-one__content__social {
  text-align: center;
  position: relative;
  margin-bottom: -20px;
  z-index: 1;
  display: inline-block;
  background: var(--laboix-white, #fff);
  padding: 20px 40px;
}
.contact-one__content__social a {
  color: var(--laboix-base, #021936);
}
.contact-one__content__social a:hover {
  color: var(--laboix-secondary, #0EBD66);
}
.contact-one__content__social a + a {
  margin-left: 34px;
}
@media (max-width: 575px) {
  .contact-one__content__social a + a {
    margin-left: 20px;
  }
}
.contact-one__info {
  margin-bottom: 47px;
}
.contact-one__info__item {
  display: flex;
  gap: 20px;
  align-items: center;
}
.contact-one__info__item:hover .contact-one__info__icon {
  background: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.2);
}
.contact-one__info__icon {
  max-width: 50px;
  width: 100%;
  height: 50px;
  border-radius: 50%;
  background: rgba(var(--laboix-white-rgb, 255, 255, 255), 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: var(--laboix-white, #fff);
  transition: all 0.5s ease-in-out;
}
.contact-one__info__icon i {
  color: inherit;
}
.contact-one__info__text {
  color: var(--laboix-border-color, #DBDBDB);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 0;
  margin-bottom: 3px;
  text-align: left;
}
.contact-one__info__title {
  color: var(--laboix-white, #fff);
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 0;
  margin-bottom: -3px;
  text-align: left;
}
.contact-one__info__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.contact-one__info__title a:hover {
  background-size: 100% 1px;
}
.contact-one__info__title a:hover {
  color: var(--laboix-base, #021936);
}
.contact-one__info__item + .contact-one__info__item {
  margin-top: 30px;
}

.contact-map {
  padding-bottom: 0 !important;
}
.contact-map .google-map iframe {
  height: 322px;
}
.contact-map .google-map__contact__pin {
  position: absolute;
  top: 35%;
  right: 45%;
}
.contact-map .google-map__contact__location {
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.06);
  position: absolute;
  padding: 30px 50px 35px;
  right: 25%;
  top: -15%;
  max-width: 420px;
  background: var(--laboix-white, #fff);
}
.contact-map .google-map__contact__location__text {
  color: var(--laboix-base, #021936);
  font-size: 24px;
  font-weight: 700;
  line-height: 152.083%;
  letter-spacing: -0.72px;
  padding-bottom: 0;
  margin-bottom: 0;
}
.contact-map .google-map__contact__location::after {
  content: "";
  bottom: -28px;
  left: 0;
  width: 34px;
  height: 29px;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  position: absolute;
  background: var(--laboix-white, #fff);
}

.contact-two {
  padding: 120px 0px;
  position: relative;
  z-index: 1;
}
@media (max-width: 991px) {
  .contact-two {
    padding: 100px 0px;
  }
}
@media (max-width: 767px) {
  .contact-two {
    padding: 80px 0px;
  }
}
.contact-two .sec-title {
  padding-bottom: 35px;
}
.contact-two__form input[type=date],
.contact-two__form input[type=text],
.contact-two__form input[type=email],
.contact-two__form input[type=tel], .contact-two__form textarea {
  background-color: var(--laboix-border-color2, #F3F3F3);
  height: 60px;
  width: 100%;
  border: none;
  padding-left: 30px;
  padding-right: 20px;
  outline: none;
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.contact-two__form input[type=date]::placeholder,
.contact-two__form input[type=text]::placeholder,
.contact-two__form input[type=email]::placeholder,
.contact-two__form input[type=tel]::placeholder, .contact-two__form textarea::placeholder {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.contact-two__form textarea {
  height: 150px;
}
.contact-two__form .laboix-btn::before {
  background: var(--laboix-white, #fff);
}
.contact-two__form .laboix-btn:hover {
  color: var(--laboix-base, #021936);
}
.contact-two__map {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 885px;
  z-index: -1;
}
.contact-two .google-map iframe {
  height: 815px;
}
.contact-two .google-map {
  position: relative;
  z-index: 1;
}
.contact-two .google-map__contact__pin {
  position: absolute;
  top: 35%;
  right: 85%;
}
.contact-two::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 55%;
  height: 100%;
  background-color: var(--laboix-base, #021936);
  z-index: -1;
  /* background-image: url(../media/shapes/contact-shape.png); */
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
}
@media (max-width: 991px) {
  .contact-two::after {
    width: 100%;
  }
}
@media (max-width: 1199px) {
  .contact-two__right {
    margin-left: -35px;
  }
}
@media (max-width: 991px) {
  .contact-two__right {
    margin-top: 30px;
    margin-left: 0;
  }
}
.contact-two__content {
  background: var(--laboix-secondary, #0EBD66);
  margin-left: 122px;
  margin-right: 50px;
  padding: 60px 60px 55px;
}
@media (max-width: 1199px) {
  .contact-two__content {
    margin-left: 0px;
    margin-right: 50px;
  }
}
@media (max-width: 767px) {
  .contact-two__content {
    margin-right: 0;
  }
}
@media (max-width: 575px) {
  .contact-two__content {
    padding: 40px;
  }
}
.contact-two__content__top {
  margin-bottom: 38px;
}
.contact-two__content__top__subtitle {
  margin-top: -7px;
  display: block;
  color: var(--laboix-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 156.25%;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}
.contact-two__content__top__title {
  color: var(--laboix-white, #fff);
  font-size: 30px;
  font-weight: 700;
  line-height: 83.333%;
}
.contact-two__content__list {
  margin-bottom: 0;
  padding-bottom: 0;
}
.contact-two__content__list__item {
  display: flex;
  align-items: center;
  gap: 20px;
}
.contact-two__content__list__item:not(:last-of-type) {
  padding-bottom: 40px;
  margin-bottom: 35px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.24);
}
@media (max-width: 767px) {
  .contact-two__content__list__item:not(:last-of-type) {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}
.contact-two__content__list__item:hover .contact-two__content__icon {
  background: var(--laboix-white, #fff);
  color: var(--laboix-secondary, #0EBD66);
  transform: rotateY(360deg);
}
.contact-two__content__icon {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 64"><path d="M26.5 0.50293L53 16.2529V47.7529L26.5 63.5029L0 47.7529V16.2529L26.5 0.50293Z"/></svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 64"><path d="M26.5 0.50293L53 16.2529V47.7529L26.5 63.5029L0 47.7529V16.2529L26.5 0.50293Z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  max-width: 53px;
  width: 100%;
  height: 64px;
  background: var(--laboix-base, #021936);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--laboix-white, #fff);
  font-size: 22px;
  transition: all 0.8s ease-in-out;
}
.contact-two__content__title {
  color: var(--laboix-white, #fff);
  font-size: 16px;
  font-weight: 500;
  line-height: 156.25%;
  padding-bottom: 0;
  margin-bottom: 0;
}
.contact-two__content__text {
  color: var(--laboix-white, #fff);
  font-size: 18px;
  font-weight: 600;
  line-height: 138.889%;
  margin-bottom: 0;
  padding-bottom: 0;
}
.contact-two__content__text a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.contact-two__content__text a:hover {
  background-size: 100% 1px;
}

/*--------------------------------------------------------------
# Topbar
--------------------------------------------------------------*/
.topbar-one {
  position: relative;
  z-index: 1;
  background-color: var(--laboix-base, #021936);
}
@media (max-width: 1200px) {
  .topbar-one {
    display: none;
  }
}
.topbar-one .container-fluid {
  max-width: 1830px;
}
.topbar-one__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topbar-one__info {
  display: flex;
  align-items: center;
  gap: 37px;
  margin-bottom: 0;
}
@media (max-width: 1199px) {
  .topbar-one__info {
    gap: 25px;
  }
}
.topbar-one__info__icon {
  font-size: 14px;
  color: var(--laboix-secondary, #0EBD66);
  margin-right: 4px;
}
.topbar-one__info__item__location, .topbar-one__info__item__email {
  color: var(--laboix-text-gray, #97A5B7);
  font-size: 14px;
  font-weight: 500;
  line-height: 128.571%;
}
.topbar-one__info__item__email {
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.topbar-one__info__item__email:hover {
  background-size: 100% 1px;
}
.topbar-one__info__item__email:hover {
  color: var(--laboix-white, #fff);
}
.topbar-one__right {
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.topbar-one__right__icon {
  font-size: 14px;
  color: var(--laboix-secondary, #0EBD66);
}
.topbar-one__text {
  color: var(--laboix-text-gray, #97A5B7);
  font-size: 14px;
  font-weight: 500;
  line-height: 128.571%;
  margin-bottom: 0;
}
.topbar-one__text span {
  color: currentColor;
}
.topbar-one__social {
  /* margin-left: 40px; */
  background: var(--laboix-secondary, #0EBD66);
  padding: 12px 0px 12px 40px;
  position: relative;
}
.topbar-one__social a {
  color: var(--laboix-white, #fff);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  transition: all 0.5s ease-in-out;
}
.topbar-one__social a:hover {
  color: var(--laboix-base, #021936);
}
.topbar-one__social a + a {
  margin-left: 20px;
}
.topbar-one__social::before {
  content: "";
  background: var(--laboix-white, #fff);
  width: 9999999px;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0%;
  z-index: 1;
  opacity: 0.2;
}
.topbar-one::after {
  content: "";
  background: var(--laboix-secondary, #0EBD66);
  width: 9999999px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 92%;
  z-index: -2;
}
.topbar-one--two {
  padding: 12px 0px;
  background: var(--laboix-white, #fff);
}
@media (max-width: 1200px) {
  .topbar-one--two {
    display: block;
  }
}
@media (max-width: 991px) {
  .topbar-one--two {
    display: none;
  }
}
.topbar-one--two::after {
  display: none;
}
.topbar-one--two .topbar-one__right {
  padding-right: 0;
}
.topbar-one--two .topbar-one__info__item__location, .topbar-one--two .topbar-one__info__item__email {
  color: #868686;
}
.topbar-one--two .topbar-one__info__item__email {
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.topbar-one--two .topbar-one__info__item__email:hover {
  background-size: 100% 1px;
}
.topbar-one--two .topbar-one__info__item__email:hover {
  color: var(--laboix-base, #021936);
}
.topbar-one--two .topbar-one__text {
  color: #868686;
}

/*--------------------------------------------------------------
# Navigations
--------------------------------------------------------------*/
.main-header {
  background-color: var(--laboix-white, #fff);
}
.main-header .container-fluid {
  max-width: 1830px;
}
.main-header__inner {
  display: flex;
  align-items: center;
  position: relative;
}
@media (max-width: 767px) {
  .main-header__inner {
    padding: 20px 0;
  }
}
@media (max-width: 1300px) {
  .main-header__inner {
    padding: 20px 0;
  }
}
.main-header__logo {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .main-header__logo {
    width: auto;
  }
}
@media (min-width: 768px) {
  .main-header__logo .mobile-nav__btn {
    margin-left: 30px;
  }
}
.main-header__btn {
  display: none;
  padding: 11px 10px;
  background: var(--laboix-border-color2, #F3F3F3);
  color: var(--laboix-base, #021936);
  font-size: 13px;
  line-height: 128.571%;
}
@media (min-width: 576px) {
  .main-header__btn {
    display: inline-flex;
  }
}
.main-header__link {
  border-right: 1px solid var(--laboix-border-color, #DBDBDB);
  margin-right: 20px;
  padding-right: 20px;
}
.main-header__right {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}
.main-header__right__info {
  padding-right: 30px;
  display: flex;
}
@media (max-width: 575px) {
  .main-header__right__info {
    display: none;
  }
}
.main-header__right__info a + a {
  margin-left: 20px;
}
.main-header__right__info__item {
  font-size: 20px;
  color: var(--laboix-base, #021936);
  transition: all 500ms ease;
  line-height: 1em;
}
.main-header__right__info__item:hover {
  color: var(--laboix-secondary, #0EBD66);
}
.main-header__right__call {
  display: flex;
  align-items: center;
  background-color: var(--laboix-secondary, #0EBD66);
  gap: 10px;
  padding: 5px 0px 5px 15px;

}
@media (max-width: 1300px) {
  .main-header__right__call {
    display: none;
  }
}
.main-header__right__icon {
  width: 40px;
  height: 40px;
  border-radius: 500px;
  background: var(--laboix-white, #fff);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--laboix-secondary, #0EBD66);
  font-size: 20px;
  overflow: hidden;
}
.main-header__right__content__text {
  color: var(--laboix-white, #fff);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
}
.main-header__right__content__number {
  margin-top: -5px;
  color: var(--laboix-white, #fff);
  font-size: 18px;
  font-weight: 600;
  line-height: 166.667%;
  margin-bottom: 0;
  padding-bottom: 0;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.main-header__right__content__number:hover {
  background-size: 100% 1px;
}
.main-header__right__content__number:hover {
  color: var(--laboix-base, #021936);
}
.main-header__right::after {
  content: "";
  background: var(--laboix-secondary, #0EBD66);
  width: 9999999px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 94%;
  z-index: -1;
}
@media (max-width: 1300px) {
  .main-header__right::after {
    display: none;
  }
}
.main-header__nav {
  margin-left: 180px;
  margin-right: auto;
}
@media (max-width: 1500px) {
  .main-header__nav {
    margin-left: 80px;
  }
}
.main-header--two {
  position: absolute;
  background: transparent;
  top: 0%;
  left: 0%;
  width: 100%;
  z-index: 11;
}
.main-header--two .main-header__inner {
  padding-top: 7px;
}
.main-header--two .main-header__right::after {
  background: none;
}
.main-header--two .main-header__right__call {
  background: transparent;
}
.main-header--two .main-menu .main-menu__list > li > a {
  color: var(--laboix-white, #fff);
}
.main-header--two .main-header__right__info {
  border-left: 0px solid var(--laboix-border-color, #DBDBDB);
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
.main-header--two .main-header__right__call {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  margin-right: 30px;
  padding-right: 30px;
}
.main-header--two .main-header__right__content__text {
  color: var(--laboix-text-gray, #97A5B7);
}
.main-header--two .main-header__right__content__number:hover {
  color: var(--laboix-secondary, #0EBD66);
}
.main-header--two .main-header__right__info__item {
  color: var(--laboix-white, #fff);
}
.main-header--two .main-header__right__info__item:hover {
  color: var(--laboix-secondary, #0EBD66);
}
.main-header--two .mobile-nav__btn {
  margin-right: 0;
  margin-left: 20px;
}
.main-header--two .mobile-nav__btn span {
  background: var(--laboix-white, #fff);
}
.main-header--two .mobile-nav__btn:hover span {
  background: var(--laboix-secondary, #0EBD66);
}
.main-header--three .main-header__middle {
  background: var(--laboix-base, #021936);
}
.main-header--three .main-header__bootom {
  background: transparent;
  position: relative;
  z-index: 11;
}
@media (max-width: 1199px) {
  .main-header--three .main-header__bootom {
    display: none;
  }
}
@media (max-width: 1300px) {
  .main-header--three .main-header__inner {
    padding: 0;
  }
}
.main-header--three .main-header__inner .main-header__nav {
  display: none;
  margin-left: 70px;
  margin-right: auto;
}
.main-header--three .main-header__inner .main-header__nav .main-menu__list > li > a {
  color: var(--laboix-white, #fff);
}
.main-header--three .main-header__bootom__inner {
  background: var(--laboix-white, #fff);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4.5px 40px;
  position: relative;
  z-index: 1;
  margin-bottom: -88px;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}
.main-header--three .main-header__bootom__inner .main-header__nav {
  margin-left: 0;
  margin-right: auto;
}
@media (max-width: 1500px) {
  .main-header--three .main-header__bootom__inner .main-header__nav {
    margin-left: 0px;
  }
}
.main-header--three .main-header__bootom__inner .main-header__nav .main-menu .main-menu__list > li > a {
  color: var(--laboix-text, #6A6A6A);
  font-weight: 500;
  line-height: normal;
}
.main-header--three .main-header__bootom__inner .main-header__link {
  border-right: 0px solid var(--laboix-border-color, #DBDBDB);
  margin-right: 0px;
  padding-right: 0px;
}
.main-header--three .main-header__right {
  background: #02142C;
  padding: 23px 30px 23px 30px;
}
.main-header--three .main-header__right::after {
  display: none;
}
.main-header--three .main-header__right__info {
  padding-right: 0;
}
.main-header--three .main-header__right__call {
  background: transparent;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-right: 30px;
  padding-right: 40px;
}
@media (max-width: 1300px) {
  .main-header--three .main-header__right__call {
    display: flex;
  }
}
@media (max-width: 768px) {
  .main-header--three .main-header__right__call {
    display: none;
  }
}
.main-header--three .main-header__right__content__number:hover {
  color: var(--laboix-secondary, #0EBD66);
}
.main-header--three .main-header__inner {
  justify-content: space-between;
}
.main-header--three .main-header__right__info__item {
  color: var(--laboix-white, #fff);
}
.main-header--three .main-header__right__info__item:hover {
  color: var(--laboix-secondary, #0EBD66);
}
.main-header--three .mobile-nav__btn {
  margin-left: 30px;
}
.main-header--three .mobile-nav__btn span {
  background: var(--laboix-white, #fff);
}

.sticky-header--cloned {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  top: 0;
  background-color: var(--laboix-white, #fff);
  transform: translateY(-200%);
  box-shadow: 0px 3px 18px rgba(var(--laboix-black-rgb, 0, 0, 0), 0.07);
  transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
  visibility: hidden;
  transition: transform 500ms ease, visibility 500ms ease;
}
.sticky-header--cloned.active {
  transform: translateY(0%);
  visibility: visible;
}

.main-header--two.active {
  background-color: var(--laboix-base, #021936);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.main-header--three.active .main-header__inner .main-header__nav {
  display: block;
}
.main-header--three.active .main-header__bootom__inner {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.mobile-nav__btn {
  width: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  cursor: pointer;
  z-index: 3;
}
@media (max-width: 1199px) {
  .mobile-nav__btn {
    margin-right: 10px;
  }
}
@media (max-width: 575px) {
  .mobile-nav__btn {
    margin-left: -40px;
  }
}
@media (min-width: 1200px) {
  .mobile-nav__btn {
    display: none;
  }
}
.mobile-nav__btn span {
  width: 100%;
  height: 2px;
  background-color: var(--laboix-black, #000);
}
.mobile-nav__btn span:nth-child(2) {
  margin-top: 4px;
  margin-bottom: 4px;
}

.main-menu {
  /* after third level no menu */
}
.main-menu .main-menu__list,
.main-menu .main-menu__list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  align-items: center;
  display: none;
}
@media (min-width: 1200px) {
  .main-menu .main-menu__list,
  .main-menu .main-menu__list ul {
    display: flex;
  }
}
.main-menu .main-menu__list > li {
  padding-top: 25px;
  padding-bottom: 24.33px;
  position: relative;
}
.main-menu .main-menu__list > li.dropdown > a {
  position: relative;
}
.main-menu .main-menu__list > li + li {
  margin-left: 20px;
}
@media (max-width: 1350px) {
  .main-menu .main-menu__list > li + li {
    margin-left: 20px;
  }
}
.main-menu .main-menu__list > li > a {
  color: var(--laboix-text, #6A6A6A);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.main-menu .main-menu__list > li.current > a,
.main-menu .main-menu__list > li:hover > a {
  color: var(--laboix-secondary, #0EBD66);
  text-shadow: 0 0 0.5px currentColor;
}
.main-menu .main-menu__list li ul {
  position: absolute;
  top: 100%;
  left: -25px;
  min-width: 232px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  opacity: 0;
  visibility: hidden;
  transform-origin: top center;
  transform: scaleY(1) translateZ(100px);
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease;
  z-index: 99;
  background-color: var(--laboix-border-color2, #F3F3F3);
 /* padding: 20px; */
}
.main-menu .main-menu__list li:hover > ul {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1) translateZ(0px);
}
.main-menu .main-menu__list > .megamenu {
  position: static;
}
.main-menu .main-menu__list > .megamenu > ul {
  top: 100% !important;
  left: 0 !important;
  right: 0 !important;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}
.main-menu .main-menu__list li ul li {
  flex: 1 1 100%;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}
.main-menu .main-menu__list li ul li:last-child {
  margin-bottom: 0;
}
.main-menu .main-menu__list li ul li > a {
  display: block;
  color: var(--laboix-text, #6A6A6A);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  padding: 10px 20px;
  text-shadow: 0 0 0 0.2px currentColor;
  margin-bottom: -1px;
}
.main-menu .main-menu__list li ul li.current > a,
.main-menu .main-menu__list li ul li:hover > a {
  background-color: var(--laboix-white, #fff);
  color: var(--laboix-base, #021936);
}
.main-menu .main-menu__list li ul li.current > a::after,
.main-menu .main-menu__list li ul li:hover > a::after {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.main-menu .main-menu__list li ul li > ul {
  top: 0;
  left: calc(100% + 20px);
}
.main-menu .main-menu__list li ul li > ul.right-align {
  top: 0;
  left: auto;
  right: 100%;
}
.main-menu .main-menu__list li ul li > ul ul {
  display: none;
}
.main-menu .sub-menu .dropdown::before {
  position: absolute;
  right: 20px;
  top: 35%;
  border-radius: 0;
  font-size: 12px;
  font-weight: 500;
  font-family: "icomoon" !important;
  content: "\e91f";
  color: var(--laboix-secondary, #0EBD66);
  visibility: hidden;
  opacity: 0;
  transition: all 500ms ease;
  transform: scale(0);
  line-height: 1;
  z-index: 1;
}
.main-menu .sub-menu .dropdown:hover::before {
  visibility: visible;
  opacity: 1;
  transform: scaleX(1);
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .main-menu__list li:nth-last-child(1) ul li > ul,
  .main-menu__list li:nth-last-child(2) ul li > ul {
    left: auto;
    right: calc(100% + 20px);
  }
}
/*--------------------------------------------------------------
# Megamenu Popup
--------------------------------------------------------------*/
.mobile-nav__container .main-menu__list > .megamenu.megamenu-clickable > ul,
.main-menu .main-menu__list > .megamenu.megamenu-clickable > ul,
.stricky-header .main-menu__list > .megamenu.megamenu-clickable > ul {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100vw;
  height: 100vh;
  visibility: visible;
  overflow-y: scroll;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  transition: transform 0.7s ease, opacity 0.7s ease, visibility 0.7s ease;
  z-index: 999999;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  padding: 0;
  background-color: var(--laboix-white, #fff);
  display: block !important;
  margin: 0;
}

.main-menu__list > li.megamenu-clickable > ul::-webkit-scrollbar {
  display: none;
}

.mobile-nav__container .main-menu__list > .megamenu.megamenu-clickable > ul.megamenu-clickable--active,
.main-menu .main-menu__list > .megamenu.megamenu-clickable > ul.megamenu-clickable--active,
.stricky-header .main-menu__list > .megamenu.megamenu-clickable > ul.megamenu-clickable--active {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 1;
  visibility: visible;
  transition: transform 0.7s ease, opacity 0.7s ease, visibility 0.7s ease;
}

body.megamenu-popup-active {
  overflow: hidden;
}

body.megamenu-popup-active .stricky-header {
  bottom: 0;
}

body.megamenu-popup-active .mobile-nav__content {
  overflow: unset;
}

.mobile-nav__content .demo-one .container {
  padding-left: 15px;
  padding-right: 15px;
}

.megamenu-popup {
  position: relative;
}
.megamenu-popup .megamenu-clickable--close {
  position: absolute;
  top: 18px;
  right: 20px;
  display: block;
  color: var(--laboix-black, #000);
}
@media (min-width: 1300px) {
  .megamenu-popup .megamenu-clickable--close {
    top: 38px;
    right: 40px;
  }
}
.megamenu-popup .megamenu-clickable--close:hover {
  color: var(--laboix-base, #021936);
}
.megamenu-popup .megamenu-clickable--close span {
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
  color: currentColor;
  transition: all 500ms ease;
}
.megamenu-popup .megamenu-clickable--close span::before, .megamenu-popup .megamenu-clickable--close span::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: currentColor;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.megamenu-popup .megamenu-clickable--close span::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

/*--------------------------------------------------------------
# Home Showcase
--------------------------------------------------------------*/
.demo-one {
  padding-top: 120px;
  padding-bottom: 120px;
}
.demo-one .row {
  --bs-gutter-y: 30px;
}
.demo-one__card {
  background-color: var(--laboix-border-color2, #F3F3F3);
  box-shadow: 0px 10px 60px 0px rgba(var(--laboix-black-rgb, 0, 0, 0), 0.1);
  text-align: center;
  transition: 500ms ease;
  transform: translateY(0px);
}
.demo-one__card:hover {
  transform: translateY(-10px);
}
.demo-one__title {
  margin: 0;
  color: var(--laboix-base, #021936);
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
}
.demo-one__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.demo-one__title a:hover {
  background-size: 100% 1px;
}
.demo-one__title a:hover {
  color: var(--laboix-secondary, #0EBD66);
}
.demo-one__image {
  position: relative;
  overflow: hidden;
}
.demo-one__image img {
  max-width: 100%;
  transition: filter 500ms ease;
  filter: blur(0px);
}
.demo-one__card:hover .demo-one__image img {
  filter: blur(2px);
}
.demo-one__btns {
  background-color: rgba(var(--laboix-black-rgb, 0, 0, 0), 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  transform: scale(1, 0);
  transition: transform 500ms ease, opacity 600ms linear;
  transform-origin: bottom center;
  opacity: 0;
}
.demo-one__card:hover .demo-one__btns {
  transform: scale(1, 1);
  opacity: 1;
  transform-origin: top center;
}
.demo-one__btn {
  font-size: 14px;
  padding: 15px 30px;
}
.demo-one__btn::before {
  background-color: var(--laboix-base, #021936);
}
.demo-one__btn::after {
  background-color: var(--laboix-black, #000);
}
.demo-one__btn:hover {
  color: var(--laboix-white, #fff);
}
@media (min-width: 768px) {
  .demo-one__btn {
    display: inline-flex;
  }
}
.demo-one__title {
  padding-top: 20.5px;
  padding-bottom: 20.5px;
}

.home-showcase {
  margin-top: 0px;
  margin-bottom: -20px;
}
.home-showcase .container {
  max-width: 1350px;
}
.home-showcase .row {
  --bs-gutter-x: 42px;
  --bs-gutter-y: 20px;
}
.home-showcase__inner {
  padding: 40px 40px 21px;
  margin-left: 55px;
  margin-right: -55px;
  background-color: var(--laboix-border-color2, #F3F3F3);
  box-shadow: 0px 10px 60px 0px rgba(var(--laboix-black-rgb, 0, 0, 0), 0.07);
}
@media (max-width: 1400px) {
  .home-showcase__inner {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.home-showcase .demo-one__card {
  box-shadow: none;
}
.home-showcase .demo-one__btns {
  flex-direction: column;
}
.home-showcase .demo-one__btn {
  min-width: 135px;
  text-align: center;
  justify-content: center;
}
.home-showcase .demo-one__title {
  padding: 0;
  margin-top: 15px;
  padding-bottom: 15px;
  color: var(--laboix-base, #021936);
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
}

/*--------------------------------------------------------------
# Why choose
--------------------------------------------------------------*/
.join-us-tow {
  padding-top: 120px;
  position: relative;
  z-index: 1;
}
@media (max-width: 991px) {
  .join-us-tow {
    padding-top: 100px;
  }
}
@media (max-width: 767px) {
  .join-us-tow {
    padding-top: 80px;
  }
}
.join-us-tow__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 85%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: -1;
  filter: grayscale(100%);
}
.join-us-tow::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 85%;
  background: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.9);
  top: 0;
  left: 0;
  z-index: -1;
}
.join-us-tow .sec-title {
  padding-bottom: 30px;
}
@media (min-width: 992px) {
  .join-us-tow .sec-title {
    padding-bottom: 0px;
  }
}
.join-us-tow__link {
  text-align: start;
}
@media (min-width: 992px) {
  .join-us-tow__link {
    text-align: end;
  }
}
.join-us-tow__top {
  padding-bottom: 30px;
}
@media (min-width: 992px) {
  .join-us-tow__top {
    padding-bottom: 57px;
  }
}
.join-us-tow__item {
  background-color: var(--laboix-white, #fff);
  margin-top: 70px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
}
.join-us-tow__content {
  transition: all 0.4s ease-in-out;
  position: relative;
  z-index: 1;
  padding: 1px 30px 30px;
}
@media (min-width: 576px) {
  .join-us-tow__content {
    padding: 1px 50px 30px;
  }
}
.join-us-tow__content:hover {
  background-color: var(--laboix-secondary, #0EBD66);
}
.join-us-tow__content:hover .join-us-tow__icon {
  background-color: var(--laboix-base, #021936);
}
.join-us-tow__content:hover .join-us-tow__title {
  color: var(--laboix-white, #fff);
}
.join-us-tow__content:hover .join-us-tow__text {
  color: var(--laboix-white, #fff);
}
.join-us-tow__icon {
  position: relative;
  margin-top: -50px;
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102 121"><path d="M51 0.043457L102 30.0435V90.0435L51 120.043L0 90.0435V30.0435L51 0.043457Z"/></svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102 121"><path d="M51 0.043457L102 30.0435V90.0435L51 120.043L0 90.0435V30.0435L51 0.043457Z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 102px;
  height: 121px;
  background: var(--laboix-secondary, #0EBD66);
  font-size: 60px;
  color: var(--laboix-white, #fff);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 23px;
  transition: all 0.4s ease-in-out;
}
.join-us-tow__icon i {
  color: inherit;
}
.join-us-tow__title {
  color: var(--laboix-base, #021936);
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.48px;
  margin-bottom: 17px;
}
.join-us-tow__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.join-us-tow__title a:hover {
  background-size: 100% 1px;
}
.join-us-tow__text {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
  margin-bottom: 0;
  transition: all 0.4s ease-in-out;
}
.join-us-tow__shape {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.choose-us-one {
  position: relative;
  z-index: 1;
  padding-bottom: 240px;
}
.choose-us-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  mix-blend-mode: plus-darker;
  z-index: -1;
}
.choose-us-one__left {
  padding-top: 120px;
}
@media (max-width: 1199px) {
  .choose-us-one__left {
    padding-top: 80px;
  }
}
.choose-us-one__top {
  margin-bottom: 82px;
}
.choose-us-one__top__text {
  color: var(--laboix-text-gray, #97A5B7);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
  margin-bottom: 0;
  padding-bottom: 0;
}
.choose-us-one__top .sec-title {
  padding-bottom: 18px;
}
.choose-us-one__feature {
  display: flex;
  margin-right: -30px;
  margin-bottom: 40px;
}
@media (max-width: 1199px) {
  .choose-us-one__feature {
    margin-right: 0px;
  }
}
@media (max-width: 768px) {
  .choose-us-one__feature {
    flex-direction: column;
    gap: 70px;
  }
}
.choose-us-one__feature__item {
  background: var(--laboix-black2, #011126);
  padding: 10px 36px 30px;
}
@media (max-width: 1199px) and (min-width: 767px) {
  .choose-us-one__feature__item {
    padding: 10px 25px 25px;
  }
}
.choose-us-one__feature__item:not(:first-of-type) {
  border-left: 1px solid rgba(var(--laboix-white-rgb, 255, 255, 255), 0.1);
}
.choose-us-one__feature__item:hover .choose-us-one__feature__thumb::after {
  visibility: visible;
  opacity: 1;
}
.choose-us-one__feature__title {
  color: var(--laboix-white, #fff);
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 18px;
}
.choose-us-one__feature__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.choose-us-one__feature__title a:hover {
  background-size: 100% 1px;
}
.choose-us-one__feature__text {
  color: var(--laboix-text-gray, #97A5B7);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
  padding-bottom: 0;
  margin-bottom: 0;
}
.choose-us-one__feature__thumb {
  position: relative;
  margin-top: -51px;
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180.000000 203.000000"> <g transform="translate(0.000000,203.000000) scale(0.100000,-0.100000)"> <path d="M448 1768 l-448 -251 0 -506 1 -506 450 -251 449 -252 450 252 449 251 1 506 0 506 -448 252 c-247 138 -450 251 -453 251 -2 0 -205 -113 -451 -252z"/> </g> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180.000000 203.000000"> <g transform="translate(0.000000,203.000000) scale(0.100000,-0.100000)"> <path d="M448 1768 l-448 -251 0 -506 1 -506 450 -251 449 -252 450 252 449 251 1 506 0 506 -448 252 c-247 138 -450 251 -453 251 -2 0 -205 -113 -451 -252z"/> </g> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 180px;
  height: 203px;
  background: transparent;
  margin-bottom: 30px;
  position: relative;
}
.choose-us-one__feature__thumb::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.7);
  transition: all 0.4s ease-in-out;
  visibility: hidden;
  opacity: 0;
}
.choose-us-one__link {
  display: flex;
  align-items: center;
  gap: 40px;
}
@media (max-width: 575px) {
  .choose-us-one__link {
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }
}
.choose-us-one__link__btn {
  background-color: var(--laboix-white, #fff);
  color: var(--laboix-base, #021936);
}
.choose-us-one__link__btn:hover {
  color: var(--laboix-white, #fff);
}
.choose-us-one__funfact {
  display: flex;
  align-items: center;
  gap: 14px;
}
.choose-us-one__funfact__count {
  color: var(--laboix-secondary, #0EBD66);
  font-size: 50px;
  font-weight: 600;
  line-height: 36%;
  display: flex;
}
.choose-us-one__funfact__text {
  color: var(--laboix-white, #fff);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 128.571%;
  padding-bottom: 0;
  margin-bottom: 0;
}
.choose-us-one__funfact__text br {
  display: block;
}
.choose-us-one__thumb {
  position: absolute;
  top: 0;
  right: 0;
  width: 46%;
  height: 100%;
}
@media (max-width: 991px) {
  .choose-us-one__thumb {
    margin-top: 30px;
    position: static;
    width: 100%;
  }
}
.choose-us-one__thumb img {
  max-width: 100%;
  object-fit: cover;
}

.why-choose-us {
  padding: 120px 0px;
}
@media (max-width: 991px) {
  .why-choose-us {
    padding: 100px 0px;
  }
}
@media (max-width: 767px) {
  .why-choose-us {
    padding: 80px 0px;
  }
}
.why-choose-us__top {
  border-bottom: 1px solid var(--laboix-border-color, #DBDBDB);
  padding-bottom: 15px;
  margin-bottom: 40px;
}
.why-choose-us__top .sec-title {
  padding-bottom: 18px;
}
.why-choose-us__top__text {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
}
.why-choose-us__feature {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .why-choose-us__feature {
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 30px;
  }
}
.why-choose-us__feature__item:not(:last-of-type) {
  padding-right: 35px;
  border-right: 1px solid var(--laboix-border-color, #DBDBDB);
  margin-right: 40px;
}
@media (max-width: 1199px) {
  .why-choose-us__feature__item:not(:last-of-type) {
    padding-right: 25px;
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  .why-choose-us__feature__item:not(:last-of-type) {
    padding-right: 0px;
    border-right: 0px solid rgba(2, 25, 54, 0.1);
    margin-right: 0px;
  }
}
.why-choose-us__feature__item:hover .why-choose-us__feature__icon {
  transform: rotateY(360deg);
  background: var(--laboix-base, #021936);
}
.why-choose-us__feature__icon {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 64"><path d="M26.5 0.50293L53 16.2529V47.7529L26.5 63.5029L0 47.7529V16.2529L26.5 0.50293Z"/></svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 64"><path d="M26.5 0.50293L53 16.2529V47.7529L26.5 63.5029L0 47.7529V16.2529L26.5 0.50293Z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 53px;
  height: 64px;
  background: var(--laboix-secondary, #0EBD66);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--laboix-white, #fff);
  font-size: 26px;
  margin-bottom: 13px;
  transition: all 0.9s ease-in-out;
}
.why-choose-us__feature__title {
  color: var(--laboix-base, #021936);
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.36px;
}
.why-choose-us__feature__text {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
  padding-bottom: 0;
  margin-bottom: 0;
}
.why-choose-us__link {
  margin-top: 40px;
}
.why-choose-us__image {
  text-align: end;
  position: relative;
  z-index: 1;
}
.why-choose-us__image__item {
  position: relative;
  margin-right: -50px;
}
@media (max-width: 991px) {
  .why-choose-us__image__item {
    margin-right: 0px;
  }
  .why-choose-us__image__item img {
    object-fit: cover;
    width: 100%;
  }
}
.why-choose-us__image__item__two {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 301.000000"> <g transform="translate(0.000000,301.000000) scale(0.050000,-0.050000)"> <path d="M2118 5504 c-468 -272 -1046 -606 -1285 -744 l-433 -250 0 -1501 0 -1500 55 -32 c30 -17 321 -184 645 -372 1857 -1073 1888 -1090 1932 -1072 24 9 611 345 1305 747 l1263 729 0 1501 0 1500 -335 193 c-184 106 -765 441 -1291 745 -526 304 -967 551 -980 551 -13 -1 -407 -224 -876 -495z"/> </g> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 301.000000"> <g transform="translate(0.000000,301.000000) scale(0.050000,-0.050000)"> <path d="M2118 5504 c-468 -272 -1046 -606 -1285 -744 l-433 -250 0 -1501 0 -1500 55 -32 c30 -17 321 -184 645 -372 1857 -1073 1888 -1090 1932 -1072 24 9 611 345 1305 747 l1263 729 0 1501 0 1500 -335 193 c-184 106 -765 441 -1291 745 -526 304 -967 551 -980 551 -13 -1 -407 -224 -876 -495z"/> </g> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 300px;
  height: 301px;
  position: absolute;
  bottom: -5%;
  left: 0%;
  animation: topAni 4s ease-in-out infinite;
}
@media (max-width: 767px) {
  .why-choose-us__image__item__two {
    width: 200px;
    height: 201px;
  }
}

.choose-us-two {
  position: relative;
  background: var(--laboix-base, #021936);
}
.choose-us-two__top {
  margin-bottom: 50px;
}
.choose-us-two__top .sec-title {
  padding-bottom: 22px;
}
.choose-us-two__top__text {
  color: var(--laboix-text-gray, #97A5B7);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
  margin-bottom: 0;
}
.choose-us-two__right {
  padding-top: 120px;
}
@media (max-width: 991px) {
  .choose-us-two__right {
    padding-top: 30px;
  }
}
.choose-us-two__list {
  margin-bottom: -20px;
}
.choose-us-two__list__item {
  padding: 30px 40px 36px;
  background: #032146;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  gap: 25px;
  align-items: center;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 1199px) and (min-width: 991px) {
  .choose-us-two__list__item {
    padding: 20px;
    gap: 20px;
  }
}
.choose-us-two__list__item:hover {
  background: var(--laboix-secondary, #0EBD66);
}
.choose-us-two__list__item:hover .choose-us-two__text {
  color: var(--laboix-white, #fff);
}
.choose-us-two__list__item + .choose-us-two__list__item {
  margin-top: 20px;
}
.choose-us-two__title {
  color: var(--laboix-white, #fff);
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
}
.choose-us-two__text {
  max-width: 340px;
  width: 100%;
  color: var(--laboix-text-gray, #97A5B7);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
  margin-bottom: -5px;
  padding-bottom: 0;
  transition: all 0.4s ease-in-out;
}
.choose-us-two__thumb {
  position: absolute;
  left: 0;
  bottom: 0%;
  width: 49%;
}
@media (max-width: 991px) {
  .choose-us-two__thumb {
    position: relative;
    width: 100%;
  }
}
.choose-us-two__thumb__item img {
  object-fit: cover;
  width: 100%;
}
.choose-us-two__thumb__shape {
  position: absolute;
  top: 20%;
  right: 5%;
  animation: textRotate 4s ease-in-out infinite;
}
.choose-us-two__thumb__shape--two {
  position: absolute;
  top: 20%;
  left: 10%;
  animation: heroleft 4s ease-in-out infinite;
}
@media (max-width: 575px) {
  .choose-us-two__thumb__shape--two {
    display: none;
  }
}
@media (max-width: 575px) {
  .choose-us-two__thumb__shape {
    display: none;
  }
}

/*--------------------------------------------------------------
# Funfact
--------------------------------------------------------------*/
.funfact-one {
  background: var(--laboix-white, #fff);
  position: relative;
  padding-bottom: 120px;
}
@media (max-width: 991px) {
  .funfact-one {
    padding-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .funfact-one {
    padding-bottom: 80px;
  }
}
.funfact-one__inner {
  margin-top: -140px;
  background: var(--laboix-secondary, #0EBD66);
  padding: 30px;
  z-index: 1;
  position: relative;
}
.funfact-one__item {
  position: relative;
  z-index: 1;
  text-align: center;
  padding-bottom: 27px;
}
.funfact-one__item::after {
  position: absolute;
  bottom: 0%;
  top: 15px;
  left: 0%;
  right: 0%;
  content: "";
  background-color: var(--laboix-white, #fff);
  opacity: 0.1;
  clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
  z-index: -1;
  transition: all 0.4s ease-in-out;
}
.funfact-one__item:hover .funfact-one__icon {
  background-color: var(--laboix-white, #fff);
  color: var(--laboix-secondary, #0EBD66);
}
.funfact-one__item:hover::after {
  background-color: var(--laboix-base, #021936);
}
.funfact-one__icon {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81 91"> <path d="M40.5 0L81 22.75V68.25L40.5 91L0 68.25V22.75L40.5 0Z"/> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81 91"> <path d="M40.5 0L81 22.75V68.25L40.5 91L0 68.25V22.75L40.5 0Z"/> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 81px;
  height: 91px;
  background: var(--laboix-base, #021936);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 38px;
  color: var(--laboix-white, #fff);
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 5px;
  transition: all 0.4s ease-in-out;
}
.funfact-one__count {
  color: var(--laboix-white, #fff);
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -1.2px;
  margin-bottom: 0;
  display: flex;
  gap: 0;
  justify-content: center;
}
.funfact-one__text {
  color: var(--laboix-white, #fff);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  background: transparent;
  margin-bottom: 0;
  padding-bottom: 0;
}
@media (max-width: 575px) {
  .funfact-one__text br {
    display: none;
  }
}

.gutter-x-18 {
  --bs-gutter-x: 18px;
}

.funfact-two {
  position: relative;
  z-index: 1;
  background: var(--laboix-secondary, #0EBD66);
  padding: 100px 0px 70px;
}
.funfact-two__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 0.13;
}
.funfact-two__list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.funfact-two__item {
  position: relative;
  z-index: 1;
  text-align: center;
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
  margin-bottom: 30px;
}
.funfact-two__item:not(:last-of-type) {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.funfact-two__item:hover .funfact-two__icon {
  background-color: var(--laboix-white, #fff);
  color: var(--laboix-secondary, #0EBD66);
}
@media (max-width: 991px) {
  .funfact-two__item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .funfact-two__item:nth-child(2) {
    border-right: 0px solid rgba(255, 255, 255, 0.2);
  }
}
@media (max-width: 575px) {
  .funfact-two__item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .funfact-two__item:not(:last-of-type) {
    border-right: 0px solid rgba(255, 255, 255, 0.2);
  }
}
.funfact-two__icon {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81 91"> <path d="M40.5 0L81 22.75V68.25L40.5 91L0 68.25V22.75L40.5 0Z"/> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81 91"> <path d="M40.5 0L81 22.75V68.25L40.5 91L0 68.25V22.75L40.5 0Z"/> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 99.593px;
  height: 115px;
  background: var(--laboix-base, #021936);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: var(--laboix-white, #fff);
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 5px;
  transition: all 0.4s ease-in-out;
}
.funfact-two__count {
  color: var(--laboix-white, #fff);
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -1.2px;
  margin-bottom: 0;
  display: flex;
  gap: 0;
  justify-content: center;
}
.funfact-two__text {
  color: var(--laboix-white, #fff);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  background: transparent;
  margin-bottom: 0;
  padding-bottom: 0;
}
@media (max-width: 575px) {
  .funfact-two__text br {
    display: none;
  }
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials-one {
  padding: 120px 0px;
  position: relative;
  z-index: 1;
}
@media (max-width: 991px) {
  .testimonials-one {
    padding: 100px 0px;
  }
}
@media (max-width: 991px) {
  .testimonials-one {
    padding: 80px 0px;
  }
}
.testimonials-one__item {
  padding-top: 80px;
}
.testimonials-one__content {
  background: var(--laboix-border-color2, #F3F3F3);
  padding: 80px 44px 45px 50px;
  position: relative;
}
.testimonials-one__content::after {
  position: absolute;
  bottom: -20px;
  top: -20px;
  left: 20px;
  right: 20px;
  border: 1px solid var(--laboix-secondary, #0EBD66);
  background-color: transparent;
  content: "";
  z-index: -1;
  animation: borderScale 4s ease-in-out infinite;
}
.testimonials-one__author {
  display: inline-flex;
  align-items: end;
  gap: 30px;
  margin-top: -160px;
  position: absolute;
}
.testimonials-one__author__thumb {
  width: 140px;
  height: 140px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: -75px;
  position: relative;
  z-index: 2;
}
.testimonials-one__author__thumb img {
  object-fit: cover;
  width: 100%;
}
.testimonials-one__author__content {
  padding: 23px 80px;
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
}
.testimonials-one__author__content::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--laboix-base, #021936);
  content: "";
  clip-path: polygon(0 0, 80% 0%, 100% 100%, 0% 100%);
  z-index: -1;
}
.testimonials-one__author__name {
  color: var(--laboix-white, #fff);
  font-size: 20px;
  font-weight: 700;
  line-height: 130%;
  padding-bottom: 0;
  margin-bottom: -1px;
}
.testimonials-one__author__deg {
  display: block;
  color: var(--laboix-white, #fff);
  font-size: 14px;
  font-weight: 500;
  line-height: 178.529%;
  padding-bottom: 0;
  margin-bottom: -1px;
}
.testimonials-one__text {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
}
.testimonials-one__star {
  font-size: 22px;
  color: var(--laboix-secondary, #0EBD66);
  letter-spacing: 1px;
  margin-bottom: -4px;
}
.testimonials-one__carousel .owl-nav .owl-prev, .testimonials-one__carousel .owl-nav .owl-next {
  position: absolute;
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 45"><path d="M19.5 44.9995L9.83506e-07 33.7495L2.95052e-06 11.2495L19.5 -0.000489986L39 11.2495L39 33.7495L19.5 44.9995Z"/></svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 45"><path d="M19.5 44.9995L9.83506e-07 33.7495L2.95052e-06 11.2495L19.5 -0.000489986L39 11.2495L39 33.7495L19.5 44.9995Z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 40px;
  height: 49px;
  background: var(--laboix-secondary, #0EBD66) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}
.testimonials-one__carousel .owl-nav .owl-prev span, .testimonials-one__carousel .owl-nav .owl-next span {
  font-size: 13px;
  color: var(--laboix-white, #fff);
  transition: all 0.5s ease-in-out;
}
.testimonials-one__carousel .owl-nav .owl-prev:hover, .testimonials-one__carousel .owl-nav .owl-next:hover {
  background: var(--laboix-border-color2, #F3F3F3) !important;
}
.testimonials-one__carousel .owl-nav .owl-prev:hover span, .testimonials-one__carousel .owl-nav .owl-next:hover span {
  color: var(--laboix-base, #021936);
}
.testimonials-one__carousel .owl-nav .owl-prev {
  top: 55%;
  left: -55px;
}
.testimonials-one__carousel .owl-nav .owl-next {
  top: 55%;
  right: -55px;
}
.testimonials-one__shape {
  position: absolute;
  top: 0%;
  right: 0%;
  z-index: -1;
  animation: topAni 4s ease-in-out infinite;
}

.testimonials-two {
  padding: 105px 0px 120px 0px;
  position: relative;
  z-index: 1;
}
@media (max-width: 991px) {
  .testimonials-two {
    padding: 100px 0px;
  }
}
@media (max-width: 767px) {
  .testimonials-two {
    padding: 80px 0px;
  }
}
.testimonials-two__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: -2;
}
.testimonials-two__bg::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.81);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.testimonials-two__thumb {
  position: relative;
  z-index: 1;
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 414.000000"> <g transform="translate(0.000000,414.000000) scale(0.050000,-0.050000)"> <path d="M2150 7888 c-132 -61 -289 -134 -350 -162 -60 -28 -155 -72 -210 -96 -55 -25 -235 -108 -400 -185 -307 -143 -337 -157 -770 -355 -137 -63 -261 -124 -275 -135 -20 -16 -24 -561 -20 -2651 l5 -2632 430 -248 c237 -136 520 -299 630 -363 110 -64 434 -250 720 -415 286 -165 648 -374 805 -464 l286 -164 1434 827 1435 828 5 2631 c4 2090 0 2635 -20 2651 -14 11 -526 250 -1138 532 l-1112 513 -608 0 -607 -1 -240 -111z"/> </g> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 414.000000"> <g transform="translate(0.000000,414.000000) scale(0.050000,-0.050000)"> <path d="M2150 7888 c-132 -61 -289 -134 -350 -162 -60 -28 -155 -72 -210 -96 -55 -25 -235 -108 -400 -185 -307 -143 -337 -157 -770 -355 -137 -63 -261 -124 -275 -135 -20 -16 -24 -561 -20 -2651 l5 -2632 430 -248 c237 -136 520 -299 630 -363 110 -64 434 -250 720 -415 286 -165 648 -374 805 -464 l286 -164 1434 827 1435 828 5 2631 c4 2090 0 2635 -20 2651 -14 11 -526 250 -1138 532 l-1112 513 -608 0 -607 -1 -240 -111z"/> </g> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 373px;
  height: 500px;
}
@media (max-width: 1199px) and (min-width: 991px) {
  .testimonials-two__thumb {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 991px) {
  .testimonials-two__thumb {
    margin-bottom: 30px;
  }
}
.testimonials-two__shape {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.testimonials-two__content {
  margin-left: 40px;
  margin-right: -20px;
}
@media (max-width: 991px) {
  .testimonials-two__content {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.testimonials-two__star {
  font-size: 22px;
  color: var(--laboix-secondary, #0EBD66);
  letter-spacing: 1px;
  margin-bottom: 30px;
}
.testimonials-two__text {
  color: var(--laboix-white, #fff);
  font-size: 30px;
  font-weight: 500;
  line-height: 166.667%;
  padding-bottom: 37px;
  margin-bottom: 45px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
@media (max-width: 1199px) {
  .testimonials-two__text {
    font-size: 22px;
  }
}
.testimonials-two__author {
  display: flex;
  align-items: center;
  margin-left: 160px;
  gap: 21px;
}
.testimonials-two__author__thumb {
  display: flex;
  align-items: center;
}
.testimonials-two__author__thumb:hover .testimonials-two__author__thumb__item:not(:first-of-type) {
  margin-left: -5px;
}
.testimonials-two__author__title {
  color: var(--laboix-white, #fff);
  font-size: 20px;
  font-weight: 700;
  line-height: 130%;
  padding-bottom: 0;
  margin-bottom: -2px;
}
.testimonials-two__author__deg {
  display: block;
  color: var(--laboix-border-color, #DBDBDB);
  font-size: 14px;
  font-weight: 500;
  line-height: 178.529%;
  padding-bottom: 0;
  margin-bottom: 0;
}
.testimonials-two::after {
  content: "";
  width: 388px;
  height: 650px;
  background-color: rgba(var(--laboix-secondary-rgb, 14, 189, 102), 1);
  position: absolute;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  top: 0;
  right: 0;
  z-index: 0;
}
@media (max-width: 991px) {
  .testimonials-two::after {
    display: none;
  }
}
.testimonials-two::before {
  content: "";
  width: 430px;
  height: 100%;
  background-color: rgba(var(--laboix-white-rgb, 255, 255, 255), 1);
  position: absolute;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  top: 0;
  right: 0;
  z-index: -1;
}
@media (max-width: 991px) {
  .testimonials-two::before {
    display: none;
  }
}
@media (max-width: 575px) {
  .testimonials-two__carousel .owl-nav {
    display: none;
  }
}
.testimonials-two__carousel .owl-nav .owl-prev, .testimonials-two__carousel .owl-nav .owl-next {
  bottom: 60px;
  position: absolute;
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 45"><path d="M19.5 44.9995L9.83506e-07 33.7495L2.95052e-06 11.2495L19.5 -0.000489986L39 11.2495L39 33.7495L19.5 44.9995Z"/></svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 45"><path d="M19.5 44.9995L9.83506e-07 33.7495L2.95052e-06 11.2495L19.5 -0.000489986L39 11.2495L39 33.7495L19.5 44.9995Z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 40px;
  height: 49px;
  background: #324B64 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}
@media (max-width: 991px) {
  .testimonials-two__carousel .owl-nav .owl-prev, .testimonials-two__carousel .owl-nav .owl-next {
    bottom: 0;
  }
}
.testimonials-two__carousel .owl-nav .owl-prev span, .testimonials-two__carousel .owl-nav .owl-next span {
  font-size: 12px;
  color: var(--laboix-white, #fff);
  transition: all 0.5s ease-in-out;
}
.testimonials-two__carousel .owl-nav .owl-prev:hover, .testimonials-two__carousel .owl-nav .owl-next:hover {
  background: var(--laboix-secondary, #0EBD66) !important;
}
.testimonials-two__carousel .owl-nav .owl-prev:hover span, .testimonials-two__carousel .owl-nav .owl-next:hover span {
  color: var(--laboix-white, #fff);
}
.testimonials-two__carousel .owl-nav .owl-prev {
  right: 60px;
}
.testimonials-two__carousel .owl-nav .owl-next {
  right: 0px;
}
.testimonials-two__meta-thumb {
  position: relative;
  z-index: 1;
}
.testimonials-two .carousel-thumb__two {
  position: absolute;
  left: 44%;
  bottom: 22%;
  max-width: 150px;
  width: 100%;
}
@media (max-width: 991px) {
  .testimonials-two .carousel-thumb__two {
    left: 22%;
    bottom: 9%;
  }
}
@media (max-width: 575px) {
  .testimonials-two .carousel-thumb__two {
    left: 10%;
    bottom: 7%;
  }
}
.testimonials-two .carousel-thumb__two .owl-item:not(:first-of-type) {
  margin-left: -10px;
}
.testimonials-two .carousel-thumb__two .active.center .item__thumb {
  position: relative;
  z-index: 1;
  display: block;
}
.testimonials-two .carousel-thumb__two .active.center .item__thumb::after {
  position: absolute;
  top: -3px;
  left: -2.5px;
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.000000 65.000000"> <g transform="translate(0.000000,65.000000) scale(0.050000,-0.050000)"> <path d="M280 1122 l-270 -158 0 -324 0 -324 272 -159 272 -158 272 156 273 155 0 330 0 330 -264 150 c-146 83 -269 152 -275 155 -5 2 -131 -66 -280 -153z"/> </g> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.000000 65.000000"> <g transform="translate(0.000000,65.000000) scale(0.050000,-0.050000)"> <path d="M280 1122 l-270 -158 0 -324 0 -324 272 -159 272 -158 272 156 273 155 0 330 0 330 -264 150 c-146 83 -269 152 -275 155 -5 2 -131 -66 -280 -153z"/> </g> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 60px;
  height: 70px;
  background: var(--laboix-secondary, #0EBD66);
  content: "";
  z-index: 0;
}
.testimonials-two__meta-thumb {
  position: relative;
  z-index: 2;
  display: inline-flex;
  flex-direction: column;
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.000000 65.000000"> <g transform="translate(0.000000,65.000000) scale(0.050000,-0.050000)"> <path d="M280 1122 l-270 -158 0 -324 0 -324 272 -159 272 -158 272 156 273 155 0 330 0 330 -264 150 c-146 83 -269 152 -275 155 -5 2 -131 -66 -280 -153z"/> </g> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.000000 65.000000"> <g transform="translate(0.000000,65.000000) scale(0.050000,-0.050000)"> <path d="M280 1122 l-270 -158 0 -324 0 -324 272 -159 272 -158 272 156 273 155 0 330 0 330 -264 150 c-146 83 -269 152 -275 155 -5 2 -131 -66 -280 -153z"/> </g> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 55px;
  height: 64px;
}
.testimonials-two__meta-thumb img {
  position: relative;
  z-index: 1;
}

.testimonials-fore {
  padding: 120px 0px;
}
.testimonials-fore__card {
  padding-top: 70px;
  padding-bottom: 30px;
}
@media (max-width: 991px) {
  .testimonials-fore__card {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}
.testimonials-fore__inner {
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.06);
}
.testimonials-fore__inner:hover .testimonials-fore__star {
  background: var(--laboix-secondary, #0EBD66);
}
.testimonials-fore__inner:hover .testimonials-fore__star::after {
  background: var(--laboix-secondary, #0EBD66);
}
.testimonials-fore__inner:hover .testimonials-fore__author__quite {
  background: var(--laboix-base, #021936);
}
.testimonials-fore__content {
  padding: 1px 30px 0px;
  position: relative;
}
.testimonials-fore__content__shape {
  position: absolute;
  top: 35%;
  left: 15%;
}
.testimonials-fore__thumb {
  position: relative;
  z-index: 1;
}
.testimonials-fore__author__image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: -65px;
  margin-bottom: 25px;
}
.testimonials-fore__author__image img {
  object-fit: cover;
  width: 100%;
}
.testimonials-fore__author__quite {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 35"> <path d="M16.5 0L33 8.75V26.25L16.5 35L0 26.25V8.75L16.5 0Z"/></svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 35"> <path d="M16.5 0L33 8.75V26.25L16.5 35L0 26.25V8.75L16.5 0Z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 33px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--laboix-secondary, #0EBD66);
  font-size: 12px;
  line-height: normal;
  color: var(--laboix-white, #fff);
  position: absolute;
  top: 50px;
  right: calc(100% - 230px);
  transition: all 0.4s ease-in-out;
}
.testimonials-fore__author {
  margin-bottom: 10px;
}
.testimonials-fore__name {
  text-align: center;
  color: var(--laboix-base, #021936);
  font-size: 20px;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 0px;
  padding-bottom: 0;
}
.testimonials-fore__deg {
  text-align: center;
  color: var(--laboix-text, #6A6A6A);
  font-size: 14px;
  font-weight: 500;
  display: block;
  line-height: 178.529%;
}
.testimonials-fore__text {
  color: var(--laboix-text, #6A6A6A);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
  margin-bottom: 23px;
}
.testimonials-fore__star {
  text-align: center;
  padding: 18px 0px;
  background: var(--laboix-base, #021936);
  color: var(--laboix-white, #fff);
  font-size: 24px;
  letter-spacing: 1px;
  position: relative;
  transition: all 0.4s ease-in-out;
}
.testimonials-fore__star::after {
  position: absolute;
  left: 0;
  bottom: -29px;
  content: "";
  width: 39px;
  height: 30px;
  background: var(--laboix-base, #021936);
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  transition: all 0.4s ease-in-out;
}

/*--------------------------------------------------------------
# CTA
--------------------------------------------------------------*/
.video-cta {
  position: relative;
  z-index: 1;
  padding-top: 120px;
}
@media (max-width: 1199px) {
  .video-cta {
    padding-top: 100px;
  }
}
@media (max-width: 768px) {
  .video-cta {
    padding-top: 80px;
  }
}
.video-cta__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: -1;
}
.video-cta__video {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 161 182"> <path d="M80.5 0.790039L161 46.04V136.54L80.5 181.79L0 136.54V46.04L80.5 0.790039Z"/></svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 161 182"> <path d="M80.5 0.790039L161 46.04V136.54L80.5 181.79L0 136.54V46.04L80.5 0.790039Z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 161px;
  height: 181px;
  background-color: var(--laboix-secondary, #0EBD66);
  margin-left: auto;
  margin-right: 44%;
  font-size: 38px;
  color: var(--laboix-white, #fff);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -30px;
  transition: all 0.5s ease-in-out;
}
@media (max-width: 991px) {
  .video-cta__video {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -70px;
  }
}
@media (max-width: 1199px) {
  .video-cta__video {
    width: 140px;
    height: 150px;
    font-size: 30px;
  }
}
.video-cta__video:hover {
  background: var(--laboix-white, #fff);
}
.video-cta__video:hover a {
  color: var(--laboix-base, #021936);
}
.video-cta__content {
  padding: 120px 50px 80px;
  background: var(--laboix-base, #021936);
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 99.000000"> <g transform="translate(0.000000,99.000000) scale(0.050000,-0.050000)" > <path d="M0 990 l0 -990 3000 0 3000 0 0 990 0 990 -1242 0 -1241 0 -287 -160 c-158 -88 -297 -160 -310 -160 -13 0 -152 72 -309 160 l-284 160 -1164 0 -1163 0 0 -990z"/> </g> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 99.000000"> <g transform="translate(0.000000,99.000000) scale(0.050000,-0.050000)" > <path d="M0 990 l0 -990 3000 0 3000 0 0 990 0 990 -1242 0 -1241 0 -287 -160 c-158 -88 -297 -160 -310 -160 -13 0 -152 72 -309 160 l-284 160 -1164 0 -1163 0 0 -990z"/> </g> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: bottom center;
  mask-position: bottom center;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}
@media (max-width: 991px) {
  .video-cta__content {
    background: var(--laboix-base, #021936);
    mask: none;
  }
}
@media (max-width: 767px) {
  .video-cta__content {
    padding: 100px 30px 50px;
  }
}
.video-cta__title {
  color: var(--laboix-white, #fff);
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 117.5%;
  letter-spacing: -0.8px;
  margin-top: -10px;
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  .video-cta__title {
    font-size: 30px;
  }
}
.video-cta__text {
  color: var(--laboix-text-gray, #97A5B7);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
  max-width: 639px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 20px;
}
.video-cta__link {
  text-align: center;
}
.video-cta__link .laboix-btn::before {
  background: var(--laboix-white, #fff);
}
.video-cta__link .laboix-btn:hover {
  color: var(--laboix-secondary, #0EBD66);
}

/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/
.gallery-one {
  padding-top: 120px;
  padding-bottom: 120px;
}
.gallery-one .container-fluid {
  width: 100%;
  max-width: 1572px;
}
.gallery-one--page {
  padding-top: 120px;
}
.gallery-one .row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 30px;
}
.gallery-one__carousel .owl-nav {
  display: none !important;
}
.gallery-one__filter__list {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap;
  gap: 10px;
}
.gallery-one__filter__list li {
  cursor: pointer;
}
.gallery-one__filter__list li span {
  display: block;
  font-size: 14px;
  background-color: var(--laboix-border-color2, #F3F3F3);
  transition: all 500ms ease;
  font-weight: 600;
  padding: 15px 20px;
  line-height: normal;
}
.gallery-one__filter__list li.active span, .gallery-one__filter__list li:hover span {
  background-color: var(--laboix-secondary, #0EBD66);
  color: var(--laboix-white, #fff);
}
.gallery-one__card {
  position: relative;
  overflow: hidden;
  background-color: var(--laboix-black, #000);
}
.gallery-one__card img {
  transform: scale(1);
  max-width: 100%;
  transition: transform 500ms ease, opacity 500ms ease;
  opacity: 1;
}
.gallery-one__card__hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1, 0);
  transition: transform 500ms ease;
  transform-origin: bottom center;
}
.gallery-one__card__hover .img-popup {
  position: relative;
}
.gallery-one__card:hover img {
  transform: scale(1.05);
  opacity: 0.9;
  mix-blend-mode: screen;
}
.gallery-one__card:hover .gallery-one__card__hover {
  transform-origin: top center;
  transform: scale(1, 1);
}
.gallery-one__card__icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--laboix-secondary, #0EBD66);
  transition: all 0.5s ease-in-out;
}
.gallery-one__card__icon__item {
  width: 30px;
  height: 30px;
  display: block;
  position: relative;
}
.gallery-one__card__icon__item::after, .gallery-one__card__icon__item::before {
  content: "";
  width: 2px;
  height: 100%;
  background-color: var(--laboix-white, #fff);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.gallery-one__card__icon__item::after {
  transform: translate(-50%, -50%) rotate(90deg);
}
.gallery-one__card__icon:hover {
  background: var(--laboix-base, #021936);
}

.gallery-two {
  position: relative;
  overflow: hidden;
  padding: 0 0 20px;
}
.gallery-two .container-fluid {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 767px) {
  .gallery-two .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.gallery-two .row {
  --bs-gutter-x: 20px;
  --bs-gutter-y: 20px;
}
.gallery-two__col-one {
  width: 65%;
}
@media (max-width: 1199px) {
  .gallery-two__col-one {
    width: 100%;
  }
}
.gallery-two__col-two {
  width: 35%;
}
@media (max-width: 1199px) {
  .gallery-two__col-two {
    width: 100%;
  }
}
.gallery-two__card {
  position: relative;
  overflow: hidden;
  background-color: var(--laboix-black, #000);
}
.gallery-two__card img {
  transform: scale(1);
  width: 100%;
  transition: transform 500ms ease, opacity 500ms ease;
  opacity: 1;
}
.gallery-two__card__hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--laboix-black-rgb, 0, 0, 0), 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1, 0);
  transition: transform 500ms ease;
  transform-origin: bottom center;
}
.gallery-two__card__hover .img-popup {
  position: relative;
}
.gallery-two__card:hover img {
  transform: scale(1.05);
  opacity: 0.9;
  mix-blend-mode: screen;
}
.gallery-two__card:hover .gallery-two__card__hover {
  transform-origin: top center;
  transform: scale(1, 1);
}
.gallery-two__card a {
  width: 75px;
  height: 75px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: var(--laboix-white, #fff);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery-two__card a:hover .gallery-two__card__icon::after, .gallery-two__card a:hover .gallery-two__card__icon::before {
  background-color: var(--laboix-base, #021936);
}
.gallery-two__card__icon {
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
}
.gallery-two__card__icon::after, .gallery-two__card__icon::before {
  content: "";
  width: 2px;
  height: 100%;
  background-color: var(--laboix-black, #000);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 400ms ease;
}
.gallery-two__card__icon::after {
  transform: translate(-50%, -50%) rotate(90deg);
}
.gallery-two__info {
  position: relative;
  background-color: var(--laboix-base, #021936);
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 50px 0 100px;
  height: 100%;
}
@media (min-width: 1200px) {
  .gallery-two__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
  }
}
@media (max-width: 1500px) {
  .gallery-two__info {
    padding-left: 40px;
    padding-right: 30px;
  }
}
@media (max-width: 1199px) {
  .gallery-two__info {
    padding: 80px 50px;
  }
}
@media (max-width: 767px) {
  .gallery-two__info {
    padding: 50px 30px;
  }
}
.gallery-two__info__icon {
  width: 60px;
  height: 60px;
  margin-bottom: 24px;
}
.gallery-two__info__icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery-two__info__title {
  color: var(--laboix-white, #fff);
  font-size: 44px;
  line-height: 50px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .gallery-two__info__title {
    font-size: 35px;
    line-height: 42px;
  }
}
@media (max-width: 767px) {
  .gallery-two__info__title {
    font-size: 35px;
    line-height: 45px;
  }
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
.sidebar__single {
  background-color: var(--laboix-white, #fff);
  padding: 30px 20px;
  box-shadow: 0px 0px 60px 0px rgba(2, 2, 2, 0.07);
  max-width: 400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.sidebar__single--search {
  max-width: 400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  box-shadow: 0px 0px 0px 0px rgba(2, 2, 2, 0.07);
}
@media (min-width: 1200px) {
  .sidebar__single {
    padding: 40px;
  }
}
.sidebar__single + .sidebar__single {
  margin-top: 30px;
}
.sidebar__title {
  margin: 0;
  margin-top: -5px;
  margin-bottom: 29px;
  padding-bottom: 24px;
  color: var(--laboix-base, #021936);
  font-size: 24px;
  font-weight: 700;
  line-height: 108.333%;
  border-bottom: 1px solid var(--laboix-secondary, #0EBD66);
}
.sidebar__search {
  position: relative;
  background-color: var(--laboix-border-color2, #F3F3F3);
  padding: 30px;
}
.sidebar__search input[type=search],
.sidebar__search input[type=text] {
  outline: none;
  width: 100%;
  height: 60px;
  background-color: var(--laboix-white, #fff);
  padding-left: 55px;
  padding-right: 20px;
  transition: all 500ms ease;
  border: none;
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: 162.5%;
}
.sidebar__search input[type=search]:focus,
.sidebar__search input[type=text]:focus {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  border: none;
}
.sidebar__search input[type=search]::placeholder,
.sidebar__search input[type=text]::placeholder {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 400;
  line-height: 162.5%;
}
.sidebar__search button[type=submit] {
  border: none;
  outline: none;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translateY(-50%);
  width: auto;
  font-size: 22px;
  color: var(--laboix-text, #6A6A6A);
  transition: all 0.4s ease-in-out;
}
.sidebar__search button[type=submit]:hover {
  color: var(--laboix-base, #021936);
}
.sidebar__posts {
  margin-bottom: 0;
}
.sidebar__posts__item {
  display: flex;
  align-items: center;
}
.sidebar__posts__item:not(:last-of-type) {
  border-bottom: 1px solid var(--laboix-border-color, #DBDBDB);
  margin-bottom: 16px;
  padding-bottom: 17px;
}
.sidebar__posts__item:hover .sidebar__posts__image::after {
  width: 100%;
  right: auto;
  left: 0;
}
.sidebar__posts__item:hover .sidebar__posts__image img {
  transform: scale(1.3) rotate(-15deg);
}
.sidebar__posts__image {
  margin-right: 20px;
  overflow: hidden;
  max-width: 70px;
  width: 100%;
  height: 70px;
  position: relative;
}
.sidebar__posts__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.4s ease-in-out;
}
.sidebar__posts__image::after {
  content: "";
  width: 0%;
  height: 100%;
  top: 0%;
  left: auto;
  right: 0;
  background-color: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.5);
  position: absolute;
  transition: all 0.4s ease-in-out;
}
.sidebar__posts__title {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}
.sidebar__posts__title a {
  text-shadow: 0 0 0 0.2 currentColor;
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.sidebar__posts__title a:hover {
  background-size: 100% 1px;
}
.sidebar__posts__meta {
  margin: 0;
  line-height: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.sidebar__posts__meta a {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  display: inline-flex;
  align-items: center;
  transition: all 500ms ease;
}
.sidebar__posts__meta a:hover {
  color: var(--laboix-secondary, #0EBD66);
}
.sidebar__posts__meta a i {
  color: var(--laboix-secondary, #0EBD66);
  margin-right: 13px;
}
.sidebar__categories {
  margin-bottom: -4px;
}
.sidebar__categories li:not(:last-of-type) {
  border-bottom: 1px solid var(--laboix-border-color, #DBDBDB);
}
.sidebar__categories li:first-child a {
  padding-top: 0;
}
.sidebar__categories li:last-child a {
  padding-bottom: 0 !important;
}
.sidebar__categories li a {
  display: flex;
  align-items: center;
  padding: 11px 0 11px 0px;
  transition: all 500ms ease;
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.sidebar__categories li a::before {
  margin-right: 7px;
  content: "\e91f";
  font-family: "icomoon" !important;
  font-weight: 700;
  transition: all 500ms ease;
  font-size: 10px;
  color: var(--laboix-secondary, #0EBD66);
}
.sidebar__categories li a:hover {
  padding-left: 20px;
  color: var(--laboix-secondary, #0EBD66);
}
.sidebar__categories li a:hover::after {
  color: var(--laboix-base, #021936);
}
.sidebar__tags {
  margin-left: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.sidebar__tags a {
  background: var(--laboix-border-color2, #F3F3F3);
  transition: all 500ms ease;
  display: inline-flex;
  padding: 11px 17px;
  color: var(--laboix-text, #6A6A6A);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.sidebar__tags a:hover {
  color: var(--laboix-white, #fff);
  background-color: var(--laboix-base, #021936);
}
.sidebar__comments {
  margin-bottom: -4px;
}
.sidebar__comments__item {
  display: flex;
  align-items: center;
}
.sidebar__comments__item:not(:last-of-type) {
  margin-bottom: 21px;
}
.sidebar__comments__icon {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--laboix-border-color2, #F3F3F3);
  font-size: 24px;
  color: var(--laboix-text, #6A6A6A);
  margin-right: 14px;
  border-radius: 50%;
  transition: all 500ms ease;
}
.sidebar__comments__item:hover .sidebar__comments__icon {
  background-color: var(--laboix-secondary, #0EBD66);
  color: var(--laboix-white, #fff);
}
.sidebar__comments__title {
  color: var(--laboix-text, #6A6A6A);
  line-height: 185.714%;
  margin-bottom: 0px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}
.sidebar__comments__title a {
  color: inherit;
  transition: all 500ms ease;
}
.sidebar__comments__title a:hover {
  color: var(--laboix-secondary, #0EBD66);
}

@media (max-width: 991px) {
  .service-sidebar__single {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}
.service-sidebar__single + .service-sidebar__single {
  margin-top: 30px;
}
.service-sidebar__title {
  position: relative;
  margin: 0;
  padding: 25px 30px;
  background-color: var(--laboix-base, #021936);
  color: var(--laboix-white, #fff);
  font-size: 20px;
  font-weight: 700;
  line-height: 108.333%;
}
.service-sidebar__title::after {
  content: "";
  width: 40px;
  height: 25px;
  position: absolute;
  bottom: -20px;
  left: 8%;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background: var(--laboix-base, #021936);
  transform: rotate(180deg);
}
@media (min-width: 576px) {
  .service-sidebar__title {
    font-size: 24px;
  }
}
.service-sidebar__nav {
  border-top: 0;
  margin-bottom: 0;
  padding: 40px 30px;
  background: var(--laboix-secondary, #0EBD66);
}
@media (max-width: 1200px) and (min-width: 992px) {
  .service-sidebar__nav {
    padding: 30px 20px;
  }
}
@media (max-width: 575px) {
  .service-sidebar__nav {
    padding: 30px 20px;
  }
}
.service-sidebar__nav li a {
  overflow: hidden;
  position: relative;
  z-index: 1;
  color: var(--laboix-base, #021936);
  background: var(--laboix-white, #fff);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 500ms ease;
  padding: 14px 30px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 162.5%;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.08);
}
@media (max-width: 1200px) and (min-width: 992px) {
  .service-sidebar__nav li a {
    font-size: 14px;
  }
}
@media (max-width: 575px) {
  .service-sidebar__nav li a {
    font-size: 14px;
  }
}
.service-sidebar__nav li a::after {
  content: "\e91f";
  font-family: "icomoon" !important;
  font-weight: 900;
  transition: all 500ms ease;
  font-size: 12px;
  color: var(--laboix-secondary, #0EBD66);
}
@media (max-width: 1200px) and (min-width: 992px) {
  .service-sidebar__nav li a::after {
    font-size: 12px;
  }
}
.service-sidebar__nav li a::before {
  content: "";
  width: 0%;
  height: 100%;
  background-color: var(--laboix-base, #021936);
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  z-index: -1;
  transition: all 500ms ease-in-out;
}
.service-sidebar__nav li.current a, .service-sidebar__nav li:hover a {
  color: var(--laboix-white, #fff);
}
.service-sidebar__nav li.current a::after, .service-sidebar__nav li:hover a::after {
  color: var(--laboix-secondary, #0EBD66);
}
.service-sidebar__nav li.current a::before, .service-sidebar__nav li:hover a::before {
  width: 100%;
  left: 0;
  right: auto;
}
.service-sidebar__nav li:last-child a {
  margin-bottom: 0;
}
.service-sidebar__contact {
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: 33px 30px 193px 30px;
  background-color: var(--laboix-base, #021936);
}
.service-sidebar__contact-title {
  margin-top: -5px;
  max-width: 230px;
  margin-bottom: 42px;
  color: var(--laboix-white, #fff);
  font-size: 25px;
  font-weight: 700;
  line-height: 133.333%;
}
@media (min-width: 992px) {
  .service-sidebar__contact-title {
    font-size: 30px;
  }
}
.service-sidebar__contact-btn .laboix-btn {
  padding: 7.5px 30px;
  color: var(--laboix-white, #fff);
  background: var(--laboix-secondary, #0EBD66);
  font-size: 16px;
  font-weight: 600;
  line-height: 162.5%;
}
.service-sidebar__contact-btn .laboix-btn::before {
  background: var(--laboix-secondary, #0EBD66);
}
.service-sidebar__contact-btn .laboix-btn:hover {
  color: var(--laboix-white, #fff);
}
.service-sidebar__contact-image, .service-sidebar__contact-angle {
  bottom: 0;
  left: 0;
  position: absolute;
  object-fit: cover;
  z-index: -2;
}
.service-sidebar__contact-image {
  right: 0;
  left: auto;
  z-index: -1;
}
.service-sidebar__contact-image img {
  max-height: 320px;
  width: auto;
}

/*--------------------------------------------------------------
# Blog details
--------------------------------------------------------------*/
.blog-details .blog-card__two {
  margin-bottom: 40px;
}
.blog-details .blog-card__two__text + .blog-card__two__text {
  margin-top: 21px;
}
.blog-details__meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid var(--laboix-border-color, #DBDBDB);
  border-bottom: 1px solid var(--laboix-border-color, #DBDBDB);
}
.blog-details__tags {
  display: flex;
  align-items: center;
  gap: 10px;
}
.blog-details__tags__title {
  margin: 0;
  margin-top: -4px;
  margin-bottom: -2px;
  color: var(--laboix-base, #021936);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media (min-width: 768px) {
  .blog-details__tags__title {
    font-size: 20px;
  }
}
.blog-details__tags .sidebar__tags button {
  color: var(--laboix-white, #fff);
  padding: 11px 16px;
  font-size: 16px;
  background: var(--laboix-secondary, #0EBD66);
}
.blog-details__tags .sidebar__tags button:hover {
  background-color: var(--laboix-black, #000);
  color: var(--laboix-white, #fff);
}
.blog-details__tags .sidebar__tags2 a {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.blog-details__tags .sidebar__tags2 a:hover {
  color: var(--laboix-secondary, #0EBD66);
}

/*--------------------------------------------------------------
# Comments
--------------------------------------------------------------*/
.comments-one {
  margin-top: 40px;
}
@media (min-width: 1200px) {
  .comments-one {
    margin-top: 50px;
  }
}
.comments-one__title {
  margin: 0;
  font-size: 25px;
  margin-top: -4px;
  margin-bottom: -4px;
  color: var(--laboix-base, #021936);
  font-weight: 700;
  line-height: normal;
}
@media (min-width: 992px) {
  .comments-one__title {
    font-size: 30px;
  }
}
.comments-one__list {
  margin: 0;
  margin-top: 35px;
}
.comments-one__card {
  border-bottom: 1px solid var(--laboix-border-color, #DBDBDB);
  padding-bottom: 50px;
}
@media (min-width: 768px) {
  .comments-one__card {
    display: flex;
    align-items: flex-start;
  }
}
@media (min-width: 1200px) {
  .comments-one__card:not(:first-of-type) {
    margin-top: 50px;
  }
}
@media (min-width: 768px) {
  .comments-one__card__image {
    margin-right: 30px;
  }
}
.comments-one__card__image img {
  border-radius: 50%;
}
.comments-one__card__title {
  margin: 0;
  color: var(--laboix-base, #021936);
  font-size: 24px;
  font-weight: 700;
  line-height: 108.333%;
  margin-bottom: 10px;
}
@media (min-width: 1200px) {
  .comments-one__card__title {
    margin-bottom: 18px;
  }
}
.comments-one__card__text {
  margin: 0;
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
  max-width: 560px;
}
@media (min-width: 768px) {
  .comments-one__card__text {
    margin-bottom: 0;
  }
}
.comments-one__card__btn {
  margin-top: 22px;
}
.comments-one__card__reply {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  padding: 10px 21.5px;
  background: var(--laboix-border-color2, #F3F3F3);
}
.comments-one__card__content {
  position: relative;
}

.comments-form {
  margin-top: 40px;
}
@media (min-width: 1200px) {
  .comments-form {
    margin-top: 55px;
  }
}
.comments-form__title {
  margin: 0;
  font-size: 25px;
  margin-top: -4px;
  margin-bottom: -4px;
  color: var(--laboix-base, #021936);
  font-weight: 700;
  line-height: normal;
}
@media (min-width: 992px) {
  .comments-form__title {
    font-size: 30px;
  }
}
.comments-form__form {
  margin-top: 45px;
}

/*--------------------------------------------------------------
# Shop
--------------------------------------------------------------*/
.product {
  position: relative;
}
.product__sidebar {
  position: relative;
}
.product__sidebar--title {
  position: relative;
  margin-top: -5px;
  color: var(--laboix-base, #021936);
  font-size: 20px;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 23px;
}
.product__sidebar__search {
  position: relative;
  margin-bottom: 30px;
}
.product__sidebar__search input[type=search],
.product__sidebar__search input[type=text] {
  outline: none;
  width: 100%;
  height: 60px;
  background-color: var(--laboix-secondary, #0EBD66);
  color: var(--laboix-white, #fff);
  font-family: var(--laboix-font, "Lexend", sans-serif);
  font-size: 16px;
  font-weight: 400;
  line-height: 162.5%;
  padding-left: 60px;
  padding-right: 30px;
  transition: all 500ms ease;
  border: none;
}
.product__sidebar__search input[type=search]:focus,
.product__sidebar__search input[type=text]:focus {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  border: none;
}
.product__sidebar__search input[type=search]::placeholder,
.product__sidebar__search input[type=text]::placeholder {
  color: var(--laboix-white, #fff);
  font-size: 16px;
  font-weight: 400;
  line-height: 162.5%;
}
.product__sidebar__search button[type=submit] {
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  width: auto;
  font-size: 22px;
  color: var(--laboix-white, #fff);
}
.product__price-ranger {
  background-color: var(--laboix-white, #fff);
  box-shadow: 0px 0px 60px 0px rgba(2, 2, 2, 0.07);
  padding: 30px;
  margin-bottom: 30px;
}
.product__price-ranger #slider-range {
  margin: 15px 0 23px 0px;
  background: var(--laboix-border-color2, #F3F3F3);
  border: none;
  height: 7px;
  border-radius: 20px;
  position: relative;
}
.product__price-ranger #slider-range .ui-slider-range {
  height: 100%;
  background: var(--laboix-secondary, #0EBD66);
}
.product__price-ranger #slider-range .ui-slider-handle {
  position: absolute;
  top: -5px;
  background: var(--laboix-secondary, #0EBD66);
  border: 0;
  height: 16px;
  width: 16px !important;
  border-radius: 50%;
  margin-left: -3px;
  outline: medium none;
  cursor: pointer;
  z-index: 2;
}
.product__price-ranger .ranger-min-max-block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 18px 0 0 0px;
}
.product__price-ranger .ranger-min-max-block input[type=text] {
  position: relative;
  display: inline-block;
  color: var(--laboix-text, #6A6A6A);
  width: 45px;
  border: none;
  outline: none;
  padding: 0;
  text-align: center;
  background-color: transparent;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}
.product__price-ranger .ranger-min-max-block span {
  position: relative;
  display: inline-block;
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 700;
  left: -2px;
}
.product__price-ranger .ranger-min-max-block button[type=submit] {
  position: relative;
  display: block;
  float: right;
  text-align: center;
  border: none;
  margin: 0;
  cursor: pointer;
  padding: 0px 16px;
  height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 185.714%;
  text-transform: capitalize;
  z-index: 1;
  color: var(--laboix-text, #6A6A6A);
  background: var(--laboix-border-color2, #F3F3F3);
}
.product__price-ranger .ranger-min-max-block button[type=submit]:hover {
  color: var(--laboix-white, #fff);
}
.product__categories {
  margin-bottom: -4px;
  background-color: var(--laboix-white, #fff);
  padding: 30px 30px 23px 30px;
  box-shadow: 0px 0px 60px 0px rgba(2, 2, 2, 0.07);
}
.product__categories__title {
  margin: 0;
  margin-top: -5px;
  padding-bottom: 15px;
  margin-bottom: 14px;
  border-bottom: 1px solid var(--laboix-secondary, #0EBD66);
  position: relative;
  color: var(--laboix-base, #021936);
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}
.product__categories ul {
  margin-bottom: 0;
}
.product__categories ul li:not(:last-of-type) {
  border-bottom: 1px solid var(--laboix-border-color, #DBDBDB);
}
.product__categories ul li:first-child a {
  padding-top: 0;
}
.product__categories ul li:last-child a {
  padding-bottom: 0 !important;
}
.product__categories ul li a {
  display: flex;
  align-items: center;
  padding: 10px 0 14.44px 0px;
  transition: all 500ms ease;
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.product__categories ul li a i {
  font-size: 12px;
  margin-right: 7px;
  color: var(--laboix-secondary, #0EBD66);
}
.product__categories ul li a:hover {
  padding-left: 20px;
  background-color: var(--laboix-white, #fff);
  color: var(--laboix-secondary, #0EBD66);
}
.product__info-top {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .product__info-top {
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  .product__info-top {
    display: block;
    margin-top: 40px;
  }
}
.product__showing-text {
  margin: 0;
  font-size: 20px;
  color: var(--laboix-base, #021936);
  font-weight: 700;
  line-height: 130%;
  text-shadow: 0 0 0 1px currentColor;
}
@media (max-width: 767px) {
  .product__showing-text {
    margin-bottom: 20px;
  }
}
.product__showing-sort {
  margin: 0;
  font-size: 18px;
}
.product__showing-sort .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  position: relative;
  display: block;
  width: 270px !important;
}
@media (max-width: 360px) {
  .product__showing-sort .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 270px !important;
  }
}
.product__showing-sort .bootstrap-select > .dropdown-toggle::after {
  display: none;
}
.product__showing-sort .bootstrap-select .dropdown-menu {
  border: none;
}
.product__showing-sort .bootstrap-select > .dropdown-toggle {
  position: relative;
  height: 64px;
  outline: none !important;
  border-radius: 0;
  border: 0;
  background-color: var(--laboix-border-color2, #F3F3F3) !important;
  margin: 0;
  padding: 0;
  padding-left: 30px;
  padding-right: 30px;
  color: var(--laboix-text, #6A6A6A) !important;
  line-height: 64px;
  box-shadow: none !important;
  background-repeat: no-repeat;
  background-size: 14px 12px;
  background-position: right 25.75px center;
  font-size: 16px;
  font-weight: 600;
}
.product__showing-sort .bootstrap-select > .dropdown-toggle:before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  font-weight: 900;
  font-size: 16px;
  color: var(--laboix-secondary, #0EBD66);
}
.product__showing-sort .bootstrap-select .dropdown-menu > li + li > a {
  border-top: 1px solid var(--laboix-border-color, #DBDBDB);
}
.product__showing-sort .bootstrap-select .dropdown-menu > li > a {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 30px;
  color: var(--laboix-text, #6A6A6A);
  background-color: var(--laboix-border-color2, #F3F3F3);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.product__showing-sort .bootstrap-select .dropdown-menu > li:hover > a,
.product__showing-sort .bootstrap-select .dropdown-menu > li.selected > a {
  background: var(--laboix-base, #021936);
  color: var(--laboix-white, #fff);
  border-color: var(--laboix-base, #021936);
}
.product__item {
  position: relative;
  background-color: var(--laboix-white, #fff);
  border: 1px solid var(--laboix-border-color, #DBDBDB);
  transition: all 500ms ease;
}
.product__item:hover {
  box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid var(--laboix-secondary, #0EBD66);
}
.product__item__img {
  background-color: var(--laboix-white, #fff);
  position: relative;
  overflow: hidden;
}
.product__item__img__item {
  max-width: 228px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.product__item__img__item img {
  width: 100%;
  height: auto;
  mix-blend-mode: multiply;
  transition: all 500ms ease;
  transform: scale(1);
}
.product__item__btn {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2;
}
.product__item__btn a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: var(--laboix-border-color2, #F3F3F3);
  border-radius: 50%;
  color: var(--laboix-text, #6A6A6A);
  font-size: 14px;
  visibility: hidden;
  opacity: 0;
}
.product__item__btn a:hover {
  background-color: var(--laboix-secondary, #0EBD66);
  color: var(--laboix-white, #fff);
}
.product__item__btn a:nth-child(1) {
  -webkit-transition: transform 350ms cubic-bezier(0.445, 0.05, 0.55, 0.95), opacity ease 300ms, visibility ease 300ms, background ease 300ms, color ease 300ms, border ease 300ms;
  -moz-transition: transform 350ms cubic-bezier(0.445, 0.05, 0.55, 0.95), opacity ease 300ms, visibility ease 300ms, background ease 300ms, color ease 300ms, border ease 300ms;
  -ms-transition: transform 350ms cubic-bezier(0.445, 0.05, 0.55, 0.95), opacity ease 300ms, visibility ease 300ms, background ease 300ms, color ease 300ms, border ease 300ms;
  -o-transition: transform 350ms cubic-bezier(0.445, 0.05, 0.55, 0.95), opacity ease 300ms, visibility ease 300ms, background ease 300ms, color ease 300ms, border ease 300ms;
  transition: transform 350ms cubic-bezier(0.445, 0.05, 0.55, 0.95), opacity ease 300ms, visibility ease 300ms, background ease 300ms, color ease 300ms, border ease 300ms;
  transform: translate3d(30px, 0, 0);
  -moz-transform: translate3d(30px, 0, 0);
  -webkit-transform: translate3d(30px, 0, 0);
  -ms-transform: translate3d(30px, 0, 0);
  -o-transform: translate3d(30px, 0, 0);
}
.product__item__btn a:nth-child(2) {
  -webkit-transition: transform 550ms cubic-bezier(0.445, 0.05, 0.55, 0.95), opacity ease 300ms, visibility ease 300ms, background ease 300ms, color ease 300ms, border ease 300ms;
  -moz-transition: transform 550ms cubic-bezier(0.445, 0.05, 0.55, 0.95), opacity ease 300ms, visibility ease 300ms, background ease 300ms, color ease 300ms, border ease 300ms;
  -ms-transition: transform 550ms cubic-bezier(0.445, 0.05, 0.55, 0.95), opacity ease 300ms, visibility ease 300ms, background ease 300ms, color ease 300ms, border ease 300ms;
  -o-transition: transform 550ms cubic-bezier(0.445, 0.05, 0.55, 0.95), opacity ease 300ms, visibility ease 300ms, background ease 300ms, color ease 300ms, border ease 300ms;
  transition: transform 550ms cubic-bezier(0.445, 0.05, 0.55, 0.95), opacity ease 300ms, visibility ease 300ms, background ease 300ms, color ease 300ms, border ease 300ms;
  transform: translate3d(30px, 0, 0);
  -moz-transform: translate3d(30px, 0, 0);
  -webkit-transform: translate3d(30px, 0, 0);
  -ms-transform: translate3d(30px, 0, 0);
  -o-transform: translate3d(30px, 0, 0);
}
.product__item__btn a + a {
  margin-top: 10px;
}
.product__item:hover .product__item__img img {
  transform: scale(1.05);
}
.product__item:hover .product__item__btn a {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
}
.product__item__content {
  position: relative;
  text-align: center;
  padding: 20px 20px 40px 20px;
}
.product__item__ratings {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: var(--laboix-secondary, #0EBD66);
  letter-spacing: 4.5px;
  margin-bottom: 25px;
}
.product__item__title {
  margin: 0;
  color: var(--laboix-base, #021936);
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 10px;
}
.product__item__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.product__item__title a:hover {
  background-size: 100% 1px;
}
.product__item__title a:hover {
  color: var(--laboix-secondary, #0EBD66);
}
.product__item__price {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: var(--laboix-text, #6A6A6A);
  line-height: 1em;
  font-weight: 500;
  margin-bottom: 10px;
}
.product__item__price del {
  margin-left: 7px;
}
.product__item__link {
  color: var(--laboix-white, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 185.714%;
  padding: 7px 32px;
  text-transform: none;
}

.product-one {
  padding: 120px 0;
}
@media (max-width: 767px) {
  .product-one {
    padding: 80px 0;
  }
}
.product-one--home {
  position: relative;
  padding: 0 0 90px;
}
@media (max-width: 767px) {
  .product-one--home {
    padding-bottom: 50px;
  }
}
.product-one--home .sec-title {
  text-align: center;
}
.product-one--home .product__item {
  margin-bottom: 30px;
}
.product-one__carousel .owl-nav {
  display: none !important;
}

/*--------------------------------------------------------------
# Shop details
--------------------------------------------------------------*/
.product-details {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 991px) {
  .product-details {
    padding: 100px 0;
  }
}
@media (max-width: 767px) {
  .product-details {
    padding: 80px 0;
  }
}
.product-details__img {
  background-color: var(--laboix-white, #fff);
  position: relative;
  border: 1px solid var(--laboix-border-color, #DBDBDB);
  max-width: 570px;
  height: auto;
}
.product-details__img img {
  object-fit: cover;
  width: 100%;
  height: auto;
}
.product-details__content {
  position: relative;
  margin-top: -10px;
}
@media (max-width: 991px) {
  .product-details__content {
    margin-top: 50px;
  }
}
.product-details__top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.product-details__title {
  margin-top: 5px;
  font-size: 25px;
  color: var(--laboix-base, #021936);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media (min-width: 768px) {
  .product-details__title {
    font-size: 30px;
  }
}
.product-details__price {
  margin: 0 0 0 17px;
  color: var(--laboix-secondary, #0EBD66);
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;
}
.product-details__review {
  position: relative;
  display: flex;
  align-items: center;
  letter-spacing: 2.5px;
  font-size: 17px;
  color: var(--laboix-secondary, #0EBD66);
}
.product-details__review a {
  display: inline-block;
  margin-left: 20px;
  transition: all 500ms ease;
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0;
}
.product-details__review a:hover {
  color: var(--laboix-secondary, #0EBD66);
}
.product-details__divider {
  width: 100%;
  height: 1px;
  background-color: var(--laboix-border-color, #DBDBDB);
  margin: 32px 0 20px 0;
}
.product-details__excerpt {
  margin: 0;
  font-size: 15px;
  line-height: 30px;
}
.product-details__excerpt-text1 {
  margin: 0 0 17px;
  max-width: 514px;
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
}
.product-details__excerpt-text2 {
  color: var(--laboix-text, #6A6A6A);
  margin-bottom: 37px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
@media (max-width: 767px) {
  .product-details__excerpt-text2 br {
    display: block;
  }
}
.product-details__excerpt-text2 span {
  display: block;
  margin-top: 5px;
  font-weight: 600;
  color: var(--laboix-secondary, #0EBD66);
}
.product-details__quantity {
  position: relative;
  display: flex;
  align-items: center;
}
.product-details__quantity-title {
  margin: 0;
  margin-right: 35px;
  font-weight: bold;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
}
.product-details__quantity .quantity-box {
  position: relative;
  width: auto;
  height: 46px;
  display: flex;
}
.product-details__quantity .quantity-box input {
  width: 45px;
  height: 46px;
  border: 1px solid var(--laboix-border-color, #DBDBDB);
  appearance: textfield;
  color: var(--laboix-base, #021936);
  padding-left: 15px;
  outline: none;
  font-size: 18px;
  font-weight: 500;
  background-color: transparent;
}
.product-details__quantity .quantity-box button {
  width: 45px;
  height: auto;
  color: var(--laboix-text, #6A6A6A);
  font-size: 12px;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  transition: all 500ms ease;
}
.product-details__quantity .quantity-box button:hover, .product-details__quantity .quantity-box button:focus, .product-details__quantity .quantity-box button:active {
  background: var(--laboix-secondary, #0EBD66);
  color: var(--laboix-white, #fff);
}
.product-details__quantity .quantity-box button.sub {
  bottom: 1px;
  top: auto;
  border-top: 1px solid var(--laboix-border-color, #DBDBDB);
  border-left: 1px solid var(--laboix-border-color, #DBDBDB);
  border-bottom: 1px solid var(--laboix-border-color, #DBDBDB);
}
.product-details__quantity .quantity-box button.add {
  border-top: 1px solid var(--laboix-border-color, #DBDBDB);
  border-right: 1px solid var(--laboix-border-color, #DBDBDB);
  border-bottom: 1px solid var(--laboix-border-color, #DBDBDB);
}
.product-details__quantity .quantity-box button:hover {
  color: var(--laboix-white, #fff);
}
.product-details__buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 40px 0px 0;
}
.product-details__buttons a {
  color: var(--laboix-white, #fff);
}
@media (max-width: 768px) {
  .product-details__buttons a {
    padding: 16.55px 35px;
  }
}
.product-details__buttons a:hover {
  color: var(--laboix-white, #fff);
}
.product-details__socials {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 38px;
  margin-top: 40px;
}
.product-details__socials__title {
  display: flex;
  margin-bottom: 0;
  color: var(--laboix-base, #021936);
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
}
@media (min-width: 768px) {
  .product-details__socials__title {
    flex: 0 0 auto;
  }
}
.product-details__socials__item {
  display: flex;
  gap: 20px;
}
.product-details__socials__item a {
  display: inline;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--laboix-border-color, #DBDBDB);
  font-size: 12px;
  color: var(--laboix-base, #021936);
  transition: all 500ms ease;
  border-radius: 50%;
}
.product-details__socials__item a:hover {
  background-color: var(--laboix-base, #021936);
  color: var(--laboix-white, #fff);
}
.product-details__description {
  position: relative;
  margin: 50px 0 0;
}
@media (min-width: 768px) {
  .product-details__description {
    margin: 72px 0 0;
  }
}
.product-details__description__title {
  margin-bottom: 22px;
  color: var(--laboix-base, #021936);
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
}
.product-details__description__text {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
}
.product-details__description__lists {
  margin-bottom: 25px;
  margin-top: 25px;
  padding: 0;
}
.product-details__description__lists li {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
  color: var(--laboix-base, #021936);
}
.product-details__description__lists li i {
  color: var(--laboix-secondary, #0EBD66);
  margin-right: 5px;
}
.product-details__comment {
  border-top: 1px solid var(--laboix-border-color, #DBDBDB);
  margin: 30px 0 0;
  padding: 30px 0 0;
  position: relative;
}
@media (min-width: 768px) {
  .product-details__comment {
    margin: 44px 0 0;
    padding: 43px 0 0;
  }
}
.product-details__review-title {
  margin-bottom: 22px;
  color: var(--laboix-base, #021936);
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
}
.product-details__comment-box {
  position: relative;
  padding: 11px 0 52px 200px;
  margin-bottom: 45px;
  min-height: 166px;
  border-bottom: 1px solid var(--laboix-border-color, #DBDBDB);
}
@media (max-width: 767px) {
  .product-details__comment-box {
    padding-left: 0;
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
}
.product-details__comment-box__thumb {
  width: 168px;
  height: 168px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  margin: 0;
  border: none;
}
@media (max-width: 767px) {
  .product-details__comment-box__thumb {
    position: relative;
    margin: 0 0 20px;
  }
}
.product-details__comment-box__thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.product-details__comment-box__meta {
  margin: 0 0 20px;
  color: var(--laboix-base, #021936);
  font-size: 24px;
  font-weight: 700;
  line-height: 108.333%;
}
.product-details__comment-box__date {
  margin-left: 20px;
  display: inline-block;
  text-transform: inherit;
  color: var(--laboix-secondary, #0EBD66);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
  letter-spacing: 1.6px;
}
.product-details__comment-box__text {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
}
.product-details__comment-box__ratings {
  position: absolute;
  right: 0;
  top: 13px;
  display: flex;
  align-items: center;
  letter-spacing: 3px;
  font-size: 17px;
  color: var(--laboix-secondary, #0EBD66);
}
@media (max-width: 767px) {
  .product-details__comment-box__ratings {
    position: relative;
    top: 0;
    margin: 0 0 22px;
  }
}
.product-details__form {
  position: relative;
}
.product-details__form .row {
  --bs-gutter-x: 20px;
}
.product-details__form-title {
  font-size: 30px;
  margin-bottom: 18px;
  font-weight: bold;
  color: var(--laboix-base, #021936);
}
.product-details__form-ratings {
  display: flex;
  align-items: center;
  letter-spacing: 6px;
  font-size: 25px;
  color: var(--laboix-secondary, #0EBD66);
  margin: 0 0 40px;
}
.product-details__form-ratings i {
  color: var(--laboix-secondary, #0EBD66);
}
.product-details__form-ratings__label {
  display: inline-block;
  letter-spacing: 0;
  color: var(--laboix-text, #6A6A6A);
  margin: 0 17px 0 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 130%;
}
.product-details__form__form {
  margin-top: 0;
}
.product-details__form__form .form-one__control input, .product-details__form__form .form-one__control textarea {
  background: var(--laboix-border-color2, #F3F3F3);
}
.product-details__form__form .form-one__control input::placeholder, .product-details__form__form .form-one__control textarea::placeholder {
  color: var(--laboix-text, #6A6A6A);
}
.product-details__form__form .laboix-btn {
  padding: 19px 40.55px;
}
.product-details__form__form .laboix-btn:hover {
  color: var(--laboix-white, #fff);
}

/*--------------------------------------------------------------
# Cart
--------------------------------------------------------------*/
.cart-page {
  position: relative;
  padding: 120px 0;
  padding-top: 100px;
}
@media (max-width: 767px) {
  .cart-page {
    padding: 80px 0;
    padding-top: 60px;
  }
}
.cart-page .table-responsive {
  position: relative;
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 1199px) {
  .cart-page .table-responsive {
    margin-bottom: 30px;
  }
}
.cart-page__table {
  position: relative;
  width: 100%;
  border: none;
  margin: 0 0 60px;
}
@media (max-width: 1199px) {
  .cart-page__table {
    min-width: 1170px;
  }
}
.cart-page__table thead tr th {
  padding: 0 0 13px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid var(--laboix-border-color, #DBDBDB) !important;
  box-shadow: none;
  color: var(--laboix-base, #021936);
  font-size: 20px;
  font-weight: 700;
  line-height: 235%;
}
.cart-page__table thead tr th:last-child {
  text-align: right;
}
.cart-page__table tbody tr td {
  vertical-align: middle;
  border: none;
  box-shadow: none;
  background-color: transparent;
  border-top: 1px solid var(--laboix-border-color, #DBDBDB);
  border-bottom: 1px solid var(--laboix-border-color, #DBDBDB);
  padding: 30px 0;
  letter-spacing: 0;
  color: var(--laboix-base, #021936);
  font-size: 20px;
  font-weight: 500;
  line-height: 235%;
}
.cart-page__table tbody tr td:last-child {
  text-align: right;
}
.cart-page__table__meta {
  display: flex;
  align-items: center;
}
.cart-page__table__meta-img {
  width: 109px;
  height: 107px;
  background-color: var(--laboix-white, #fff);
  border: 1px solid var(--laboix-border-color, #DBDBDB);
  margin-right: 34px;
}
.cart-page__table__meta-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  mix-blend-mode: multiply;
}
.cart-page__table__meta-title {
  margin: 0;
  color: var(--laboix-base, #021936);
  font-size: 20px;
  font-weight: 700;
  line-height: 235%;
}
.cart-page__table__meta-title a {
  color: inherit;
}
.cart-page__table__meta-title a:hover {
  color: var(--laboix-secondary, #0EBD66);
}
.cart-page__table__remove {
  display: block;
  color: var(--laboix-black, #000);
  font-size: 16px;
}
.cart-page__table__remove:hover {
  color: var(--laboix-secondary, #0EBD66);
}
.cart-page__coupone-form {
  position: relative;
  display: flex;
  gap: 20px;
}
@media (max-width: 767px) {
  .cart-page__coupone-form {
    display: block;
  }
}
.cart-page__coupone-form input[type=text] {
  height: 56px;
  width: 303px;
  border: none;
  background-color: var(--laboix-border-color2, #F3F3F3);
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
  font-size: 16px;
  color: var(--laboix-text, #6A6A6A);
  font-family: var(--laboix-font, "Lexend", sans-serif);
  display: block;
  font-weight: 500;
}
.cart-page__coupone-form input[type=text]::placeholder {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
}
@media (max-width: 1199px) {
  .cart-page__coupone-form input[type=text] {
    width: 290px;
  }
}
@media (max-width: 767px) {
  .cart-page__coupone-form input[type=text] {
    width: 100%;
    margin: 0 0 10px;
  }
}
.cart-page__cart-total {
  position: relative;
  text-align: right;
  margin: -8px 0 24px;
  padding: 0;
}
@media (max-width: 991px) {
  .cart-page__cart-total {
    text-align: left;
    margin-top: 45px;
  }
}
.cart-page__cart-total li {
  display: block;
  margin: 0 0 0px;
  color: var(--laboix-base, #021936);
  font-size: 20px;
  font-weight: 700;
  line-height: 220%;
}
@media (max-width: 991px) {
  .cart-page__cart-total li span {
    display: inline-block;
    min-width: 172px;
  }
}
.cart-page__cart-total-amount {
  display: inline-block;
  min-width: 172px;
  color: var(--laboix-text, #6A6A6A);
  font-size: 20px;
  font-weight: 500;
  line-height: 220%;
}
.cart-page__buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
@media (max-width: 991px) {
  .cart-page__buttons {
    justify-content: flex-start;
  }
}

/*--------------------------------------------------------------
# Checkout
--------------------------------------------------------------*/
.checkout-page {
  position: relative;
  padding: 120px 0;
  padding-top: 100px;
}
@media (max-width: 991px) {
  .checkout-page {
    padding: 100px 0;
    padding-top: 80px;
  }
}
@media (max-width: 767px) {
  .checkout-page {
    padding: 80px 0;
    padding-top: 60px;
  }
}
.checkout-page .bs-gutter-x-20 {
  --bs-gutter-x: 20px;
}
.checkout-page__notice {
  max-width: 570px;
  margin: 0 0 35px;
  padding: 34px 50px;
  background: var(--laboix-border-color2, #F3F3F3);
  color: var(--laboix-base, #021936);
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
}
.checkout-page__notice a {
  color: var(--laboix-secondary, #0EBD66);
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.checkout-page__notice a:hover {
  background-size: 100% 1px;
}
.checkout-page__billing-address {
  position: relative;
}
.checkout-page__billing-address__title {
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 34px;
}
@media (max-width: 767px) {
  .checkout-page__billing-address__title {
    font-size: 28px;
  }
}
.checkout-page__shipping-address {
  position: relative;
}
@media (max-width: 991px) {
  .checkout-page__shipping-address {
    margin: 50px 0 0;
  }
}
.checkout-page__shipping-address__title {
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 34px;
  letter-spacing: -1.2px;
}
@media (max-width: 767px) {
  .checkout-page__shipping-address__title {
    font-size: 28px;
  }
}
.checkout-page__input-box {
  position: relative;
  line-height: 1;
  margin: 0 0 20px;
}
.checkout-page__input-box input[type=text],
.checkout-page__input-box input[type=email],
.checkout-page__input-box input[type=tel], .checkout-page__input-box textarea {
  height: 60px;
  width: 100%;
  border: none;
  background-color: var(--laboix-border-color2, #F3F3F3);
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
  display: block;
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.checkout-page__input-box input[type=text]::placeholder,
.checkout-page__input-box input[type=email]::placeholder,
.checkout-page__input-box input[type=tel]::placeholder, .checkout-page__input-box textarea::placeholder {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.checkout-page__input-box .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  position: relative;
  display: block;
  width: 100% !important;
  font-family: var(--laboix-font, "Lexend", sans-serif);
}
.checkout-page__input-box .bootstrap-select > .dropdown-toggle::after {
  display: none;
}
.checkout-page__input-box .bootstrap-select > .dropdown-toggle {
  position: relative;
  height: 58px;
  outline: none !important;
  border-radius: 0;
  border: 0;
  background-color: var(--laboix-border-color, #DBDBDB) !important;
  margin: 0;
  padding: 0;
  padding-left: 30px;
  padding-right: 30px;
  color: var(--laboix-text, #6A6A6A) !important;
  font-size: 14px;
  line-height: 58px;
  font-weight: 500;
  box-shadow: none !important;
  background-repeat: no-repeat;
  background-size: 14px 12px;
  background-position: right 25.75px center;
}
.checkout-page__input-box .bootstrap-select > .dropdown-toggle:before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  font-weight: 900;
  font-size: 12px;
  color: var(--laboix-text, #6A6A6A);
}
.checkout-page__input-box .bootstrap-select .dropdown-menu > li + li > a {
  border-top: 1px solid var(--laboix-border-color, #DBDBDB);
}
.checkout-page__input-box .bootstrap-select .dropdown-menu {
  border: none;
}
.checkout-page__input-box .bootstrap-select .dropdown-menu > li > a {
  font-size: 14px;
  font-weight: 500;
  padding: 15px 30px;
  color: var(--laboix-text, #6A6A6A);
  background-color: var(--laboix-border-color, #DBDBDB);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.checkout-page__input-box .bootstrap-select .dropdown-menu > li:hover > a,
.checkout-page__input-box .bootstrap-select .dropdown-menu > li.selected > a {
  background: var(--laboix-base, #021936);
  color: var(--laboix-white, #fff);
  border-color: var(--laboix-base, #021936);
}
.checkout-page__input-box textarea {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  height: 176px;
  width: 100%;
  background-color: var(--laboix-border-color2, #F3F3F3);
  padding: 20px 30px 30px;
  border: none;
  outline: none;
  margin-bottom: 0px;
}
.checkout-page__check-box {
  position: relative;
  display: block;
  margin-top: -6px;
}
.checkout-page__check-box input[type=checkbox] {
  display: none;
}
.checkout-page__check-box label {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  margin-right: 0px;
  margin-bottom: 0;
  color: var(--laboix-text, #6A6A6A);
  font-size: 15px;
  line-height: 24px;
  text-transform: none;
  cursor: pointer;
}
.checkout-page__check-box label span:before {
  position: absolute;
  top: 0;
  left: 5px;
  line-height: 20px;
  display: inline-block;
  color: var(--laboix-white, #fff);
  font-family: "Font Awesome 5 Free";
  content: "\f00c";
  font-size: 8px;
  font-weight: 900;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  opacity: 0;
}
.checkout-page__check-box input[type=checkbox] + label span {
  position: absolute;
  top: 4px;
  left: 0;
  width: 19px;
  height: 19px;
  background-color: var(--laboix-base, #021936);
  border-radius: 50%;
  border: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.checkout-page__check-box input[type=checkbox]:checked + label span:before {
  opacity: 1;
}
.checkout-page__details {
  margin-top: 25px;
  margin-bottom: 80px;
}
.checkout-page__details__title {
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.2px;
  margin-bottom: 20px;
}
.checkout-page__details__check-box input[type=checkbox] {
  display: none;
}
.checkout-page__details__check-box label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  color: var(--laboix-text, #6A6A6A);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.checkout-page__details__check-box label span {
  position: absolute;
  top: 2px;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: var(--laboix-secondary, #0EBD66);
  border-radius: 50%;
  border: none;
  vertical-align: middle;
  cursor: pointer;
  transition: all 300ms ease;
}
.checkout-page__details__check-box label span::before {
  content: "\f00c";
  color: var(--laboix-white, #fff);
  position: absolute;
  top: 4px;
  left: 6px;
  line-height: 15px;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 10px;
  font-weight: 900;
  transition: all 0.15s ease-in-out;
  opacity: 0;
}
.checkout-page__details__check-box input[type=checkbox]:checked + label span:before {
  opacity: 1;
}
.checkout-page__your-order {
  position: relative;
  margin: 32px 0 0;
}
.checkout-page__your-order__title {
  margin-bottom: 35px;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.2px;
}
@media (max-width: 767px) {
  .checkout-page__your-order__title {
    font-size: 28px;
  }
}
.checkout-page__your-order .nionx-btn {
  padding: 19px 38px;
}
.checkout-page__order-table {
  position: relative;
  width: 100%;
  border: none;
  margin: 0 0 0;
}
.checkout-page__order-table thead tr th {
  color: var(--laboix-base, #021936);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 235%;
  margin: 0;
  padding: 15.5px 0;
  border: none;
  border-top: 1px solid var(--laboix-border-color, #DBDBDB);
  border-bottom: 1px solid var(--laboix-border-color, #DBDBDB);
  font-weight: bold;
}
.checkout-page__order-table thead tr th:last-child {
  text-align: right;
}
.checkout-page__order-table tbody tr td {
  color: var(--laboix-base, #021936);
  font-family: var(--laboix-heading-font, "Lexend", sans-serif);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  padding: 0 0 24px;
  border: none;
}
.checkout-page__order-table tbody tr td:last-child {
  text-align: right;
}
.checkout-page__order-table .pro__price {
  color: var(--laboix-text, #6A6A6A);
  font-weight: 500;
}
.checkout-page__order-table tbody tr:first-child td {
  padding-top: 25px;
}
.checkout-page__order-table tbody tr:last-child td {
  padding-bottom: 26px;
  padding-top: 26px;
  border-top: 1px solid var(--laboix-border-color, #DBDBDB);
}
.checkout-page__order-table tbody tr:last-child td.pro__price {
  color: var(--laboix-base, #021936);
  font-weight: 700;
}
.checkout-page__payment {
  background-color: var(--laboix-border-color2, #F3F3F3);
  padding: 40px 45px 17px;
  min-height: 295px;
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .checkout-page__payment {
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  .checkout-page__payment {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.checkout-page__payment__item {
  position: relative;
}
.checkout-page__payment__title {
  display: flex;
  font-size: 20px;
  margin: 0;
  align-items: center;
  margin-bottom: 28px;
  cursor: pointer;
  font-weight: 700;
}
.checkout-page__payment__title::before {
  content: "";
  width: 22px;
  height: 22px;
  background-color: var(--laboix-white, #fff);
  border-radius: 50%;
  margin-right: 10px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;
  position: relative;
  top: 1px;
  transition: all 500ms ease;
}
.checkout-page__payment__title img {
  margin-left: 15px;
}
.checkout-page__payment__item--active .checkout-page__payment__title::before {
  background-color: var(--laboix-secondary, #0EBD66);
  border-color: var(--laboix-secondary, #0EBD66);
  content: "\f00c";
  color: var(--laboix-white, #fff);
}
.checkout-page__payment__content {
  margin-left: 35px;
  margin-bottom: 35px;
  font-size: 16px;
  line-height: 30px;
}

/*--------------------------------------------------------------
# Login
--------------------------------------------------------------*/
.login-page {
  position: relative;
  padding: 120px 0;
  padding-top: 100px;
}
@media (max-width: 767px) {
  .login-page {
    padding: 80px 0;
    padding-top: 60px;
  }
}
.login-page__inner {
  border: 1px solid var(--laboix-border-color, #DBDBDB);
  background: var(--laboix-white, #fff);
  box-shadow: 0px 6px 40px 0px rgba(0, 0, 0, 0.06);
  padding: 40px 30px;
}
@media (min-width: 768px) {
  .login-page__inner {
    padding: 57px 40px 57px;
  }
}
.login-page__info {
  background-color: var(--laboix-base, #021936);
  padding: 35.5px 50px;
  margin-bottom: 50px;
}
@media (max-width: 991px) {
  .login-page__info {
    margin-bottom: 30px;
    padding: 15px 30px;
  }
  .login-page__info p {
    font-size: 16px;
  }
}
.login-page__info p {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  line-height: normal;
  color: var(--laboix-white, #fff);
}
.login-page__info p span {
  font-weight: 700;
}
.login-page__info p a {
  line-height: normal;
  font-weight: 700;
  display: inline-block;
  color: var(--laboix-secondary, #0EBD66);
  transition: all 500ms ease;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.login-page__info p a:hover {
  background-size: 100% 1px;
}
.login-page__wrap {
  position: relative;
}
.login-page__wrap__title {
  margin-top: -5px;
  margin-bottom: 30px;
  color: var(--laboix-base, #021936);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.login-page__wrap .form__border {
  display: none;
}
@media (min-width: 992px) {
  .login-page__wrap .form__border {
    display: block;
  }
}
.login-page__wrap .form__border::after {
  display: none;
  position: absolute;
  top: 0;
  right: -47px;
  content: "";
  width: 1px;
  height: 100%;
  background: var(--laboix-border-color, #DBDBDB);
}
@media (min-width: 992px) {
  .login-page__wrap .form__border::after {
    display: block;
  }
}
@media (max-width: 992px) {
  .login-page .register-page__wrap {
    margin-top: 50px;
  }
}
.login-page__form {
  position: relative;
  display: block;
}
.login-page__form-input-box {
  position: relative;
  display: block;
  margin-bottom: 20px;
}
.login-page__form-input-box input[type=email],
.login-page__form-input-box input[type=password] {
  height: 60px;
  width: 100%;
  border: none;
  background-color: var(--laboix-border-color2, #F3F3F3);
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
  color: var(--laboix-text, #6A6A6A);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  display: block;
}
.login-page__form-input-box input[type=email]::placeholder,
.login-page__form-input-box input[type=password]::placeholder {
  color: var(--laboix-text, #6A6A6A);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.login-page__checked-box {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 26px;
  margin-bottom: 35px;
}
@media (max-width: 768px) {
  .login-page__checked-box {
    display: block;
  }
}
.login-page__checked-box label {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  margin-right: 0px;
  margin-bottom: 0;
  text-transform: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}
.login-page__checked-box label span:before {
  position: absolute;
  top: 2px;
  left: 5px;
  display: block;
  border-bottom: 2px solid var(--laboix-text, #6A6A6A);
  border-right: 2px solid var(--laboix-text, #6A6A6A);
  content: "";
  width: 7px;
  height: 10px;
  pointer-events: none;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
  opacity: 0;
}
.login-page__checked-box input[type=checkbox] {
  display: none;
}
.login-page__checked-box input[type=checkbox] + label span {
  position: absolute;
  top: 4px;
  left: 0;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background: transparent;
  cursor: pointer;
  transition: all 300ms ease;
  border: 1px solid var(--laboix-gray, #f9f6f1);
}
.login-page__checked-box input[type=checkbox]:checked + label span:before {
  opacity: 1;
}
.login-page__form-btn-box {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.login-page__form-btn-box .nionx-btn {
  font-size: 16px;
  font-weight: 600;
  padding: 19px 40px;
}
.login-page__form-forgot-password {
  position: relative;
  display: block;
  margin-left: 0px;
  flex: 0 0 100%;
  margin-top: 10px;
}
@media (min-width: 768px) {
  .login-page__form-forgot-password {
    margin-left: 20px;
    flex: 0 0 auto;
    margin-top: 0;
  }
}
.login-page__form-forgot-password a {
  color: var(--laboix-text, #6A6A6A);
  position: relative;
  display: inline-block;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}
.login-page__form-forgot-password a:hover {
  background-size: 100% 1px;
}
.login-page__form-forgot-password a:hover {
  color: var(--laboix-text, #6A6A6A);
}

.gutter-x-94 {
  --bs-gutter-x: 94px;
}

/*--------------------------------------------------------------
# error 404
--------------------------------------------------------------*/
.error-404 {
  padding-bottom: 80px;
  text-align: center;
  padding-top: 20px;
}
@media (min-width: 768px) {
  .error-404 {
    padding-top: 14px;
  }
}
@media (min-width: 992px) {
  .error-404 {
    padding-bottom: 120px;
  }
}
.error-404__thumb {
  max-width: 628px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-top: 260px;
  margin-bottom: 55px;
}
.error-404__thumb__item {
  position: absolute;
}
.error-404__thumb__item--main {
  position: relative;
}
.error-404__thumb__item--two {
  bottom: 0;
  left: 15%;
  z-index: 1;
  animation: topAni 4s ease-in-out infinite;
}
.error-404__thumb__item--three {
  top: 15%;
  left: 25%;
  z-index: 1;
}
.error-404__sub-title {
  color: var(--laboix-base, #021936);
  font-size: 30px;
  font-weight: 700;
  line-height: 65%;
  margin: 0;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .error-404__sub-title {
    font-size: 35px;
  }
}
@media (min-width: 1200px) {
  .error-404__sub-title {
    font-size: 40px;
  }
}
.error-404__text {
  color: var(--laboix-base, #021936);
  font-size: 16px;
  font-weight: 600;
  line-height: 200%;
  margin: 0;
  margin-bottom: 20px;
}
.error-404__search {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 38px;
  width: 100%;
  max-width: 550px;
}
.error-404__search input[type=text] {
  border: none;
  outline: none;
  display: block;
  background-color: var(--laboix-border-color2, #F3F3F3);
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  max-width: 496px;
  padding-left: 69px;
  height: 60px;
}
.error-404__search input[type=text]::placeholder {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.error-404__search__btn {
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--laboix-text, #6A6A6A);
  position: absolute;
  top: 50%;
  font-weight: 700;
  left: 60px;
  transform: translateY(-50%);
  font-size: 20px;
}

/*--------------------------------------------------------------
# Faq
--------------------------------------------------------------*/
.faq-page {
  padding: 120px 0px;
}
@media (max-width: 767px) {
  .faq-page {
    padding: 80px 0px;
  }
}
.faq-page__contact {
  background: var(--laboix-base, #021936);
}
@media (max-width: 991px) {
  .faq-page__contact {
    margin-bottom: 40px;
  }
}
.faq-page__contact__item {
  position: relative;
  padding: 0px 40px 26px 40px;
  z-index: 1;
  padding-top: 60px;
}
.faq-page__contact__item:last-child {
  background-color: var(--laboix-gray, #f9f6f1);
  border-radius: 39px;
  padding-bottom: 55px;
}
@media (max-width: 576px) {
  .faq-page__contact__item:last-child {
    padding-bottom: 40px;
  }
}
.faq-page__contact__item:last-child .shape__element {
  left: 30%;
}
.faq-page__contact__item:last-child .faq-page__contact__title {
  color: var(--laboix-base, #021936);
}
.faq-page__contact__item:last-child .faq-page__contact__number {
  color: var(--laboix-text, #6A6A6A);
}
.faq-page__contact__item:last-child .faq-page__contact__number br {
  display: block;
}
.faq-page__contact__item:last-child .faq-page__contact__number a {
  color: var(--laboix-base, #021936);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 122.53%;
}
@media (max-width: 576px) {
  .faq-page__contact__item {
    padding: 0px 20px 26px 20px;
    padding-top: 40px;
  }
}
.faq-page__contact__title {
  margin-top: -10px;
  text-align: center;
  color: var(--laboix-white, #fff);
  font-size: 24px;
  font-weight: 700;
  line-height: 147.917%;
  margin-bottom: 20px;
}
.faq-page__contact__icon {
  width: 54px;
  height: 54px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--laboix-white, #fff);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 1;
  margin-bottom: 15px;
}
.faq-page__contact__icon i {
  color: var(--laboix-base, #021936);
  font-size: 25px;
}
.faq-page__contact__number {
  margin: 0;
  color: var(--laboix-border-color, #DBDBDB);
  font-family: var(--laboix-heading-font, "Lexend", sans-serif);
  font-size: 16px;
  font-weight: 500;
  line-height: 141.875%;
  text-align: center;
}
.faq-page__contact__number a {
  color: var(--laboix-white, #fff);
  font-size: 16px;
  font-weight: 600;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
  transition: all 500ms ease;
}
.faq-page__contact__number a:hover {
  background-size: 100% 1px;
}
.faq-page__contact__number a:hover {
  color: var(--laboix-base, #021936);
}
.faq-page__contact .shape__element {
  position: absolute;
  top: 20px;
  left: 20%;
  z-index: -1;
}
.faq-page__search {
  max-width: 330px;
  width: 100%;
  margin-left: auto;
  margin-right: 0;
}
@media (max-width: 991px) {
  .faq-page__search {
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 40px;
  }
}
.faq-page__search__item {
  position: relative;
  background-color: var(--laboix-border-color2, #F3F3F3);
}
.faq-page__search__item input[type=search],
.faq-page__search__item input[type=text] {
  outline: none;
  width: 100%;
  height: 60px;
  background-color: var(--laboix-border-color2, #F3F3F3);
  padding-left: 55px;
  padding-right: 20px;
  transition: all 500ms ease;
  border: none;
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.faq-page__search__item input[type=search]:focus,
.faq-page__search__item input[type=text]:focus {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  border: none;
}
.faq-page__search__item input[type=search]::placeholder,
.faq-page__search__item input[type=text]::placeholder {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.faq-page__search__item button[type=submit] {
  border: none;
  outline: none;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
  width: auto;
  font-size: 18px;
  line-height: 1;
  color: var(--laboix-text, #6A6A6A);
  transition: all 0.4s ease-in-out;
}
.faq-page__search__item button[type=submit]:hover {
  color: var(--laboix-base, #021936);
}
@media (max-width: 991px) {
  .faq-page .sec-title {
    padding-bottom: 25px;
  }
}
.faq-page__content__item {
  background: var(--laboix-base, #021936);
  position: relative;
  z-index: 1;
  padding: 2px;
  transition: all 0.4s ease-in-out;
}
.faq-page__content__item:hover {
  background: var(--laboix-secondary, #0EBD66);
}
.faq-page__content__item:hover .faq-page__content__icon {
  background: var(--laboix-base, #021936);
}
.faq-page__content__icon {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: var(--laboix-secondary, #0EBD66);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  margin-top: -45px;
  font-size: 40px;
  color: var(--laboix-white, #fff);
  transition: all 0.4s ease-in-out;
}
.faq-page__content__title {
  color: var(--laboix-white, #fff);
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 147.917%;
  letter-spacing: -0.48px;
  max-width: 289px;
  margin-left: auto;
  margin-right: auto;
}
.faq-page__content__action {
  margin-top: 21px;
  padding-top: 17px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  text-align: center;
  padding-bottom: 17px;
}
.faq-page__content__action__top {
  display: block;
  color: var(--laboix-border-color, #DBDBDB);
  margin-bottom: -10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 221.875%;
}
.faq-page__content__action__text {
  color: #D9D9D9;
  font-size: 18px;
  font-weight: 500;
  line-height: 197.222%;
  margin-top: -3px;
  margin-bottom: 0;
}
.faq-page__content__action__text a {
  color: var(--laboix-white, #fff);
  font-size: 18px;
  font-weight: 600;
  line-height: 197.222%;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.faq-page__content__action__text a:hover {
  background-size: 100% 1px;
}

.faq-two {
  position: relative;
  z-index: 1;
  padding-top: 120px;
}
@media (max-width: 991px) {
  .faq-two {
    padding-top: 100px;
  }
}
@media (max-width: 767px) {
  .faq-two {
    padding-top: 80px;
  }
}
.faq-two__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: -1;
}
.faq-two__bg::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.9);
}
.faq-two__inner {
  background: var(--laboix-white, #fff);
  padding: 68px 60px;
  margin-left: -60px;
  margin-right: -60px;
}
.faq-two__inner .faq-page__accordion {
  padding-bottom: 0;
  margin-bottom: 0;
}
@media (max-width: 1199px) {
  .faq-two__inner {
    padding: 40px;
    margin-left: 0px;
    margin-right: -30px;
  }
}
@media (max-width: 991px) {
  .faq-two__inner {
    margin-right: 0px;
  }
}
.faq-two__image {
  position: absolute;
  top: 0;
  right: 0;
  width: 47.6%;
  height: 100%;
}
@media (max-width: 991px) {
  .faq-two__image {
    margin-top: 30px;
    position: relative;
    width: 100%;
  }
  .faq-two__image img {
    object-fit: cover;
    width: 100%;
  }
}
.faq-two__funfact {
  position: relative;
  z-index: 1;
  margin-left: 30px;
  background: var(--laboix-secondary, #0EBD66);
  display: inline-flex;
  flex-direction: column;
  padding: 50px 60px;
}
@media (max-width: 1199px) {
  .faq-two__funfact {
    padding: 40px;
    margin-left: 0px;
  }
}
@media (max-width: 1199px) {
  .faq-two__funfact {
    position: absolute;
    bottom: 0;
  }
}
.faq-two__funfact__count {
  color: var(--laboix-white, #fff);
  font-size: 70px;
  font-weight: 700;
  line-height: 150.217%;
  letter-spacing: -1.4px;
  margin-bottom: -10px;
  padding-bottom: 0;
  display: flex;
  gap: 0;
  align-items: center;
}
@media (max-width: 575px) {
  .faq-two__funfact__count {
    font-size: 50px;
  }
}
.faq-two__funfact__text {
  color: var(--laboix-white, #fff);
  font-size: 40px;
  font-weight: 500;
  line-height: 47px;
  letter-spacing: -0.8px;
  margin-bottom: -10px;
  padding-bottom: 0;
}
@media (max-width: 575px) {
  .faq-two__funfact__text {
    font-size: 30px;
  }
}

.faq-page__accordion {
  margin-bottom: 100px;
}
.faq-page__accordion .accrodion {
  border: 1px solid var(--laboix-border-color, #DBDBDB);
}
.faq-page__accordion .accrodion + .accrodion {
  margin-top: 20px;
}
.faq-page__accordion .accrodion-title {
  padding: 25px 30px 18px 30px;
  padding-right: 70px;
  cursor: pointer;
}
.faq-page__accordion .accrodion-title__text {
  position: relative;
  color: var(--laboix-base, #021936);
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
}
.faq-page__accordion .accrodion-title__icon {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 50%;
  right: -35px;
  border-radius: 50%;
  background: var(--laboix-border-color2, #F3F3F3);
  transform: translateY(-50%);
}
.faq-page__accordion .accrodion-title__icon::after, .faq-page__accordion .accrodion-title__icon::before {
  width: 2.555555px;
  height: 12px;
  position: absolute;
  background-color: var(--laboix-base, #021936);
  top: 50%;
  left: 50%;
  content: "";
  transform: translate(-50%, -50%);
  transition: all 500ms ease;
}
.faq-page__accordion .accrodion-title__icon::after {
  width: 12px;
  height: 2px;
}
.faq-page__accordion .active .accrodion-title__icon {
  background-color: var(--laboix-secondary, #0EBD66);
}
.faq-page__accordion .active .accrodion-title__icon::after, .faq-page__accordion .active .accrodion-title__icon::before {
  background-color: var(--laboix-white, #fff);
  opacity: 0;
}
.faq-page__accordion .active .accrodion-title__icon::after {
  opacity: 1;
}
.faq-page__accordion .accrodion-content .inner {
  padding: 3px 54px 22px 30px;
  margin-top: -3px;
}
.faq-page__accordion .accrodion-content p {
  color: var(--laboix-text, #6A6A6A);
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
  max-width: 960px;
  width: 100%;
}

/*--------------------------------------------------------------
# Package
--------------------------------------------------------------*/
@media (max-width: 991px) {
  .package-card {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}
.package-card__head {
  position: relative;
  z-index: 1;
}
.package-card__head__top {
  margin-top: -46.88px;
  color: var(--laboix-white, #fff);
  font-size: 16px;
  font-weight: 500;
  line-height: 293.75%;
  letter-spacing: -0.32px;
  background: var(--laboix-secondary, #0EBD66);
  margin-bottom: 0;
  padding-bottom: 0;
  text-align: center;
  max-width: 230px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 60px;
}
@media (max-width: 767px) {
  .package-card__head__top {
    margin-top: 0px;
  }
}
.package-card__head__box {
  position: relative;
  z-index: 1;
}
.package-card__head__box::after {
  content: "";
  width: 100%;
  background: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.7);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.package-card__head__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  background-position: center center;
  z-index: -1;
  filter: grayscale(100%);
}
.package-card__head__title {
  padding: 32.5px 0px;
  color: var(--laboix-white, #fff);
  font-size: 30px;
  font-weight: 600;
  line-height: 156.667%;
  letter-spacing: -0.9px;
  text-align: center;
  margin-bottom: 0;
}
.package-card__head__price {
  margin-top: -0.5px;
  padding: 15px 0px;
  color: var(--laboix-white, #fff);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
  display: flex;
  justify-content: center;
  background: var(--laboix-base, #021936);
  align-items: center;
  clip-path: polygon(0 0, 100% 0%, 84% 100%, 0% 100%);
  margin-bottom: 0;
  transition: all 0.5s ease-in-out;
}
.package-card__head__month {
  color: var(--laboix-border-color, #DBDBDB);
  font-size: 20px;
  font-weight: 400;
  line-height: 200%;
  display: inline-block;
  letter-spacing: -0.4px;
  padding-left: 4.88px;
  transition: all 0.5s ease-in-out;
}
.package-card__head__content {
  position: relative;
  z-index: 1;
}
.package-card__head__content::after {
  position: absolute;
  content: "";
  bottom: -4px;
  right: 0;
  width: 45.5px;
  height: 64.5px;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  background: var(--laboix-base, #021936);
  z-index: 1;
}
.package-card__content {
  background: var(--laboix-white, #fff);
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
  padding: 45px 20px 50px;
  text-align: center;
}
.package-card__content__list {
  margin-bottom: 43px;
}
.package-card__content__list__item {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: 162.5%;
}
.package-card__content__list__item i {
  font-size: 16px;
  color: var(--laboix-secondary, #0EBD66);
}
.package-card__content__list__item + .package-card__content__list__item {
  margin-top: 19px;
}
.package-card__content__link__btn {
  background: var(--laboix-border-color2, #F3F3F3);
  color: var(--laboix-base, #021936);
  font-size: 16px;
  font-weight: 600;
  line-height: 112.5%;
  text-shadow: 0 0 0 1px currentColor;
}
.package-card__content__link__btn:hover {
  color: var(--laboix-white, #fff);
}
.package-card:hover .package-card__head__price {
  background: var(--laboix-secondary, #0EBD66);
}
.package-card:hover .package-card__head__month {
  color: var(--laboix-white, #fff);
}

.price-page__inner__btn {
  background-color: var(--laboix-border-color2, #F3F3F3);
  color: var(--laboix-base, #021936);
}
.price-page__inner__btn.active-btn, .price-page__inner__btn:hover {
  background: var(--laboix-secondary, #0EBD66);
  color: var(--laboix-white, #fff);
}
.price-page__inner__btn.active-btn::before, .price-page__inner__btn:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--laboix-secondary, #0EBD66);
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  right: 0;
  top: 0;
  z-index: -3;
}

.price-page__inner__filter {
  margin-top: -5px;
  margin-bottom: 98px;
  display: flex;
  gap: 10px;
  justify-content: center;
}
@media (max-width: 767px) {
  .price-page__inner__filter {
    margin-bottom: 40px;
  }
}

.package-page {
  padding: 120px 0;
  background: var(--laboix-white, #fff);
}
.package-page--one .sec-title {
  padding-bottom: 100px;
}
@media (max-width: 767px) {
  .package-page--one .sec-title {
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .package-page {
    padding: 80px 0;
  }
}
.package-page__carousel .owl-nav {
  display: none !important;
}

/*--------------------------------------------------------------
# Offer
--------------------------------------------------------------*/
.offer-one {
  padding-bottom: 120px;
}
@media (max-width: 767px) {
  .offer-one {
    padding-bottom: 80px;
  }
}
.offer-one--home {
  padding: 100px 0;
}
@media (max-width: 767px) {
  .offer-one--home {
    padding: 80px 0;
  }
}
.offer-one .container-fluid {
  max-width: 1604px;
}
.offer-one__card {
  padding: 20px;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
.offer-one__card::before {
  background: linear-gradient(90deg, rgba(var(--laboix-white-rgb, 255, 255, 255), 0.13) 0px, rgba(var(--laboix-white-rgb, 255, 255, 255), 0.13) 77%, rgba(var(--laboix-white-rgb, 255, 255, 255), 0.5) 92%, rgba(var(--laboix-white-rgb, 255, 255, 255), 0));
  content: "";
  height: 200%;
  left: -210%;
  opacity: 0;
  position: absolute;
  top: -50%;
  transition: all 0.7s ease 0s;
  width: 200%;
}
.offer-one__card:hover::before {
  left: -30%;
  opacity: 1;
  top: -20%;
  transition-duration: 0.7s, 0.7s, 0.15s;
  transition-property: left, top, opacity;
  transition-timing-function: linear;
}
.offer-one__card__inner {
  position: relative;
  border: 1px solid var(--laboix-white, #fff);
  padding: 40px;
}
@media (min-width: 992px) {
  .offer-one__card__inner {
    padding: 50px;
  }
}
.offer-one__card__shape {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
@media (min-width: 992px) {
  .offer-one__card__shape {
    display: block;
  }
}
.offer-one__card__value {
  font-family: var(--laboix-special-font, "Urbanist", sans-serif);
  font-weight: 400;
  font-size: 30px;
  color: var(--laboix-base, #021936);
  line-height: 1;
  margin: 0;
}
@media (min-width: 992px) {
  .offer-one__card__value {
    font-size: 40px;
  }
}
.offer-one__card__title {
  position: relative;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  font-size: 30px;
  line-height: 1.2em;
  margin-bottom: 17px;
}
@media (min-width: 992px) {
  .offer-one__card__title {
    font-size: 40px;
  }
}

/*--------------------------------------------------------------
# Membership
--------------------------------------------------------------*/
.membership-one {
  padding: 120px 0;
  padding-top: 100px;
}
@media (max-width: 767px) {
  .membership-one {
    padding: 80px 0;
    padding-top: 60px;
  }
}
.membership-one .sec-title {
  text-align: center;
  padding-bottom: 25px;
}
.membership-one__tab__list {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap;
}
.membership-one__tab__list li {
  cursor: pointer;
}
.membership-one__tab__list li span {
  display: block;
  font-size: 10px;
  background-color: var(--laboix-gray, #f9f6f1);
  transition: all 500ms ease;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: var(--laboix-letter-space, 0.1em);
  padding: 15px 20px;
  line-height: 1.2em;
  color: var(--laboix-text, #6A6A6A);
}
.membership-one__tab__list li.active-btn span, .membership-one__tab__list li:hover span {
  background-color: var(--laboix-base, #021936);
  color: var(--laboix-white, #fff);
}
.membership-one__card {
  background-repeat: no-repeat;
  background-position: top right;
  border: 1px solid var(--laboix-border-color, #DBDBDB);
  padding: 50px;
  background-color: var(--laboix-white, #fff);
  transition: all 500ms ease;
}
.membership-one__card:hover {
  border-color: var(--laboix-white, #fff);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}
.membership-one__card__price {
  font-family: var(--laboix-font, "Lexend", sans-serif);
  color: var(--laboix-black, #000);
  line-height: 1;
  font-size: 40px;
  line-height: 1em;
  font-weight: 500;
}
.membership-one__card__tagline {
  margin: 0;
  font-size: 16px;
  font-family: var(--laboix-font, "Lexend", sans-serif);
  line-height: 2.5em;
  color: var(--laboix-text, #6A6A6A);
  border-bottom: 1px solid var(--laboix-border-color, #DBDBDB);
  padding-bottom: 16px;
  margin-bottom: 33px;
}
.membership-one__card__text {
  margin: 0;
  font-weight: 600;
  color: var(--laboix-black, #000);
  font-size: 16px;
  line-height: 2.5em;
  margin-top: 13px;
}
.membership-one__card__list {
  margin-bottom: 26px;
}
.membership-one__card__list li {
  position: relative;
  font-size: 16px;
  line-height: 2.5em;
  color: var(--laboix-text, #6A6A6A);
  display: flex;
  justify-content: center;
  align-items: center;
}
.membership-one__card__list li > i {
  font-size: 14px;
  color: var(--laboix-base, #021936);
  margin-right: 10px;
  position: relative;
  top: 1px;
}

.membership-two {
  padding: 120px 0;
  padding-top: 100px;
}
@media (max-width: 767px) {
  .membership-two {
    padding: 80px 0;
    padding-top: 60px;
  }
}
.membership-two--padding {
  padding: 120px 0;
}
@media (max-width: 767px) {
  .membership-two--padding {
    padding: 80px 0;
  }
}
.membership-two .sec-title {
  text-align: center;
}
.membership-two-card {
  background-repeat: no-repeat;
  background-position: top right;
  border: 1px solid var(--laboix-border-color, #DBDBDB);
  padding: 36px 40px;
  transition: all 500ms ease;
  background-color: var(--laboix-white, #fff);
}
.membership-two-card:hover {
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}
@media (min-width: 992px) {
  .membership-two-card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
.membership-two-card__icon i,
.membership-two-card__icon span {
  color: var(--laboix-base, #021936);
  font-size: 60px;
}
@media (min-width: 992px) {
  .membership-two-card__icon {
    margin-right: 30px;
  }
}
.membership-two-card__title {
  margin: 0;
  text-transform: uppercase;
  color: var(--laboix-black, #000);
  text-transform: uppercase;
  font-size: 20px;
  line-height: 1.5em;
  font-weight: bold;
}
.membership-two-card__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.membership-two-card__title a:hover {
  background-size: 100% 1px;
}
.membership-two-card__text {
  font-size: 15px;
  line-height: 2em;
  margin: 0;
}
.membership-two-card__price {
  margin: 0;
  color: var(--laboix-base, #021936);
  font-size: 16px;
  line-height: 1.6666666667em;
  margin-top: 10px;
}
@media (min-width: 768px) {
  .membership-two-card__price {
    font-size: 18px;
  }
}
@media (min-width: 992px) {
  .membership-two-card__price {
    margin-left: 90px;
  }
}
@media (min-width: 1200px) {
  .membership-two-card__price {
    margin-top: 0;
    margin-left: auto;
  }
}

/*--------------------------------------------------------------
# Gift Card
--------------------------------------------------------------*/
.gift-page {
  padding: 120px 0;
  padding-top: 100px;
}
@media (max-width: 767px) {
  .gift-page {
    padding: 80px 0;
    padding-top: 60px;
  }
}
@media (min-width: 992px) {
  .gift-page__carousel .owl-nav {
    display: none;
  }
}

.gift-card-one {
  background-repeat: no-repeat;
  background-position: left top;
  border: 1px solid var(--laboix-border-color, #DBDBDB);
  text-align: center;
  padding: 30px;
  position: relative;
  transition: all 500ms ease;
}
.gift-card-one:hover {
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}
.gift-card-one__inner {
  padding: 45px;
  border: 6px solid var(--laboix-black, #000);
  transition: all 500ms ease;
}
.gift-card-one:hover .gift-card-one__inner {
  border-color: var(--laboix-base, #021936);
}
.gift-card-one__flower {
  position: absolute;
  top: 0;
  right: 0;
  width: auto !important;
  animation: flowerRotate 2s linear 0s infinite;
}
.gift-card-one__title {
  margin: 0;
  text-transform: uppercase;
  color: var(--laboix-black, #000);
  font-weight: bold;
  font-size: 25px;
  line-height: 1em;
  margin-top: -5px;
}
@media (min-width: 768px) {
  .gift-card-one__title {
    font-size: 30px;
  }
}
.gift-card-one__title a {
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
  color: inherit;
}
.gift-card-one__title a:hover {
  background-size: 100% 1px;
}
.gift-card-one__title a:hover {
  color: var(--laboix-base, #021936);
}
.gift-card-one__price {
  margin: 0;
  font-size: 20px;
  color: var(--laboix-base, #021936);
  line-height: 1.5em;
}
.gift-card-one__code {
  margin: 0;
  font-size: 16px;
  color: var(--laboix-black, #000);
  line-height: 22px;
}
.gift-card-one__shape {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto !important;
  margin-top: 25px;
  margin-bottom: 25px;
}
.gift-card-one__text {
  margin: 0;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: var(--laboix-letter-space-xl, 0.2em);
  line-height: 22px;
  margin-bottom: 23px;
}
.gift-card-one__link {
  font-size: 10px;
  padding: 11px 29.5px;
}

/*--------------------------------------------------------------
# Animations
--------------------------------------------------------------*/
@keyframes bubbleMover {
  0% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
  }
  30% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
  50% {
    -webkit-transform: translateY(50px) translateX(100px) rotate(45deg);
    transform: translateY(50px) translateX(100px) rotate(45deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  80% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }
  100% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}
@keyframes shapeMover {
  0%, 100% {
    transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
  }
  50% {
    transform: perspective(400px) rotate(-45deg) translateZ(20px) translateY(20px) translateX(20px);
  }
}
@keyframes banner3Shake {
  0% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }
  30% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  60% {
    -webkit-transform: rotate3d(1, 0, 0, 0deg);
    transform: rotate3d(1, 0, 0, 0deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }
}
@keyframes squareMover {
  0%, 100% {
    -webkit-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0);
  }
  20%, 60% {
    -webkit-transform: translate(20px, 40px) rotate(180deg);
    transform: translate(20px, 40px) rotate(180deg);
  }
  30%, 80% {
    -webkit-transform: translate(40px, 60px) rotate(0deg);
    transform: translate(40px, 60px) rotate(0deg);
  }
}
@keyframes treeMove {
  0%, 100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform: rotate(0deg) translateX(0);
  }
  25%, 75% {
    -webkit-transform: rotate(5deg) translateX(15px);
    transform: rotate(5deg) translateX(15px);
  }
  50% {
    -webkit-transform: rotate(10deg) translateX(30px);
    transform: rotate(10deg) translateX(30px);
  }
}
@keyframes leafMove {
  0%, 100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform: rotate(0deg) translateX(0);
  }
  25%, 75% {
    transform: rotate(-2deg) translateX(5px);
  }
  50% {
    transform: rotate(-4deg) translateX(10px);
  }
}
@keyframes messageMove {
  0%, 100% {
    transform: translateX(0);
  }
  25%, 75% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(10px);
  }
}
@keyframes textRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes flowerRotate {
  0%, 100% {
    transform: rotate(0deg);
  }
  25%, 75% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(10deg);
  }
}
@keyframes textRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes scale2 {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(0.9);
  }
  60% {
    transform: scale(1);
  }
  90% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(60deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes left-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes iconleft {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes heroleft {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(15px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes bgSlide {
  0% {
    background-position: 0 0;
  }
  20% {
    background-position: -100px 0;
  }
  40% {
    background-position: -200px 0;
  }
  60% {
    background-position: -150px 0;
  }
  80% {
    background-position: -100px 0;
  }
  100% {
    background-position: 0px 0;
  }
}
@keyframes topAni {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes rotatey {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
@keyframes aniWidth {
  0% {
    height: 0%;
    overflow: hidden;
  }
  25% {
    height: 30%;
    overflow: hidden;
  }
  50% {
    height: 70%;
    overflow: hidden;
  }
  75% {
    height: 100%;
    overflow: hidden;
  }
  100% {
    height: 0%;
    overflow: hidden;
  }
}
@keyframes borderScale {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
/*--------------------------------------------------------------
# Mobile Nav
--------------------------------------------------------------*/
.mobile-nav__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  transform: translateX(-100%);
  transform-origin: left center;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms;
  visibility: hidden;
  position: fixed;
}
.mobile-nav__wrapper .container {
  padding-left: 0;
  padding-right: 0;
}
.mobile-nav__wrapper .home-showcase .row [class*=col-] {
  flex: 0 0 100%;
}
.mobile-nav__wrapper .home-showcase {
  margin-bottom: -1px;
  margin-top: 0;
  border-bottom: 1px solid RGBA(var(--laboix-white-rgb, 255, 255, 255), 0.1);
}
.mobile-nav__wrapper .home-showcase__inner {
  padding: 15px 0px;
  background-color: transparent;
  box-shadow: none;
}
.mobile-nav__wrapper .home-showcase__title {
  color: var(--laboix-white, #fff);
}

.mobile-nav__wrapper.expanded {
  opacity: 1;
  transform: translateX(0%);
  visibility: visible;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms;
}
.mobile-nav__wrapper.expanded .mobile-nav__content {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms;
}

.mobile-nav__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--laboix-black, #000);
  opacity: 0.3;
  cursor: url(../media/close.webp), auto;
}

.mobile-nav__content {
  width: 300px;
  background-color: var(--laboix-black2, #011126);
  z-index: 10;
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-100%);
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms;
}
.mobile-nav__content .main-menu__nav {
  display: block;
  padding: 0;
}

.mobile-nav__content .logo-box {
  margin-bottom: 50px;
  display: flex;
}

.mobile-nav__close {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 18px;
  color: var(--laboix-white, #fff);
  cursor: pointer;
}
.mobile-nav__close:hover {
  color: var(--laboix-secondary, #0EBD66);
}

.mobile-nav__content .main-menu__list,
.mobile-nav__content .main-menu__list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mobile-nav__content .main-menu__list ul {
  display: none;
  border-top: 1px solid RGBA(var(--laboix-white-rgb, 255, 255, 255), 0.1);
}

.mobile-nav__content .main-menu__list ul li > a {
  padding-left: 1em;
}

.mobile-nav__content .main-menu__list li:not(:last-child) {
  border-bottom: 1px solid RGBA(var(--laboix-white-rgb, 255, 255, 255), 0.1);
}

.mobile-nav__content .main-menu__list li > a {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  color: var(--laboix-white, #fff);
  font-weight: 500;
  height: 46px;
  align-items: center;
  transition: 500ms;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  text-shadow: 0 0 0 0.2px currentColor;
}

.mobile-nav__content .main-menu__list li a.expanded {
  color: var(--laboix-secondary, #0EBD66);
}

.mobile-nav__content .main-menu__list li a button {
  width: 30px;
  height: 30px;
  background-color: var(--laboix-secondary, #0EBD66);
  border: none;
  outline: none;
  color: var(--laboix-white, #fff);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: rotate(-90deg);
  transition: transform 500ms ease;
}

.mobile-nav__content .main-menu__list li a button.expanded {
  transform: rotate(0deg);
  background-color: var(--laboix-white, #fff);
  color: var(--laboix-secondary, #0EBD66);
}

.mobile-nav__social {
  display: flex;
  align-items: center;
}
.mobile-nav__social a {
  font-size: 16px;
  color: var(--laboix-white, #fff);
  transition: 500ms;
}
.mobile-nav__social a + a {
  margin-left: 20px;
}
.mobile-nav__social a:hover {
  color: var(--laboix-secondary, #0EBD66);
}

.mobile-nav__contact {
  margin-bottom: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
.mobile-nav__contact li {
  color: var(--laboix-white, #fff);
  font-size: 14px;
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;
}
.mobile-nav__contact li + li {
  margin-top: 15px;
}
.mobile-nav__contact li a {
  color: inherit;
  transition: 500ms;
}
.mobile-nav__contact li a:hover {
  color: var(--laboix-secondary, #0EBD66);
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.mobile-nav__contact li a:hover:hover {
  background-size: 100% 1px;
}
.mobile-nav__contact li > i {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: var(--laboix-secondary, #0EBD66);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  margin-right: 10px;
  color: var(--laboix-white, #fff);
}

.mobile-nav__container .main-menu__logo,
.mobile-nav__container .main-menu__right {
  display: none;
}

/*--------------------------------------------------------------
# Search Popup
--------------------------------------------------------------*/
.search-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
  -webkit-transition: all 1s ease;
  -khtml-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.search-popup__overlay {
  position: fixed;
  width: 224vw;
  height: 224vw;
  top: calc(90px - 112vw);
  right: calc(50% - 112vw);
  z-index: 3;
  display: block;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: scale(0);
  -khtml-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transition: transform 0.8s ease-in-out;
  -khtml-transition: transform 0.8s ease-in-out;
  -moz-transition: transform 0.8s ease-in-out;
  -ms-transition: transform 0.8s ease-in-out;
  -o-transition: transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;
  transition-delay: 0s;
  transition-delay: 0.3s;
  -webkit-transition-delay: 0.3s;
  background-color: #000;
  opacity: 0.9;
  cursor: url(../media/close.webp), auto;
}
@media (max-width: 767px) {
  .search-popup__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
    width: 100%;
    height: 100%;
    border-radius: 0;
    transform: translateY(-110%);
  }
}
.search-popup__content {
  position: fixed;
  width: 0;
  max-width: 560px;
  padding: 30px 15px;
  left: 50%;
  top: 50%;
  opacity: 0;
  z-index: 3;
  -webkit-transform: translate(-50%, -50%);
  -khtml-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: opacity 0.5s 0s, width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
  -khtml-transition: opacity 0.5s 0s, width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
  -moz-transition: opacity 0.5s 0s, width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
  -ms-transition: opacity 0.5s 0s, width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
  -o-transition: opacity 0.5s 0s, width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
  transition: opacity 0.5s 0s, width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
  transition-delay: 0s, 0.8s, 0s;
  transition-delay: 0s, 0.4s, 0s;
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
}
.search-popup__form {
  position: relative;
}
.search-popup__form input[type=search],
.search-popup__form input[type=text] {
  width: 100%;
  background-color: var(--laboix-white, #fff);
  font-size: 16px;
  color: var(--laboix-text, #6A6A6A);
  border: none;
  outline: none;
  height: 60px;
  padding-left: 30px;
}
.search-popup__form .laboix-btn {
  padding: 0;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  right: -1px;
  border-radius: 0;
}
.search-popup__form .laboix-btn i {
  margin: 0;
}
.search-popup__form .laboix-btn::after {
  background-color: var(--laboix-black, #000);
}
.search-popup.active {
  z-index: 9999;
}
.search-popup.active .search-popup__overlay {
  top: auto;
  bottom: calc(90px - 112vw);
  -webkit-transform: scale(1);
  -khtml-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  opacity: 0.9;
  -webkit-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
  -khtml-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
  -moz-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
  -ms-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
  -o-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
  transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
}
@media (max-width: 767px) {
  .search-popup.active .search-popup__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
    width: 100%;
    height: 100%;
    border-radius: 0;
    transform: translateY(0%);
  }
}
.search-popup.active .search-popup__content {
  width: 100%;
  opacity: 1;
  transition-delay: 0.7s;
  -webkit-transition-delay: 0.7s;
}

/*--------------------------------------------------------------
# Page Header
--------------------------------------------------------------*/
.page-header {
  position: relative;
  padding-top: 175px;
  padding-bottom: 165px;
  z-index: 1;
}
@media (max-width: 991px) {
  .page-header {
    padding-top: 150px;
    padding-bottom: 140px;
  }
}
@media (max-width: 767px) {
  .page-header {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.page-header__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}
.page-header .container {
  position: relative;
  z-index: 10;
  text-align: center;
}
.page-header__title {
  margin: 0;
  color: var(--laboix-white, #fff);
  font-size: 50px;
  font-weight: 700;
  line-height: 94%;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .page-header__title {
    font-size: 40px;
  }
}
.page-header::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.6);
}

.nionx-breadcrumb {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  margin-bottom: 13px;
}
@media (min-width: 768px) {
  .nionx-breadcrumb {
    margin-bottom: 7px;
  }
}
.nionx-breadcrumb li {
  display: flex;
  align-items: center;
}
.nionx-breadcrumb li:not(:last-of-type)::after {
  content: "/";
  position: relative;
  top: -1px;
  margin-left: 12px;
  margin-right: 12px;
  color: var(--laboix-white, #fff);
  font-size: 20px;
  font-weight: 600;
}
.nionx-breadcrumb li span,
.nionx-breadcrumb li a {
  display: inline-flex;
  color: var(--laboix-white, #fff);
  font-size: 20px;
  font-weight: 600;
  line-height: 235%;
}
.nionx-breadcrumb li a:hover {
  color: var(--laboix-secondary, #0EBD66);
}

/*--------------------------------------------------------------
# Google Map
--------------------------------------------------------------*/
.google-map {
  position: relative;
}
.google-map iframe {
  position: relative;
  display: block;
  border: none;
  height: 440px;
  width: 100%;
  mix-blend-mode: luminosity;
}
.google-map__contact {
  background-color: #D9D9D9;
}

.contact-map {
  position: relative;
  padding-bottom: 120px;
}
@media (max-width: 767px) {
  .contact-map {
    padding-bottom: 80px;
  }
}
.contact-map .container-fluid {
  padding-left: 0;
  padding-right: 0;
  max-width: 1920px;
}

/*--------------------------------------------------------------
# Client Carousel
--------------------------------------------------------------*/
.client-carousel {
  padding: 80px 0 80px 0;
}
@media (min-width: 992px) {
  .client-carousel {
    padding: 102px 0 120px 0px;
  }
}
.client-carousel__one__item img {
  opacity: 0.2;
  transition: all 500ms ease;
  max-width: 100%;
  width: auto !important;
}
.client-carousel__one__item:hover img {
  opacity: 0.6;
}
.client-carousel__top {
  position: relative;
  margin-bottom: 50px;
}
@media (max-width: 992px) {
  .client-carousel__top {
    margin-bottom: 40px;
  }
}
.client-carousel__top::after {
  content: "";
  position: absolute;
  left: 0;
  z-index: -1;
  top: 50%;
  width: 100%;
  height: 1px;
  background: var(--laboix-border-color2, #F3F3F3);
}

.client-carousel-one {
  position: relative;
  background: var(--laboix-white, #fff);
  padding: 0 0 60px;
}
.client-carousel-one .client-carousel__one {
  padding: 50px 0 0;
}
.client-carousel-one .owl-theme .owl-nav {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  z-index: 2;
  line-height: 0.8;
}
.client-carousel-one .owl-theme .owl-nav button span {
  width: 23px;
  height: 19px;
  line-height: 19px !important;
  background-color: var(--laboix-secondary, #0EBD66);
  padding: 0;
  transition: all 500ms ease;
  color: var(--laboix-white, #fff);
  text-align: center;
  font-size: 10px;
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.000000 19.000000"><g transform="translate(0.000000,19.000000) scale(0.050000,-0.050000)"> <path d="M56 285 l-53 -94 53 -96 54 -95 120 0 120 0 54 97 55 97 -59 93 -59 93 -116 0 -115 0 -54 -95z"/> </g> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.000000 19.000000"><g transform="translate(0.000000,19.000000) scale(0.050000,-0.050000)"> <path d="M56 285 l-53 -94 53 -96 54 -95 120 0 120 0 54 97 55 97 -59 93 -59 93 -116 0 -115 0 -54 -95z"/> </g> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
}
.client-carousel-one .owl-theme .owl-nav button span:hover {
  background-color: var(--laboix-base, #021936);
  color: var(--laboix-white, #fff);
}
.client-carousel-one .owl-theme .owl-nav button:hover {
  background-color: transparent;
}
.client-carousel-one .owl-theme .owl-nav::before {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  content: "";
  height: 1px;
  background-color: var(--laboix-border-color, #DBDBDB);
  z-index: -1;
}
.client-carousel-one .owl-theme .owl-nav::after {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: -1;
  width: 60px;
  content: "";
  height: 1px;
  background-color: var(--laboix-white, #fff);
}
.client-carousel-one .client-carousel__one__item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 103px;
  transition: all 500ms ease;
}
.client-carousel-one .client-carousel__one__item img {
  transition: all 500ms ease;
  opacity: 0.2;
  max-width: 100%;
  width: auto;
}
.client-carousel-one .client-carousel__one__item:hover img {
  opacity: 0.6;
}

/*--------------------------------------------------------------
# Hero Slider
--------------------------------------------------------------*/
.main-slider-one {
  position: relative;
}
.main-slider-one__carousel {
  position: relative;
  width: 100%;
}
.main-slider-one__carousel .owl-dots {
  display: flex;
  gap: 10px;
  position: absolute;
  bottom: 66px;
  left: 19.45%;
}
.main-slider-one__carousel .owl-dots .owl-dot {
  width: 8px;
  border-radius: 50%;
  background-color: rgba(var(--laboix-white-rgb, 255, 255, 255), 0.2);
  height: 8px;
}
.main-slider-one__carousel .owl-dots .owl-dot.active,
.main-slider-one__carousel .owl-dots .owl-dot:hover {
  background-color: rgba(var(--laboix-secondary-rgb, 14, 189, 102), 1);
}
.main-slider-one__item {
  background-color: var(--laboix-base, #021936);
  position: relative;
  z-index: 3;
  padding-top: 280px;
  padding-bottom: 250px;
}
@media (max-width: 1800px) {
  .main-slider-one__item {
    padding-top: 150px;
    padding-bottom: 200px;
  }
}
@media (max-width: 1199px) {
  .main-slider-one__item {
    padding-top: 130px;
    padding-bottom: 150px;
  }
}
@media (max-width: 991px) {
  .main-slider-one__item {
    padding-top: 120px;
    padding-bottom: 130px;
  }
}
.main-slider-one__bg {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top right;
  transition: transform 1000ms ease, opacity 1000ms ease;
  transform: translatex(50%) scalex(2);
  filter: blur(10px);
  opacity: 0;
}
@media (max-width: 991px) {
  .main-slider-one__bg {
    display: none;
  }
}
.main-slider-one__bg::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 79%;
  content: "";
  z-index: -2;
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 185.000000"> <g transform="translate(0.000000,185.000000) scale(0.050000,-0.050000)"> <path d="M0 1850 l0 -1850 2993 0 2993 0 -37 41 c-21 23 -341 322 -713 664 -372 343 -676 630 -676 637 0 8 148 157 329 331 l329 317 -810 855 -809 855 -1800 0 -1799 0 0 -1850z"/> </g> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 185.000000"> <g transform="translate(0.000000,185.000000) scale(0.050000,-0.050000)"> <path d="M0 1850 l0 -1850 2993 0 2993 0 -37 41 c-21 23 -341 322 -713 664 -372 343 -676 630 -676 637 0 8 148 157 329 331 l329 317 -810 855 -809 855 -1800 0 -1799 0 0 -1850z"/> </g> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  background: linear-gradient(180deg, #0EBD66 0%, rgba(14, 189, 102, 0) 100%);
}
@media (max-width: 1400px) {
  .main-slider-one__bg::before {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 991px) {
  .main-slider-one__bg::before {
    display: none;
  }
}
.main-slider-one__bg::after {
  content: "";
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 193.000000"> <g transform="translate(0.000000,193.000000) scale(0.050000,-0.050000)"> <path d="M0 1930 l0 -1930 2990 0 c1645 0 2990 4 2990 8 0 5 -337 320 -748 700 l-748 691 293 284 c161 156 319 308 350 338 l57 53 -846 893 -845 893 -1746 0 -1747 0 0 -1930z"/> </g> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 193.000000"> <g transform="translate(0.000000,193.000000) scale(0.050000,-0.050000)"> <path d="M0 1930 l0 -1930 2990 0 c1645 0 2990 4 2990 8 0 5 -337 320 -748 700 l-748 691 293 284 c161 156 319 308 350 338 l57 53 -846 893 -845 893 -1746 0 -1747 0 0 -1930z"/> </g> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  position: absolute;
  left: 0;
  top: 0;
  width: 75%;
  height: 101%;
  z-index: -1;
  background-color: var(--laboix-base, #021936);
}
@media (max-width: 1400px) {
  .main-slider-one__bg::after {
    width: 95%;
    height: 100%;
  }
}
@media (max-width: 991px) {
  .main-slider-one__bg::after {
    display: none;
  }
}
.main-slider-one__content {
  position: relative;
  display: inline-block;
  z-index: 3;
  overflow: hidden;
}
.main-slider-one__sub-title {
  color: var(--laboix-secondary, #0EBD66);
  font-family: var(--laboix-font, "Lexend", sans-serif);
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  margin: -7px 0 10px;
  transition: transform 1000ms ease, opacity 1000ms ease;
  transform: translateY(-200px);
}
.main-slider-one__title {
  color: var(--laboix-white, #fff);
  display: inline-block;
  overflow: hidden;
  margin: 0 0 17px;
  opacity: 0;
  font-size: 70px;
  font-weight: 600;
  line-height: 114.286%;
  letter-spacing: -2.1px;
  text-shadow: 0 0 1px currentColor;
  transition: transform 1200ms ease, opacity 1200ms ease;
  transform: translateX(200px);
}
@media (max-width: 1199px) {
  .main-slider-one__title {
    font-size: 60px;
  }
}
@media (max-width: 991px) {
  .main-slider-one__title {
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  .main-slider-one__title {
    font-size: 40px;
  }
}
.main-slider-one__title::after {
  content: "";
  width: 101%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 100%;
  background: currentColor;
  transition: 1s cubic-bezier(0.858, 0.01, 0.068, 0.99);
  z-index: 3;
  transform: translateX(-100%);
  transition-delay: 1s;
}
.main-slider-one__btn {
  position: relative;
  z-index: 5;
  overflow: hidden;
  opacity: 0;
  transform: perspective(400px) rotateY(0deg) translateY(80px);
  transform-origin: bottom;
  transition: all 1500ms ease;
}
.main-slider-one__btn .laboix-btn::before {
  background-color: var(--laboix-white, #fff);
}
.main-slider-one__btn .laboix-btn:hover {
  color: var(--laboix-base, #021936);
}
.main-slider-one__shape {
  position: absolute;
}
.main-slider-one__shape--one {
  bottom: 0%;
  left: 0%;
  transition: all 1500ms ease;
  transform: perspective(400px) rotateY(0deg) translateY(80px);
  transform-origin: left;
  opacity: 0;
}
@media (max-width: 1199px) {
  .main-slider-one__shape--one {
    display: none;
  }
}
.main-slider-one__shape--two {
  top: 0%;
  left: 0%;
  transform: perspective(400px) rotateY(0deg) translateY(80px);
  transform-origin: bottom;
  opacity: 0;
  transition: all 1500ms ease;
}
@keyframes scale3 {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
.main-slider-one__shape--three {
  bottom: 0;
  right: 0;
  transition: all 1500ms ease;
  transform: perspective(400px) rotateY(0deg) translateY(80px);
  transform-origin: right;
  opacity: 0;
}
@media (max-width: 991px) {
  .main-slider-one__shape--three {
    display: none;
  }
}
.main-slider-one__shape--fore {
  top: 0;
  left: 0;
  transition: all 1500ms ease;
  transform: perspective(400px) rotateY(0deg) translateY(80px);
  transform-origin: left;
  opacity: 0;
}
@media (max-width: 1199px) {
  .main-slider-one__shape--fore {
    display: none;
  }
}
.main-slider-one .active .steap {
  animation: dash 0.5s linear;
}
@keyframes dash {
  0% {
    stroke-width: 0;
  }
  100% {
    stroke-width: 200px;
  }
}
.main-slider-one .active .main-slider-one__sub-title {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 1100ms;
}
.main-slider-one .active .main-slider-one__bg {
  opacity: 1;
  transform: translatex(0) scalex(1);
  filter: blur(0);
  transition-delay: 1000ms;
}
.main-slider-one .active .main-slider-one__shape--two {
  transform: perspective(400px) rotateY(0deg) translateY(80px);
  transition-delay: 1700ms;
  animation: scale3 6s ease-in-out infinite;
  opacity: 1;
}
.main-slider-one .active .main-slider-one__shape--one,
.main-slider-one .active .main-slider-one__shape--fore {
  transform: perspective(400px) rotateY(0deg) translateY(0px);
  transition-delay: 1700ms;
  opacity: 1;
}
.main-slider-one .active .main-slider-one__shape--three {
  transform: perspective(400px) rotateY(0deg) translateY(0px);
  transition-delay: 1700ms;
  animation: topAni 4s ease-in-out infinite;
  opacity: 1;
}
.main-slider-one .active .main-slider-one__title {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 1300ms;
}
.main-slider-one .active .main-slider-one__title::after {
  transform: translateX(1%);
  transition-delay: 1500ms;
}
.main-slider-one .active .main-slider-one__btn {
  opacity: 1;
  transform: perspective(400px) rotateY(0deg) translateY(0px);
  transition-delay: 1700ms;
}

.main-slider-two__carousel {
  position: relative;
  width: 100%;
}
.main-slider-two__carousel .owl-dots {
  display: flex;
  gap: 10px;
  position: absolute;
  bottom: 66px;
  left: 19.45%;
}
.main-slider-two__carousel .owl-dots .owl-dot {
  width: 8px;
  border-radius: 50%;
  background-color: rgba(var(--laboix-white-rgb, 255, 255, 255), 0.2);
  height: 8px;
}
.main-slider-two__carousel .owl-dots .owl-dot.active,
.main-slider-two__carousel .owl-dots .owl-dot:hover {
  background-color: rgba(var(--laboix-white-rgb, 255, 255, 255), 1);
}
.main-slider-two__carousel .owl-nav {
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 50%;
  left: 70px;
  gap: 10px;
  position: absolute;
}
@media (max-width: 1400px) {
  .main-slider-two__carousel .owl-nav {
    left: 10px;
  }
}
@media (max-width: 1080px) {
  .main-slider-two__carousel .owl-nav {
    display: none;
  }
}
.main-slider-two__carousel .owl-nav .owl-prev, .main-slider-two__carousel .owl-nav .owl-next {
  background: #324B64 !important;
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="31" height="36" viewBox="0 0 31 36" > <path d="M15.4182 0.485718L30.5141 9.1949V26.6133L15.4182 35.3225L0.322266 26.6133V9.1949L15.4182 0.485718Z"/></svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="31" height="36" viewBox="0 0 31 36" > <path d="M15.4182 0.485718L30.5141 9.1949V26.6133L15.4182 35.3225L0.322266 26.6133V9.1949L15.4182 0.485718Z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 31px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}
.main-slider-two__carousel .owl-nav .owl-prev span, .main-slider-two__carousel .owl-nav .owl-next span {
  color: var(--laboix-white, #fff);
  display: block;
  font-size: 10px;
}
.main-slider-two__carousel .owl-nav .owl-prev:hover, .main-slider-two__carousel .owl-nav .owl-next:hover {
  background: var(--laboix-secondary, #0EBD66) !important;
}
.main-slider-two__carousel .owl-nav .owl-next span {
  transform: rotate(90deg);
}
.main-slider-two__carousel .owl-nav .owl-prev span {
  transform: rotate(90deg);
}
.main-slider-two__item {
  background-color: var(--laboix-base, #021936);
  position: relative;
  z-index: 3;
  padding-top: 110px;
  padding-bottom: 75px;
}
@media (max-width: 991px) {
  .main-slider-two__item {
    padding-top: 150px;
    padding-bottom: 60px;
  }
}
.main-slider-two__item__shape {
  position: absolute;
  bottom: 0;
  right: 0;
  transition: all 1500ms ease;
  opacity: 0;
}
@media (max-width: 991px) {
  .main-slider-two__item__shape {
    display: none;
  }
}
@keyframes scale3 {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}
.main-slider-two__bg {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: transform 1000ms ease, opacity 1000ms ease;
  transform: translatey(50%) scaley(2);
  opacity: 0;
}
.main-slider-two__bg::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background-color: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.9);
}
.main-slider-two__title {
  color: var(--laboix-white, #fff);
  display: inline-block;
  overflow: hidden;
  margin: 0 -20px 27px 0px;
  opacity: 0;
  font-size: 70px;
  font-weight: 600;
  line-height: 114.286%;
  letter-spacing: -2.1px;
  text-shadow: 0 0 1px currentColor;
  transition: transform 1200ms ease, opacity 1200ms ease;
  transform: translateX(200px);
}
@media (max-width: 991px) {
  .main-slider-two__title {
    font-size: 55px;
  }
}
@media (max-width: 767px) {
  .main-slider-two__title {
    font-size: 50px;
  }
}
.main-slider-two__title::after {
  content: "";
  width: 101%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 100%;
  background: currentColor;
  transition: 1s cubic-bezier(0.858, 0.01, 0.068, 0.99);
  z-index: 3;
  transform: translateX(-100%);
  transition-delay: 1s;
}
.main-slider-two__btn {
  margin-top: 30px;
  z-index: 5;
  overflow: hidden;
  opacity: 0;
  transform: perspective(400px) rotateY(0deg) translateY(80px);
  transform-origin: bottom;
  transition: all 1000ms ease;
}
.main-slider-two__btn .laboix-btn::before {
  background-color: var(--laboix-white, #fff);
}
.main-slider-two__btn .laboix-btn:hover {
  color: var(--laboix-base, #021936);
}
.main-slider-two__list {
  margin-top: -7px;
  margin-bottom: 0;
  padding-bottom: 0;
  position: relative;
  z-index: 1;
  display: inline-block;
  opacity: 0;
  overflow: hidden;
  transition: transform 1300ms ease, opacity 1300ms ease;
  transform: translateX(200px);
}
.main-slider-two__list__item {
  color: var(--laboix-white, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 200%;
}
.main-slider-two__list__item i {
  color: var(--laboix-secondary, #0EBD66);
  margin-right: 16px;
}
.main-slider-two__list::after {
  content: "";
  width: 101%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 100%;
  background: var(--laboix-white, #fff);
  transition: 1s cubic-bezier(0.858, 0.01, 0.068, 0.99);
  z-index: 3;
  transform: translateX(-100%);
  transition-delay: 1s;
}
.main-slider-two__image {
  position: relative;
  z-index: 1;
  opacity: 0;
  transition: transform 1300ms ease, opacity 1300ms ease;
  transform: translateX(200%);
}
@media (max-width: 991px) {
  .main-slider-two__image {
    margin-top: 40px;
  }
}
.main-slider-two__image__item {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 347.000000"> <g transform="translate(0.000000,347.000000) scale(0.050000,-0.050000)"> <path d="M1495 6074 l-1494 -864 4 -1738 5 -1737 1480 -857 c814 -471 1494 -856 1510 -856 17 0 696 385 1510 856 l1480 856 5 1738 4 1738 -1490 860 c-820 473 -1497 862 -1505 864 -8 2 -687 -385 -1509 -860z"/> </g> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 347.000000"> <g transform="translate(0.000000,347.000000) scale(0.050000,-0.050000)"> <path d="M1495 6074 l-1494 -864 4 -1738 5 -1737 1480 -857 c814 -471 1494 -856 1510 -856 17 0 696 385 1510 856 l1480 856 5 1738 4 1738 -1490 860 c-820 473 -1497 862 -1505 864 -8 2 -687 -385 -1509 -860z"/> </g> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 590px;
  height: 680px;
  position: relative;
  margin-right: -100px;
  margin-left: 100px;
}
@media (max-width: 1199px) {
  .main-slider-two__image__item {
    width: 100%;
    margin-right: 0px;
    margin-left: 50px;
  }
}
@media (max-width: 991px) {
  .main-slider-two__image__item {
    margin-left: auto;
    margin-right: auto;
  }
}
.main-slider-two__image__item-two {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 347.000000"> <g transform="translate(0.000000,347.000000) scale(0.050000,-0.050000)"> <path d="M1495 6074 l-1494 -864 4 -1738 5 -1737 1480 -857 c814 -471 1494 -856 1510 -856 17 0 696 385 1510 856 l1480 856 5 1738 4 1738 -1490 860 c-820 473 -1497 862 -1505 864 -8 2 -687 -385 -1509 -860z"/> </g> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 347.000000"> <g transform="translate(0.000000,347.000000) scale(0.050000,-0.050000)"> <path d="M1495 6074 l-1494 -864 4 -1738 5 -1737 1480 -857 c814 -471 1494 -856 1510 -856 17 0 696 385 1510 856 l1480 856 5 1738 4 1738 -1490 860 c-820 473 -1497 862 -1505 864 -8 2 -687 -385 -1509 -860z"/> </g> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 218px;
  height: 251px;
  position: absolute;
  bottom: 50px;
  left: -5px;
}
@media (max-width: 575px) {
  .main-slider-two__image__item-two {
    bottom: 100px;
    width: 150px;
    height: 160px;
  }
}
.main-slider-two__image__item-shape {
  position: absolute;
  top: -30px;
  left: 75px;
  z-index: -1;
}
.main-slider-two__image__item-shape img {
  object-fit: cover;
  width: auto !important;
}
@media (max-width: 991px) {
  .main-slider-two__image__item-shape {
    position: absolute;
    top: -10px;
    left: 0px;
    z-index: -1;
  }
  .main-slider-two__image__item-shape img {
    object-fit: cover;
    width: 100% !important;
  }
}
.main-slider-two .active .main-slider-two__bg {
  opacity: 1;
  transform: translatey(0) scaley(1);
  filter: blur(0);
  transition-delay: 1000ms;
}
.main-slider-two .active .main-slider-two__image {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 1300ms;
}
.main-slider-two .active .main-slider-two__title {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 1300ms;
}
.main-slider-two .active .main-slider-two__title::after {
  transform: translateX(1%);
  transition-delay: 1500ms;
}
.main-slider-two .active .main-slider-two__list {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 1300ms;
}
.main-slider-two .active .main-slider-two__list::after {
  transform: translateX(1%);
  transition-delay: 1500ms;
  opacity: 0;
}
.main-slider-two .active .main-slider-two__btn {
  opacity: 1;
  transform: perspective(400px) rotateY(0deg) translateY(0px);
  transition-delay: 1300ms;
}
.main-slider-two .active .main-slider-two__item__shape {
  transform: perspective(400px) rotateY(0deg) translateY(0px);
  transition-delay: 1700ms;
  opacity: 1;
}
.main-slider-two .active .main-slider-two__image__item-shape {
  opacity: 1;
  animation: scale3 4s ease-in-out infinite;
}

.main-slider-three__carousel {
  position: relative;
  width: 100%;
}
.main-slider-three__carousel .owl-dots {
  position: absolute;
  left: 48.5%;
  bottom: 215px;
  display: flex;
  gap: 10px;
  text-align: center;
  justify-content: center;
}
.main-slider-three__carousel .owl-dots .owl-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(var(--laboix-white-rgb, 255, 255, 255), 0.2);
}
.main-slider-three__carousel .owl-dots .owl-dot.active,
.main-slider-three__carousel .owl-dots .owl-dot:hover {
  background-color: rgba(var(--laboix-white-rgb, 255, 255, 255), 1);
}
.main-slider-three__item {
  background-color: var(--laboix-base, #021936);
  position: relative;
  z-index: 1;
  padding-top: 270px;
  padding-bottom: 270px;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .main-slider-three__item {
    padding-top: 120px;
    padding-bottom: 270px;
  }
}
.main-slider-three__bg {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: transform 1000ms ease, opacity 1000ms ease;
  transform: translatex(50%) scalex(2);
  filter: blur(10px);
  opacity: 0;
}
.main-slider-three__bg::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background-color: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.9);
  display: none;
}
@media (max-width: 767px) {
  .main-slider-three__bg::before {
    display: block;
  }
}
.main-slider-three__content {
  text-align: center;
}
.main-slider-three__title {
  margin-top: -13px;
  color: var(--laboix-white, #fff);
  text-align: center;
  font-size: 65px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.3px;
  max-width: 1060px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  opacity: 0;
  transition: transform 1200ms ease, opacity 1200ms ease;
  transform: translateY(200px);
}
@media (max-width: 1199px) {
  .main-slider-three__title {
    font-size: 60px;
  }
}
@media (max-width: 991px) {
  .main-slider-three__title {
    font-size: 55px;
  }
}
@media (max-width: 767px) {
  .main-slider-three__title {
    font-size: 50px;
  }
}
@media (max-width: 575px) {
  .main-slider-three__title {
    font-size: 40px;
  }
}
.main-slider-three__title::after {
  content: "";
  width: 101%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0%;
  background: currentColor;
  transition: 1s cubic-bezier(0.858, 0.01, 0.068, 0.99);
  z-index: 3;
  transform: translateY(500);
  transition-delay: 1s;
}
.main-slider-three__btn {
  overflow: hidden;
  opacity: 0;
  transform: perspective(400px) rotateY(0deg) translateY(80px);
  transform-origin: bottom;
  transition: all 1000ms ease;
}
.main-slider-three__btn .laboix-btn {
  color: var(--laboix-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 112.5%;
  padding: 19px 33.5px;
  text-transform: capitalize;
}
.main-slider-three__btn .laboix-btn::before {
  background: var(--laboix-white, #fff);
}
.main-slider-three__btn .laboix-btn:hover {
  color: var(--laboix-base, #021936);
}
.main-slider-three__shape {
  position: absolute;
  z-index: -1;
  top: -15%;
  left: 120px;
  right: 120px;
  mix-blend-mode: luminosity;
  transition: all 1500ms ease;
  transform: perspective(400px) translateY(-100%);
  transform-origin: bottom;
  opacity: 0;
}
.main-slider-three__shape img {
  object-fit: cover;
  width: 100%;
  opacity: 0.6;
}
.main-slider-three__shape__two {
  position: absolute;
  z-index: 1;
}
.main-slider-three__shape__two--one {
  top: 10%;
  left: 0;
  transition: all 1500ms ease;
  transform: perspective(400px) translateX(-100%);
  transform-origin: left;
  opacity: 0;
}
.main-slider-three__shape__two--two {
  bottom: 0;
  right: 0;
  transition: all 1500ms ease;
  transform: perspective(400px) translateX(100%);
  transform-origin: right;
  opacity: 0;
}
@media (max-width: 767px) {
  .main-slider-three__shape__two {
    display: none;
  }
}
.main-slider-three .active .main-slider-three__bg {
  opacity: 1;
  transform: translatey(0) scaley(1);
  filter: blur(0);
  transition-delay: 1000ms;
}
.main-slider-three .active .main-slider-three__title {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 1300ms;
}
.main-slider-three .active .main-slider-three__title::after {
  transform: translateY(100%);
  transition-delay: 1500ms;
}
.main-slider-three .active .main-slider-three__btn {
  opacity: 1;
  transform: perspective(400px) rotateY(0deg) translateY(0px);
  transition-delay: 1300ms;
}
.main-slider-three .active .main-slider-three__shape {
  transform: perspective(400px) rotateY(0deg) translateY(0px);
  transition-delay: 1700ms;
  opacity: 1;
}
.main-slider-three .active .main-slider-three__shape__two--one {
  transform: perspective(400px) translateX(0px);
  transition-delay: 1700ms;
  opacity: 1;
}
.main-slider-three .active .main-slider-three__shape__two--two {
  transform: perspective(400px) translateX(0px);
  transition-delay: 1700ms;
  opacity: 1;
}

/*--------------------------------------------------------------
# Feature Section
--------------------------------------------------------------*/
.case-studies {
  padding-bottom: 150px;
  background: var(--laboix-white, #fff);
}
@media (max-width: 1080px) {
  .case-studies {
    padding-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .case-studies {
    padding-bottom: 80px;
  }
}
.case-studies--home {
  padding-top: 130px;
}
@media (max-width: 1080px) {
  .case-studies--home {
    padding-top: 110px;
  }
}
@media (max-width: 767px) {
  .case-studies--home {
    padding-top: 90px;
  }
}
.case-studies .container {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}
.case-studies__list {
  display: flex;
  gap: 30px;
  margin-bottom: 0;
  padding-bottom: 0;
}
@media (max-width: 1080px) {
  .case-studies__list {
    flex-wrap: wrap;
  }
}
@media (max-width: 1080px) {
  .case-studies__list__item {
    flex: 0 0 50%;
    max-width: 48%;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .case-studies__list__item {
    flex: 0 0 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    margin-bottom: 30px;
  }
}
.case-studies__list__item__content {
  position: relative;
  z-index: 1;
}
.case-studies__list__item__thumb {
  max-height: 500px;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.case-studies__list__item__thumb img {
  object-fit: cover;
  width: 100%;
}
.case-studies__list__item__thumb::after {
  transition: all 0.3s ease-in-out;
  position: absolute;
  content: "";
  width: 0%;
  height: 100%;
  background-color: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.9);
  top: 0;
  left: 0;
  bottom: 0;
}
.case-studies__list__item__hover {
  position: relative;
  text-align: center;
  margin-top: -130px;
  transition: all 0.4s ease-in-out;
  z-index: 1;
  padding-bottom: 15px;
  opacity: 0;
  visibility: hidden;
}
.case-studies__list__item__hover::after {
  position: absolute;
  content: "";
  width: 170px;
  height: 1px;
  background-color: var(--laboix-secondary, #0EBD66);
  bottom: 0%;
  left: 29%;
  transform: scaleX(0);
  transition: all 0.7s ease;
}
@media (max-width: 1299px) and (min-width: 991px) {
  .case-studies__list__item__hover::after {
    width: 120px;
    left: 29%;
  }
}
.case-studies__list__item__title {
  color: var(--laboix-white, #fff);
  font-size: 30px;
  font-weight: 700;
  line-height: 156.667%;
  letter-spacing: -0.6px;
  padding-bottom: 0;
  margin-bottom: -10px;
}
@media (max-width: 1299px) and (min-width: 991px) {
  .case-studies__list__item__title {
    font-size: 25px;
  }
}
.case-studies__list__item__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.case-studies__list__item__title a:hover {
  background-size: 100% 1px;
}
.case-studies__list__item__text {
  color: var(--laboix-secondary, #0EBD66);
  font-size: 20px;
  font-weight: 500;
  line-height: 235%;
  letter-spacing: -0.4px;
  margin-bottom: 0;
  padding-bottom: 0;
}
@media (max-width: 1299px) and (min-width: 991px) {
  .case-studies__list__item__text {
    font-size: 18px;
  }
}
.case-studies__list__item:nth-child(odd) {
  margin-top: 50px;
}
@media (max-width: 1080px) {
  .case-studies__list__item:nth-child(odd) {
    margin-top: 0px;
  }
}
.case-studies__list__item:hover .case-studies__list__item__thumb::after {
  width: 100%;
  left: auto;
  right: 0;
}
.case-studies__list__item:hover .case-studies__list__item__hover {
  visibility: visible;
  opacity: 1;
}
.case-studies__list__item:hover .case-studies__list__item__hover::after {
  transform: scaleX(1);
}

.case-studies-two .container {
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
}
.case-studies-two__item {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.case-studies-two__item:hover .case-studies-two__thumb::after {
  visibility: visible;
  opacity: 1;
}
.case-studies-two__item:hover .case-studies-two__hover {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .case-studies-two__item {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}
.case-studies-two__thumb {
  width: 100%;
  position: relative;
}
.case-studies-two__thumb img {
  object-fit: cover;
  width: 100%;
}
.case-studies-two__thumb::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.7);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}
.case-studies-two__hover {
  position: absolute;
  bottom: 0;
  z-index: 1;
  background: var(--laboix-secondary, #0EBD66);
  clip-path: polygon(50% 0%, 100% 35%, 100% 100%, 0 100%, 0 35%);
  max-width: 100%;
  width: 460px;
  height: 265px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 55px;
  margin-right: 55px;
  margin-bottom: -100%;
  transition: all 0.5s ease-in-out;
}
@media (max-width: 1600px) {
  .case-studies-two__hover {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 1199px) and (min-width: 991px) {
  .case-studies-two__hover {
    height: 200px;
  }
}
@media (max-width: 575px) {
  .case-studies-two__hover {
    height: 200px;
  }
}
.case-studies-two__icon {
  font-size: 70px;
  color: var(--laboix-white, #fff);
  text-align: center;
  opacity: 0.7;
  margin-bottom: 15px;
}
.case-studies-two__title {
  color: var(--laboix-white, #fff);
  font-size: 30px;
  font-weight: 700;
  line-height: 156.667%;
  letter-spacing: -0.6px;
  margin-bottom: 0;
  padding-bottom: -7px;
}
.case-studies-two__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.case-studies-two__title a:hover {
  background-size: 100% 1px;
}
.case-studies-two__text {
  color: var(--laboix-white, #fff);
  font-size: 20px;
  font-weight: 500;
  line-height: 235%;
  letter-spacing: -0.4px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.our-work {
  padding-bottom: 120px;
  background: var(--laboix-white, #fff);
}
@media (max-width: 991px) {
  .our-work {
    padding-bottom: 100px;
  }
}
@media (max-width: 80px) {
  .our-work {
    padding-bottom: 80px;
  }
}
.our-work__item {
  position: relative;
  max-width: 400px;
  z-index: 1;
}
.our-work__item::after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  right: -34%;
  top: 13%;
  border-top: 2px dashed var(--laboix-border-color, #DBDBDB);
  z-index: -1;
}
@media (max-width: 991px) {
  .our-work__item::after {
    display: none;
  }
}
.our-work__item--two::after {
  display: none;
}
.our-work__item__step {
  transition: all 0.4s ease-in-out;
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 66"><path d="M28.1458 0.97168L56.2917 17.2217V49.7217L28.1458 65.9717L0 49.7217V17.2217L28.1458 0.97168Z"/></svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 66"><path d="M28.1458 0.97168L56.2917 17.2217V49.7217L28.1458 65.9717L0 49.7217V17.2217L28.1458 0.97168Z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 57px;
  margin-left: 60px;
  margin-bottom: 24px;
  height: 66px;
  background-color: var(--laboix-border-color2, #F3F3F3);
  counter-increment: courseNumber;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--laboix-base, #021936);
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}
.our-work__item__step::before {
  content: counters(courseNumber, ".", decimal-leading-zero);
}
@media (max-width: 575px) {
  .our-work__item__step {
    margin-bottom: 15px;
  }
}
.our-work__item__content {
  padding-left: 60px;
  position: relative;
  z-index: 1;
}
@media (max-width: 1199px) and (min-width: 991px) {
  .our-work__item__content {
    padding-left: 30px;
  }
}
.our-work__item__content::after {
  content: "";
  width: 19px;
  height: 141px;
  background: linear-gradient(90deg, #021936 -100.25%, rgba(255, 255, 255, 0) 30.29%);
  position: absolute;
  top: 10%;
  left: 0;
}
.our-work__item__title {
  color: var(--laboix-base, #021936);
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
  margin-bottom: 17px;
}
.our-work__item__title a {
  color: inherit;
}
.our-work__item__title a:hover {
  color: var(--laboix-secondary, #0EBD66);
}
.our-work__item__text {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
  padding-bottom: 0;
  margin-bottom: 0;
}
.our-work__item__shape {
  position: absolute;
  left: 0;
  top: 0%;
}
.our-work__item:hover .our-work__item__step {
  background-color: var(--laboix-secondary, #0EBD66);
  color: var(--laboix-white, #fff);
}
.our-work__item:hover .our-work__item__shape {
  animation: topAni 2s ease-in infinite;
}

.our-work-two {
  padding: 120px 0px;
}
@media (max-width: 991px) {
  .our-work-two {
    padding: 100px 0px;
  }
}
@media (max-width: 767px) {
  .our-work-two {
    padding: 80px 0px;
  }
}
.our-work-two__middle {
  position: relative;
  z-index: 1;
}
.our-work-two__image {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 466.000000"> <g transform="translate(0.000000,466.000000) scale(0.050000,-0.050000)"> <path d="M1495 8437 l-1495 -863 5 -2919 5 -2919 690 -399 c380 -220 875 -506 1100 -636 226 -130 583 -336 795 -459 211 -122 394 -222 406 -222 12 0 262 139 555 309 294 170 962 556 1484 857 l950 549 5 2920 5 2920 -1477 852 c-812 469 -1490 857 -1505 863 -15 5 -701 -378 -1523 -853z"/> </g> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 466.000000"> <g transform="translate(0.000000,466.000000) scale(0.050000,-0.050000)"> <path d="M1495 8437 l-1495 -863 5 -2919 5 -2919 690 -399 c380 -220 875 -506 1100 -636 226 -130 583 -336 795 -459 211 -122 394 -222 406 -222 12 0 262 139 555 309 294 170 962 556 1484 857 l950 549 5 2920 5 2920 -1477 852 c-812 469 -1490 857 -1505 863 -15 5 -701 -378 -1523 -853z"/> </g> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 100%;
  height: 607px;
}
.our-work-two__image img {
  object-fit: cover;
  width: 100%;
}
@media (max-width: 1199px) {
  .our-work-two__image {
    height: 100%;
  }
}
@media (max-width: 991px) {
  .our-work-two__image {
    height: 500px;
  }
}
.our-work-two__shape {
  position: absolute;
  top: 3%;
  left: -5%;
  z-index: -1;
  animation: borderScale 4s ease-in-out infinite;
}
.our-work-two__shape img {
  width: 105%;
}
@media (max-width: 991px) {
  .our-work-two__shape {
    max-height: 500px;
    left: 20%;
    right: 20%;
  }
  .our-work-two__shape img {
    width: 75%;
  }
}
.our-work-two__item {
  background: var(--laboix-border-color2, #F3F3F3);
  padding: 40px;
  display: flex;
  align-items: center;
  gap: 40px;
  position: relative;
  z-index: 1;
  transition: all 0.5s ease-in-out;
}
@media (max-width: 575px) {
  .our-work-two__item {
    padding: 20px;
    gap: 20px;
  }
}
.our-work-two__item__icon {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 68"> <path d="M29 0L58 17V51L29 68L0 51V17L29 0Z"/></svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 68"> <path d="M29 0L58 17V51L29 68L0 51V17L29 0Z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  max-width: 58px;
  width: 100%;
  height: 68px;
  background: var(--laboix-base, #021936);
  display: flex;
  align-items: center;
  justify-content: center;
  counter-increment: courseNumber;
  transition: all 0.8s ease-in-out;
}
.our-work-two__item__icon::before {
  color: var(--laboix-white, #fff);
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  content: counters(courseNumber, ".", decimal-leading-zero);
  transition: all 0.5s ease-in-out;
}
.our-work-two__item__title {
  color: var(--laboix-base, #021936);
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
  padding-bottom: 0;
  margin-bottom: 0;
  transition: all 0.5s ease-in-out;
}
@media (max-width: 575px) {
  .our-work-two__item__title {
    font-size: 20px;
  }
}
.our-work-two__item:hover {
  background: var(--laboix-secondary, #0EBD66);
}
.our-work-two__item:hover .our-work-two__item__title {
  color: var(--laboix-white, #fff);
}
.our-work-two__item:hover .our-work-two__item__icon {
  background: var(--laboix-white, #fff);
  transform: rotateY(360deg);
}
.our-work-two__item:hover .our-work-two__item__icon::before {
  color: var(--laboix-base, #021936);
}
.our-work-two__item + .our-work-two__item {
  margin-top: 30px;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about-two {
  padding: 120px 0px;
  position: relative;
  z-index: 1;
  background: var(--laboix-white, #fff);
}
@media (max-width: 991px) {
  .about-two {
    padding: 100px 0px;
  }
}
@media (max-width: 767px) {
  .about-two {
    padding: 80px 0px;
  }
}
.about-two__thumb {
  position: relative;
}
.about-two__thumb img {
  object-fit: cover;
}
@media (max-width: 1199px) and (min-width: 992px) {
  .about-two__thumb img {
    width: 100%;
    object-fit: cover;
    height: auto;
  }
}
@media (max-width: 767px) {
  .about-two__thumb img {
    width: 100%;
    object-fit: cover;
    height: auto;
  }
}
.about-two__thumb::after {
  width: 40px;
  height: 324px;
  content: "";
  position: absolute;
  top: 0;
  left: -70px;
  background: var(--laboix-secondary, #0EBD66);
  animation: topAni 4s ease-in-out infinite;
}
.about-two__thumb__item {
  position: relative;
  z-index: 1;
}
.about-two__thumb__item--two {
  position: relative;
  margin-top: -70px;
  margin-left: -99px;
  left: 0%;
  z-index: 1;
}
@media (max-width: 1199px) {
  .about-two__thumb__item--two {
    margin-top: -100px;
    margin-left: -60px;
  }
  .about-two__thumb__item--two img {
    object-fit: cover;
    width: 100%;
    object-fit: cover;
    height: auto;
    max-width: 260px;
  }
}
.about-two__items {
  position: absolute;
  top: 38%;
  right: 30px;
}
.about-two__box {
  background: var(--laboix-white, #fff);
  bottom: 0;
  right: 30px;
  display: inline-flex;
  padding: 22px 50px 22px 30px;
  align-items: center;
  gap: 25px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
}
.about-two__box__icon {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89 105"><path d="M44.5 0L89 26.0957V78.287L44.5 104.383L0 78.287V26.0957L44.5 0Z"/></svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89 105"><path d="M44.5 0L89 26.0957V78.287L44.5 104.383L0 78.287V26.0957L44.5 0Z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 89px;
  height: 105px;
  background: var(--laboix-secondary, #0EBD66);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  color: var(--laboix-white, #fff);
  transition: all 1s ease-in-out;
}
@media (max-width: 575px) {
  .about-two__box__icon {
    width: 49px;
    height: 59px;
    font-size: 18px;
  }
}
.about-two__box__subtitle {
  color: var(--laboix-text, #6A6A6A);
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: -5px;
  display: block;
}
@media (max-width: 575px) {
  .about-two__box__subtitle {
    font-size: 15px;
  }
}
.about-two__box__text {
  color: var(--laboix-base, #021936);
  font-size: 30px;
  font-weight: 700;
  line-height: 193.333%;
  margin-bottom: 0;
  padding-bottom: 0;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.about-two__box__text:hover {
  background-size: 100% 1px;
}
@media (max-width: 575px) {
  .about-two__box__text {
    font-size: 20px;
  }
}
.about-two__box:hover .about-two__box__icon {
  transform: rotateY(360deg);
  background-color: var(--laboix-base, #021936);
}
.about-two__left {
  margin-right: 50px;
  margin-left: -50px;
}
@media (max-width: 1199px) {
  .about-two__left {
    margin-right: 0px;
    margin-left: 0px;
  }
}
@media (max-width: 991px) {
  .about-two__right {
    margin-top: 40px;
  }
}
.about-two__top {
  margin-bottom: 63px;
}
.about-two__top .sec-title {
  padding-bottom: 25px;
}
.about-two__top__text {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
}
.about-two__feature {
  display: flex;
  align-items: center;
  margin-bottom: 39px;
}
@media (max-width: 575px) {
  .about-two__feature {
    flex-direction: column;
    gap: 50px;
  }
}
.about-two__feature__item {
  padding: 0px 30px 20px;
  background: var(--laboix-white, #fff);
  transition: all 0.4s ease-in-out;
}
.about-two__feature__item:first-child {
  border-top: 1px solid var(--laboix-border-color, #DBDBDB);
  border-bottom: 1px solid var(--laboix-border-color, #DBDBDB);
  border-left: 1px solid var(--laboix-border-color, #DBDBDB);
}
.about-two__feature__item:first-child:hover {
  background-color: var(--laboix-border-color2, #F3F3F3);
  border-top: 1px solid var(--laboix-border-color2, #F3F3F3);
  border-bottom: 1px solid var(--laboix-border-color2, #F3F3F3);
  border-left: 1px solid var(--laboix-border-color2, #F3F3F3);
}
.about-two__feature__item:first-child:hover .about-two__feature__icon {
  background-color: var(--laboix-base, #021936);
  transform: rotateY(360deg);
}

.about-two__feature__item:last-child {
  background-color: var(--laboix-border-color2, #F3F3F3);
  border: 1px solid var(--laboix-border-color2, #F3F3F3);
}
.about-two__feature__item:last-child:hover {
  background-color: var(--laboix-white, #fff);
}
.about-two__feature__item:hover {
  background-color: var(--laboix-border-color2, #F3F3F3);
}
.about-two__feature__item:hover .about-two__feature__icon {
  background-color: var(--laboix-base, #021936);
  transform: rotateY(360deg);
}
.about-two__feature__icon {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59 69"><path d="M29.5 0L59 17.25V51.75L29.5 69L0 51.75V17.25L29.5 0Z"/></svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59 69"><path d="M29.5 0L59 17.25V51.75L29.5 69L0 51.75V17.25L29.5 0Z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 59px;
  height: 69px;
  position: relative;
  margin-top: -33px;
  background: var(--laboix-secondary, #0EBD66);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: var(--laboix-white, #fff);
  margin-bottom: 10px;
  transition: all 1s ease-in-out;
}
.about-two__feature__title {
  color: var(--laboix-base, #021936);
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
}
.about-two__feature__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.about-two__feature__title a:hover {
  background-size: 100% 1px;
}
.about-two__feature__text {
  color: var(--laboix-text, #6A6A6A);
  font-size: 14px;
  font-weight: 500;
  line-height: 185.714%;
  margin-bottom: 0;
  padding-bottom: 0;
}
.about-two__list__item {
  color: var(--laboix-base, #021936);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-shadow: 0 0 0 5px currentColor;
}
.about-two__list__item i {
  font-size: 18px;
  color: var(--laboix-secondary, #0EBD66);
  margin-right: 10px;
}
.about-two__list__item + .about-two__list__item {
  margin-top: 20px;
}
.about-two__link {
  margin-top: 39px;
}
.about-two__shape {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  animation: borderScale 4s ease-in-out infinite;
}
@media (max-width: 991px) {
  .about-two__shape {
    display: none;
  }
}
.about-two__shape--two {
  position: absolute;
  right: 0;
  bottom: 0%;
  animation: heroleft 4s ease-in-out infinite;
}
.about-two__shape--two img {
  object-fit: cover;
  filter: grayscale(100%);
}
@media (max-width: 991px) {
  .about-two__shape--two {
    display: none;
  }
}

.about-three {
  position: relative;
  z-index: 1;
  background-color: var(--laboix-base, #021936);
  padding: 120px 0px;
}
@media (max-width: 991px) {
  .about-three {
    padding: 100px 0px;
  }
}
@media (max-width: 767px) {
  .about-three {
    padding: 80px 0px;
  }
}
.about-three__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: -1;
  mix-blend-mode: color;
}
.about-three__image {
  position: relative;
  z-index: 1;
  margin-right: 40px;
}
@media (max-width: 1199px) {
  .about-three__image {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .about-three__image {
    margin-bottom: 30px;
  }
}
.about-three__image__item {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 343.000000"> <g transform="translate(0.000000,343.000000) scale(0.050000,-0.050000)"> <path d="M1500 6204 l-1490 -655 -5 -2112 -5 -2112 595 -260 c327 -144 997 -437 1488 -653 492 -216 904 -392 916 -392 16 0 2609 1128 2949 1283 l52 24 -5 2111 -5 2111 -1490 654 c-820 360 -1494 655 -1500 655 -5 0 -680 -295 -1500 -654z"/> </g> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 343.000000"> <g transform="translate(0.000000,343.000000) scale(0.050000,-0.050000)"> <path d="M1500 6204 l-1490 -655 -5 -2112 -5 -2112 595 -260 c327 -144 997 -437 1488 -653 492 -216 904 -392 916 -392 16 0 2609 1128 2949 1283 l52 24 -5 2111 -5 2111 -1490 654 c-820 360 -1494 655 -1500 655 -5 0 -680 -295 -1500 -654z"/> </g> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 528px;
  height: 603px;
}
@media (max-width: 1500px) {
  .about-three__image__item {
    width: 100%;
    height: 500px;
  }
  .about-three__image__item img {
    object-fit: cover;
    width: 100%;
  }
}
.about-three__image__item__two {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 343.000000"> <g transform="translate(0.000000,343.000000) scale(0.050000,-0.050000)"> <path d="M1500 6204 l-1490 -655 -5 -2112 -5 -2112 595 -260 c327 -144 997 -437 1488 -653 492 -216 904 -392 916 -392 16 0 2609 1128 2949 1283 l52 24 -5 2111 -5 2111 -1490 654 c-820 360 -1494 655 -1500 655 -5 0 -680 -295 -1500 -654z"/> </g> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 343.000000"> <g transform="translate(0.000000,343.000000) scale(0.050000,-0.050000)"> <path d="M1500 6204 l-1490 -655 -5 -2112 -5 -2112 595 -260 c327 -144 997 -437 1488 -653 492 -216 904 -392 916 -392 16 0 2609 1128 2949 1283 l52 24 -5 2111 -5 2111 -1490 654 c-820 360 -1494 655 -1500 655 -5 0 -680 -295 -1500 -654z"/> </g> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 236px;
  height: 260px;
  position: absolute;
  bottom: -15%;
  right: 0;
}
@media (max-width: 991px) {
  .about-three__image__item__two {
    bottom: 0;
  }
}
@media (max-width: 767px) {
  .about-three__image__item__two {
    width: 180px;
    height: 200px;
  }
}
.about-three__image__funfact {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 245"><path d="M108 0.0750732L216 46.9034L216 197.247L108 244.075L0.000214611 197.247L0 46.9034L108 0.0750732Z"/></svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 245"><path d="M108 0.0750732L216 46.9034L216 197.247L108 244.075L0.000214611 197.247L0 46.9034L108 0.0750732Z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 216px;
  height: 245px;
  background: rgba(var(--laboix-secondary-rgb, 14, 189, 102), 1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 19%;
  left: -19%;
}
@media (max-width: 991px) {
  .about-three__image__funfact {
    bottom: 19%;
    left: 0;
  }
}
.about-three__image__funfact__text {
  color: var(--laboix-white, #fff);
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
}
@media (max-width: 767px) {
  .about-three__image__funfact {
    width: 180px;
    height: 200px;
  }
}
.about-three__image__shape {
  position: absolute;
  top: 10%;
  z-index: -1;
  right: -20px;
  animation: heroleft 4s ease-in-out infinite;
}
@media (max-width: 1500px) {
  .about-three__image__shape img {
    object-fit: cover;
    width: 90%;
  }
}
@media (max-width: 1199px) {
  .about-three__image__shape {
    right: 0px;
  }
  .about-three__image__shape img {
    object-fit: cover;
    width: 100%;
  }
}
@media (max-width: 991px) {
  .about-three__image__shape img {
    object-fit: cover;
    width: 90%;
  }
}
.about-three__image__count {
  color: var(--laboix-white, #fff);
  text-align: center;
  font-size: 60px;
  font-weight: 700;
  line-height: 116.667%;
  display: flex;
  gap: 0;
  justify-content: center;
  padding-bottom: 0;
  margin-bottom: 0;
}
.about-three__top {
  margin-bottom: 40px;
}
.about-three__top .sec-title {
  padding-bottom: 22px;
}
.about-three__top .sec-title .sec-title__title {
  color: var(--laboix-white, #fff);
}
.about-three__top__text {
  color: var(--laboix-text-gray, #97A5B7);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
}
.about-three__feature {
  padding: 40px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 40px;
}
@media (max-width: 1199px) {
  .about-three__feature {
    padding: 20px;
  }
}
.about-three__feature__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: -1;
}
.about-three__feature__bg::after {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(var(--laboix-secondary-rgb, 14, 189, 102), 0.9);
  position: absolute;
  top: 0;
  left: 0;
}
.about-three__feature__icon__box {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81 94"><path d="M40.5 0.0749512L81 23.325V69.825L40.5 93.075L0 69.825V23.325L40.5 0.0749512Z"/></svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81 94"><path d="M40.5 0.0749512L81 23.325V69.825L40.5 93.075L0 69.825V23.325L40.5 0.0749512Z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 81px;
  height: 94px;
  background: var(--laboix-white, #fff);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--laboix-secondary, #0EBD66);
  font-size: 48px;
  transition: all 0.7s ease-in-out;
}
.about-three__feature__title {
  color: var(--laboix-white, #fff);
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 5px;
}
.about-three__feature__text {
  color: var(--laboix-white, #fff);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
  padding-bottom: 0;
  margin-bottom: 0;
}
.about-three__feature:hover .about-three__feature__icon__box {
  background: var(--laboix-base, #021936);
  transform: rotateY(360deg);
  color: var(--laboix-white, #fff);
}
.about-three__list {
  margin-top: 50px;
  margin-bottom: 0;
  margin-left: 0;
}
.about-three__list__item {
  color: var(--laboix-text-gray, #97A5B7);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.about-three__list__item i {
  resize: 18px;
  color: var(--laboix-secondary, #0EBD66);
  margin-right: 17px;
}
.about-three__list__item + .about-three__list__item {
  margin-top: 18px;
}
.about-three__link {
  margin-top: 50px;
}
.about-three__link__btn::before {
  background: var(--laboix-white, #fff);
}
.about-three__link__btn:hover {
  color: var(--laboix-base, #021936);
}
.about-three__shape {
  position: absolute;
  top: 5%;
  right: 5%;
  opacity: 0.3;
  animation: borderScale 4s ease-in-out infinite;
}
.about-three__shape__two {
  position: absolute;
  bottom: 2%;
  right: 2%;
  animation: topAni 4s ease-in-out infinite;
}

.about-fore {
  padding: 120px 0px;
}
@media (max-width: 991px) {
  .about-fore {
    padding: 100px 0px;
  }
}
@media (max-width: 767px) {
  .about-fore {
    padding: 80px 0px;
  }
}
.about-fore__top {
  margin-bottom: 28px;
}
.about-fore__top .sec-title {
  padding-bottom: 22px;
}
.about-fore__text {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
}
.about-fore__feature {
  margin-bottom: 60px;
}
.about-fore__feature__item {
  display: flex;
  align-items: center;
  gap: 25px;
}
.about-fore__feature__item:not(:first-of-type) {
  border-top: 1px solid var(--laboix-border-color, #DBDBDB);
  margin-top: 35px;
  padding-top: 40px;
}
.about-fore__feature__item:hover .about-fore__feature__icon {
  transform: rotateY(180deg);
  color: var(--laboix-base, #021936);
}
.about-fore__feature__icon {
  font-size: 75px;
  color: var(--laboix-secondary, #0EBD66);
  transition: all 0.5s ease-in-out;
}
.about-fore__feature__title {
  margin-top: -4px;
  color: var(--laboix-base, #021936);
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.about-fore__feature__text {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
  margin-bottom: -4px;
  padding-bottom: 0;
}
.about-fore__right {
  margin-left: 20px;
  margin-right: -100px;
}
@media (max-width: 991px) {
  .about-fore__right {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.about-fore__item {
  position: relative;
  z-index: 1;
}
.about-fore__item__image {
  position: relative;
  z-index: 1;
}
.about-fore__item__image img {
  object-fit: cover;
  width: 100%;
}
.about-fore__item__logo {
  display: inline-flex;
  padding: 19px;
  background: var(--laboix-secondary, #0EBD66);
  position: absolute;
  top: 75px;
  right: -50px;
  z-index: 1;
  animation: topAni 4s ease-in-out infinite;
}
.about-fore__item__funfact {
  position: absolute;
  right: 40px;
  bottom: -18px;
  z-index: 1;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  padding: 70px;
}
@media (max-width: 1199px) {
  .about-fore__item__funfact {
    right: 0px;
  }
}
@media (max-width: 767px) {
  .about-fore__item__funfact {
    padding: 20px 10px;
  }
}
.about-fore__item__funfact__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: -1;
}
.about-fore__item__funfact__bg::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.7);
}
.about-fore__item__funfact__count {
  margin-top: -10px;
  color: var(--laboix-white, #fff);
  text-align: center;
  font-size: 53.333px;
  font-weight: 700;
  line-height: 115.989%;
  display: flex;
  gap: 0;
  justify-content: center;
  margin-bottom: 2px;
}
.about-fore__item__funfact__count .pluse {
  color: var(--laboix-secondary, #0EBD66);
  font-size: 53.333px;
  font-weight: 700;
  line-height: 61.861px;
  display: inline-block;
}
.about-fore__item__funfact__text {
  color: var(--laboix-white, #fff);
  text-align: center;
  font-size: 21.795px;
  font-weight: 700;
  line-height: 117.783%;
  margin-bottom: 0;
  padding-bottom: 0;
}
.about-fore__item__funfact__shape {
  position: absolute;
  top: 10%;
  left: 20%;
  opacity: 0.2;
  z-index: -1;
}
.about-fore__item__call {
  z-index: 1;
  display: inline-flex;
  background: var(--laboix-secondary, #0EBD66);
  gap: 22px;
  align-items: center;
  padding: 15px 50px 15px 30px;
  position: absolute;
  margin-top: -80px;
  margin-left: 38px;
}
@media (max-width: 1199px) {
  .about-fore__item__call {
    margin-left: 0px;
  }
}
@media (max-width: 767px) {
  .about-fore__item__call {
    display: block;
    padding: 15px 30px;
    margin-top: -100px;
  }
}
.about-fore__item__icon {
  font-size: 45px;
  color: var(--laboix-base, #021936);
}
.about-fore__item__subtitle {
  color: var(--laboix-white, #fff);
  font-size: 14px;
  font-weight: 500;
  line-height: 289.001%;
  margin-bottom: -15px;
  margin-top: -5px;
  padding-bottom: 0;
  display: block;
}
.about-fore__item__number {
  margin-top: -15px;
  color: var(--laboix-white, #fff);
  font-size: 21.795px;
  font-weight: 700;
  line-height: 241.667%;
  padding-bottom: 0;
  margin-bottom: -5px;
}
.about-fore__item__number a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.about-fore__item__number a:hover {
  background-size: 100% 1px;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.service-card__inner {
  position: relative;
  background: var(--laboix-base, #021936);
  z-index: 1;
  transition: all 0.5s ease-in-out;
}
.service-card__inner:hover {
  background: var(--laboix-secondary, #0EBD66);
}
.service-card__top {
  padding: 32px 30px 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.service-card__title {
  color: var(--laboix-white, #fff);
  font-size: 30px;
  font-weight: 700;
  line-height: 133.333%;
  letter-spacing: -0.6px;
  padding-bottom: 0;
  margin-bottom: 0;
}
@media (max-width: 1199px) and (min-width: 992px) {
  .service-card__title {
    font-size: 25px;
  }
}
.service-card__title a {
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.service-card__title a:hover {
  background-size: 100% 1px;
}
.service-card__icon {
  font-size: 60px;
  color: var(--laboix-white, #fff);
  opacity: 0.2;
}
.service-card__icon i {
  color: inherit;
}
.service-card__thumb {
  position: relative;
}
.service-card__thumb__item {
  position: relative;
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 261.000000"> <g transform="translate(0.000000,261.000000) scale(0.050000,-0.050000)"> <path d="M4880 4790 c-610 -236 -1958 -755 -2995 -1154 l-1885 -726 0 -1455 0 -1455 3000 0 3000 0 0 2610 c0 1436 -2 2610 -5 2609 -3 0 -504 -193 -1115 -429z"/> </g> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 261.000000"> <g transform="translate(0.000000,261.000000) scale(0.050000,-0.050000)"> <path d="M4880 4790 c-610 -236 -1958 -755 -2995 -1154 l-1885 -726 0 -1455 0 -1455 3000 0 3000 0 0 2610 c0 1436 -2 2610 -5 2609 -3 0 -504 -193 -1115 -429z"/> </g> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
}
.service-card__thumb__item img {
  object-fit: cover;
  width: 100%;
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.service-card__thumb__item::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.6);
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.service-card__thumb__shape {
  position: absolute;
  top: 15px;
  left: 0;
}
@media (max-width: 1199px) and (min-width: 992px) {
  .service-card__thumb__shape {
    top: 0px;
  }
}
.service-card__thumb:hover .service-card__thumb__item img {
  transform: scale(1.1);
}
.service-card__thumb:hover .service-card__thumb__item::after {
  visibility: visible;
  opacity: 1;
}
@media (max-width: 767px) {
  .service-card {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

.service-one {
  position: relative;
  background-color: var(--laboix-white, #fff);
  padding: 120px 0;
}
.service-one__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% + 160px);
  margin-top: -160px;
  z-index: 2;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--laboix-gray, #f9f6f1);
}
@media (min-width: 1400px) {
  .service-one__bg {
    width: calc(100% - 240px);
    left: 120px;
  }
}
@media (max-width: 767px) {
  .service-one {
    padding: 80px 0;
  }
}
.service-one .container {
  position: relative;
  z-index: 3;
  margin-top: -160px;
}
.service-one--page .container {
  margin-top: 0;
}
.service-one--page .service-one__bg {
  height: 100%;
  margin-top: 0;
}
.service-one .sec-title {
  text-align: center;
}
.service-one .col-lg-3 {
  width: 20%;
}
@media (max-width: 1199px) {
  .service-one .col-lg-3 {
    width: 33.33%;
  }
}
@media (max-width: 767px) {
  .service-one .col-lg-3 {
    width: 100%;
  }
}
.service-one__item {
  position: relative;
  background-color: var(--laboix-white, #fff);
  box-shadow: 0px 10px 60px 0px rgba(var(--laboix-black-rgb, 0, 0, 0), 0.07);
  margin-bottom: 30px;
  z-index: 2;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top left;
  transition: all 500ms ease;
}
.service-one__item__wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 39px 30px 36px;
  transition: all 500ms ease;
}
.service-one__item__hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  transition: 500ms ease;
  transform: scale(1.2);
  z-index: -1;
}
.service-one__item__hover::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(var(--laboix-black2-rgb, 1, 17, 38), 0.7);
}
.service-one__item:hover .service-one__item__hover {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.service-one__item:hover .service-one__item__title {
  color: var(--laboix-white, #fff);
}
.service-one__item__icon {
  font-size: 60px;
  line-height: 1;
  position: relative;
  z-index: 3;
  color: var(--laboix-base, #021936);
  display: inline-block;
  margin: 0 0 23px;
  transition: 500ms ease;
}
.service-one__item__icon span {
  display: inline-block;
  transition: all 500ms linear;
  transition-delay: 0s;
  transition-delay: 0.1s;
  transform: scale(1);
}
.service-one__item:hover .service-one__item__icon {
  color: var(--laboix-base, #021936);
}
.service-one__item:hover .service-one__item__icon span {
  transform: scale(0.9);
}
.service-one__item__title {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  max-width: 125px;
  transition: all 300ms ease;
  position: relative;
  z-index: 3;
  margin: 0 auto;
}
.service-one__item__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.service-one__item__title a:hover {
  background-size: 100% 1px;
}
.service-one__item__title a:hover {
  color: var(--laboix-base, #021936);
  background-size: 100% 1px;
}
.service-one__info {
  position: relative;
  max-width: 830px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--laboix-border-color, #DBDBDB);
  padding: 17px 18px 16px 28px;
}
@media (max-width: 767px) {
  .service-one__info {
    display: block;
    text-align: center;
  }
}
.service-one__info__text {
  margin: 0;
}
.service-one__info .laboix-btn {
  font-size: 10px;
  font-weight: 800;
  letter-spacing: 2px;
  padding: 11px 29px;
}
@media (max-width: 767px) {
  .service-one__info .laboix-btn {
    margin: 15px 0 10px;
  }
}

.service-page {
  padding: 120px 0px;
  position: relative;
  z-index: 1;
  background: var(--laboix-white, #fff);
}
@media (max-width: 991px) {
  .service-page {
    padding: 100px 0px;
  }
}
@media (max-width: 991px) {
  .service-page {
    padding: 80px 0px;
  }
}
.service-page--one {
  position: relative;
}
.service-page--one::after {
  position: absolute;
  top: 0;
  content: "";
  left: 0;
  width: 100%;
  height: 45%;
  z-index: -2;
  background: var(--laboix-border-color2, #F3F3F3);
}
.service-page--home {
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto;
  padding: 120px 0;
}
@media (max-width: 767px) {
  .service-page--home {
    padding: 80px 0;
  }
}
.service-page--home .sec-title {
  text-align: center;
}
.service-page__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 45%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 0.03;
  mix-blend-mode: luminosity;
  background: var(--laboix-border-color2, #F3F3F3);
  animation: bgSlide 10s ease-in-out infinite;
}
.service-page__carousel .owl-nav {
  display: none !important;
}

.service-two {
  padding: 120px 0px;
}
@media (max-width: 991px) {
  .service-two {
    padding: 100px 0px;
  }
}
@media (max-width: 991px) {
  .service-two {
    padding: 80px 0px;
  }
}
.service-two__item {
  border: 1px solid var(--laboix-border-color, #DBDBDB);
  padding: 0px 40px 40px;
}
@media (max-width: 1199px) and (min-width: 991px) {
  .service-two__item {
    padding: 0px 20px 30px;
  }
}
.service-two__item:hover .service-two__icon {
  background: var(--laboix-base, #021936);
}
.service-two__icon {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.000000 92.000000"> <g transform="translate(0.000000,92.000000) scale(0.050000,-0.050000)" > <path d="M1 1205 l1 -635 487 -275 c268 -151 499 -275 514 -274 15 0 243 124 507 275 l480 276 5 634 6 634 -1001 0 -1000 0 1 -635z"/> </g> </svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.000000 92.000000"> <g transform="translate(0.000000,92.000000) scale(0.050000,-0.050000)" > <path d="M1 1205 l1 -635 487 -275 c268 -151 499 -275 514 -274 15 0 243 124 507 275 l480 276 5 634 6 634 -1001 0 -1000 0 1 -635z"/> </g> </svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 100px;
  height: 92px;
  background: var(--laboix-secondary, #0EBD66);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: var(--laboix-white, #fff);
  margin-bottom: 23px;
  transition: all 0.4s ease-in-out;
}
.service-two__title {
  color: var(--laboix-base, #021936);
  font-size: 24px;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: -0.48px;
  margin-bottom: 15px;
  padding-bottom: 0;
}
.service-two__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.service-two__title a:hover {
  background-size: 100% 1px;
}
@media (max-width: 991px) {
  .service-two__title {
    font-size: 23px;
  }
}
.service-two__text {
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: 187.5%;
  padding-bottom: 0;
  margin-bottom: -2px;
}

.service-three {
  position: relative;
  z-index: 1;
}
.service-three__list {
  display: flex;
  gap: 0;
  position: relative;
  z-index: 1;
  margin-top: -90px;
  background: var(--laboix-white, #fff);
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .service-three__list {
    flex-direction: column;
  }
}
.service-three__list__item {
  display: flex;
  gap: 20px;
  padding: 40px 30px;
  background: var(--laboix-white, #fff);
  transition: all 0.5s ease-in-out;
}
.service-three__list__item:not(:first-of-type) {
  border-right: 1px solid #DFDFDF;
  border-top: 1px solid #DFDFDF;
  border-bottom: 1px solid #DFDFDF;
}
@media (max-width: 991px) {
  .service-three__list__item:not(:first-of-type) {
    border-top: 0px solid #DFDFDF;
    border-right: 1px solid #DFDFDF;
    border-left: 1px solid #DFDFDF;
    border-bottom: 1px solid #DFDFDF;
  }
}
.service-three__list__item:nth-child(1) {
  border: 1px solid #DFDFDF;
}
.service-three__list__item:hover {
  border: 1px solid var(--laboix-base, #021936);
  background: var(--laboix-base, #021936);
}
.service-three__list__item:hover .service-three__list__title {
  color: var(--laboix-white, #fff);
}
.service-three__list__item:hover .service-three__list__text {
  color: var(--laboix-text-gray, #97A5B7);
}
.service-three__list__item:hover:not(:first-of-type) {
  border-right: 1px solid var(--laboix-base, #021936);
  border-top: 1px solid var(--laboix-base, #021936);
  border-bottom: 1px solid var(--laboix-base, #021936);
}
.service-three__list__item:hover:nth-child(1) {
  border: 1px solid var(--laboix-base, #021936);
}
.service-three__list__icon {
  font-size: 53px;
  color: var(--laboix-secondary, #0EBD66);
}
.service-three__list__icon i {
  color: inherit;
}
.service-three__list__title {
  margin-top: -5px;
  color: var(--laboix-base, #021936);
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 18px;
}
.service-three__list__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.service-three__list__title a:hover {
  background-size: 100% 1px;
}
.service-three__list__text {
  color: var(--laboix-text, #6A6A6A);
  font-size: 14px;
  font-weight: 500;
  line-height: 185.714%;
  margin-bottom: -9px;
  padding-bottom: 0px;
  transition: all 0.5s ease-in-out;
}

.service-fore {
  position: relative;
  z-index: 1;
}
.service-fore--page {
  padding: 120px 0px;
}
@media (max-width: 991px) {
  .service-fore--page {
    padding: 100px 0px;
  }
}
@media (max-width: 767px) {
  .service-fore--page {
    padding: 80px 0px;
  }
}
.service-fore__card {
  background: var(--laboix-base, #021936);
  position: relative;
  z-index: 1;
  transition: all 0.4s ease-in-out;
}
.service-fore__card:hover {
  background: var(--laboix-secondary, #0EBD66);
}
.service-fore__card:hover .service-fore__top::after {
  background: rgba(var(--laboix-white-rgb, 255, 255, 255), 0.1);
}
.service-fore__card:hover .service-fore__icon {
  background: var(--laboix-base, #021936);
}
.service-fore__card:hover .service-fore__thumb::after {
  visibility: visible;
  opacity: 1;
}
.service-fore__top {
  padding: 40px 20px 0px;
  position: relative;
  z-index: 1;
}
.service-fore__top::after {
  content: "";
  background: #062142;
  position: absolute;
  bottom: 40px;
  left: 20px;
  right: 20px;
  top: 20px;
  z-index: -2;
  transition: all 0.4s ease-in-out;
}
.service-fore__title {
  color: var(--laboix-white, #fff);
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 133.333%;
  letter-spacing: -0.6px;
  margin-bottom: 0;
  padding-bottom: 20px;
}
.service-fore__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.service-fore__title a:hover {
  background-size: 100% 1px;
}
.service-fore__icon {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69 81"> <path d="M34.5 0L69 20.25V60.75L34.5 81L0 60.75V20.25L34.5 0Z"/></svg>');
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69 81"> <path d="M34.5 0L69 20.25V60.75L34.5 81L0 60.75V20.25L34.5 0Z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-size: auto;
  mask-size: auto;
  width: 69px;
  height: 81px;
  background: var(--laboix-secondary, #0EBD66);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: -40px;
  z-index: 1;
  font-size: 35px;
  color: var(--laboix-white, #fff);
  transition: all 0.4s ease-in-out;
}
.service-fore__thumb {
  position: relative;
}
.service-fore__thumb img {
  object-fit: cover;
  width: 100%;
}
.service-fore__thumb::after {
  position: absolute;
  top: 0%;
  left: 0%;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.4);
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

/*--------------------------------------------------------------
# Service details
--------------------------------------------------------------*/
.service-details {
  padding: 120px 0;
  padding-top: 100px;
}
@media (max-width: 767px) {
  .service-details {
    padding: 80px 0;
    padding-top: 60px;
  }
}
.service-details__thumbnail {
  margin-bottom: 40px;
}
.service-details__thumbnail img {
  max-width: 100%;
}
.service-details__title {
  margin: 0;
  margin-bottom: 15px;
  color: var(--laboix-base, #021936);
  font-size: 30px;
  font-weight: 700;
  line-height: 156.667%;
  letter-spacing: -0.6px;
}
.service-details__sub-title {
  margin: 0;
  margin-top: 30px;
  margin-bottom: 17px;
  color: var(--laboix-secondary, #0EBD66);
  font-size: 30px;
  font-weight: 700;
  line-height: 156.667%;
}
.service-details__text {
  margin: 0;
  color: var(--laboix-text, #6A6A6A);
  font-size: 16px;
  font-weight: 500;
  line-height: 200%;
}
.service-details__text + .service-details__title {
  margin-top: 40px;
}
.service-details__text + .service-details__list {
  margin-top: 40px;
}
.service-details__text + .service-details__text {
  margin-top: 35px;
}
.service-details__list {
  margin-bottom: 0;
}
.service-details__list li {
  position: relative;
  color: var(--laboix-base, #021936);
  font-size: 18px;
  font-weight: 600;
  line-height: 261.111%;
  margin-bottom: -5px;
}
.service-details__list li i {
  color: var(--laboix-secondary, #0EBD66);
  margin-right: 7px;
}
@media (max-width: 575px) {
  .service-details__list li {
    line-height: 161.111%;
    font-size: 16px;
  }
}
.service-details__list li + li {
  margin-top: 10px;
}
@media (min-width: 768px) {
  .service-details__list li + li {
    margin-top: 0;
  }
}
.service-details__single + .service-details__single {
  margin-top: 35px;
}
.service-details__benefit {
  margin-left: -30px;
}
@media (max-width: 1199px) {
  .service-details__benefit {
    margin-left: 0px;
  }
}
.service-details__benefit__title {
  margin: 0;
  font-size: 25px;
  font-size: 30px;
  font-weight: 800;
  line-height: 156.667%;
  letter-spacing: -0.9px;
  margin-bottom: -4px;
}
@media (min-width: 768px) {
  .service-details__benefit__title {
    font-size: 30px;
  }
}
.service-details__benefit__sub_title {
  margin-top: -3px;
  margin-bottom: 2px;
  color: var(--laboix-secondary, #0EBD66);
  font-size: 24px;
  font-weight: 700;
  line-height: 195.833%;
}
@media (max-width: 575px) {
  .service-details__benefit__sub_title {
    line-height: 135%;
  }
}
.service-details__benefit__text {
  max-width: 475px;
  line-height: 200%;
}
.service-details__single-inner .service-details__title {
  margin-top: -10px;
  margin-bottom: 28px;
}
.service-details__inner-item {
  display: flex;
  background: var(--laboix-white, #fff);
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.08);
}
.service-details__inner-item .item-icon {
  background: var(--laboix-secondary, #0EBD66);
  max-width: 85px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}
.service-details__inner-item .item-icon .icon {
  font-size: 45px;
  color: var(--laboix-white, #fff);
}
.service-details__inner-item .item-content {
  padding: 25px 35px 25px 30px;
}
.service-details__inner-item .item__title {
  color: var(--laboix-base, #021936);
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
}
.service-details__inner-item .item__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.service-details__inner-item .item__title a:hover {
  background-size: 100% 1px;
}
.service-details__inner-item .item__dec {
  color: var(--laboix-text, #6A6A6A);
  font-size: 14px;
  font-weight: 600;
  line-height: 178.571%;
  padding-bottom: 0;
  margin-bottom: -3px;
}
.service-details__inner-item:hover .item-icon {
  background: var(--laboix-base, #021936);
}
.service-details .faq-page__accordion {
  padding-top: 20px;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Instagram
--------------------------------------------------------------*/
.instagram-one {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 767px) {
  .instagram-one {
    padding: 80px 0;
  }
}
@media (min-width: 1400px) {
  .instagram-one .container {
    max-width: 1380px;
  }
}
.instagram-one__title {
  display: block;
  text-align: center;
  position: relative;
  font-size: 14px;
  color: var(--laboix-text, #6A6A6A);
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  font-family: var(--laboix-font, "Lexend", sans-serif);
  letter-spacing: 2.8px;
  margin: 0 auto 39px;
}
.instagram-one__title span {
  display: inline-block;
  background-color: var(--laboix-white, #fff);
  position: relative;
  z-index: 2;
  padding: 0 28px;
}
@media (max-width: 550px) {
  .instagram-one__title span {
    padding: 0 18px;
  }
}
.instagram-one__title::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 9px;
  width: 518px;
  height: 1px;
  content: "";
  background-color: var(--laboix-border-color, #DBDBDB);
}
@media (max-width: 550px) {
  .instagram-one__title::after {
    width: 100%;
  }
}
.instagram-one a {
  overflow: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}
.instagram-one a::before {
  position: absolute;
  content: "\f16d";
  font-family: "Font Awesome 5 Brands";
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 55%;
  transform: translateY(-50%);
  z-index: 3;
  color: var(--laboix-white, #fff);
  font-size: 30px;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
  transition-delay: 0s;
}
.instagram-one a::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  z-index: 1;
  opacity: 0;
  background: rgba(var(--laboix-black-rgb, 0, 0, 0), 0.5);
  visibility: hidden;
  transition: all ease 0.4s;
}
.instagram-one a:hover::after {
  height: 100%;
  opacity: 1;
  visibility: visible;
}
.instagram-one a:hover::before {
  top: 50%;
  visibility: visible;
  opacity: 1;
  transition-delay: 0.4s;
}
.instagram-one a img {
  width: 100%;
  height: auto;
  transition: all ease 0.3s;
}
.instagram-one a:hover img {
  transform: scale(1.1) rotate(1.5deg);
  -moz-transform: scale(1.1) rotate(1.5deg);
  -webkit-transform: scale(1.1) rotate(1.5deg);
  -ms-transform: scale(1.1) rotate(1.5deg);
  -o-transform: scale(1.1) rotate(1.5deg);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.instagram-two {
  position: relative;
  z-index: 2;
  padding: 0 0;
  margin-bottom: -120px;
}
.instagram-two .container {
  max-width: 1670px;
}
.instagram-two a {
  overflow: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}
.instagram-two a::before {
  position: absolute;
  content: "\f16d";
  font-family: "Font Awesome 5 Brands";
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 55%;
  transform: translateY(-50%);
  z-index: 3;
  color: var(--laboix-white, #fff);
  font-size: 40px;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
  transition-delay: 0s;
}
.instagram-two a::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  z-index: 1;
  opacity: 0;
  background: rgba(var(--laboix-base-rgb, 2, 25, 54), 0.9);
  visibility: hidden;
  transition: all ease 0.4s;
}
.instagram-two a:hover::after {
  height: 100%;
  opacity: 1;
  visibility: visible;
}
.instagram-two a:hover::before {
  top: 50%;
  visibility: visible;
  opacity: 1;
  transition-delay: 0.4s;
}
.instagram-two a img {
  width: 100%;
  height: auto;
  transition: all ease 0.3s;
}
.instagram-two a:hover img {
  transform: scale(1.1) rotate(1.5deg);
  -moz-transform: scale(1.1) rotate(1.5deg);
  -webkit-transform: scale(1.1) rotate(1.5deg);
  -ms-transform: scale(1.1) rotate(1.5deg);
  -o-transform: scale(1.1) rotate(1.5deg);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

/*--------------------------------------------------------------
# Opening Time
--------------------------------------------------------------*/
.opening {
  position: relative;
  margin-bottom: -85px;
}
@media (max-width: 1199px) {
  .opening {
    margin-bottom: 0;
  }
}
.opening--home-three {
  margin-bottom: 0;
  padding-bottom: 120px;
}
@media (max-width: 767px) {
  .opening--home-three {
    padding-bottom: 80px;
  }
}
.opening__wrapper {
  background-color: var(--laboix-base, #021936);
  position: relative;
  z-index: 2;
  padding: 39px 0;
}
@media (max-width: 767px) {
  .opening__wrapper {
    padding: 30px 20px;
    text-align: center;
  }
}
.opening__wrapper::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 180px;
  height: 100%;
  content: "";
  opacity: 0.102;
  background-image: linear-gradient(90deg, rgba(var(--laboix-black-rgb, 0, 0, 0), 0) 0%, rgba(var(--laboix-black-rgb, 0, 0, 0), 1) 100%);
}
@media (max-width: 991px) {
  .opening__wrapper::after {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .opening__wrapper::after {
    width: 155px;
  }
}
.opening__icon {
  font-size: 65px;
  color: var(--laboix-white, #fff);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 0 0;
  margin-right: -10px;
  position: relative;
  z-index: 2;
}
.opening__title {
  color: var(--laboix-white, #fff);
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 700;
  margin: 27px -30px 0 33px;
  position: relative;
}
@media (max-width: 767px) {
  .opening__title {
    margin: 20px 0 0;
  }
}
.opening__info {
  font-size: 12px;
  line-height: 30px;
  color: var(--laboix-white, #fff);
  text-transform: uppercase;
  padding: 12px 0 20px;
  margin: 0 0 0 -14px;
}
@media (max-width: 991px) {
  .opening__info {
    margin: 0 0 0;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .opening__info {
    margin: 0 0 0;
  }
}
.opening__info--last {
  margin-left: -45px;
  padding-left: 60px;
  border-left: 1px solid rgba(var(--laboix-white-rgb, 255, 255, 255), 0.2);
}
@media (max-width: 991px) {
  .opening__info--last {
    margin: 0 0 0;
    padding: 0;
    border: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .opening__info--last {
    margin-left: -30px;
    padding-left: 20px;
  }
}
.opening__info__text {
  display: block;
  font-size: 20px;
  text-transform: none;
}

/*--------------------------------------------------------------
# Boxed Home
--------------------------------------------------------------*/
body.boxed-wrapper {
  position: relative;
}
body.boxed-wrapper .page-wrapper {
  max-width: 1530px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--laboix-white, #fff);
  box-shadow: 0px 0px 100px 0px rgba(var(--laboix-black-rgb, 0, 0, 0), 0.08);
}
body.boxed-wrapper .main-slider-one__item {
  padding-top: 200px;
  padding-bottom: 220px;
}

/*--------------------------------------------------------------
# Work Process
--------------------------------------------------------------*/
.work-process-one {
  position: relative;
  counter-reset: count;
  padding: 120px 0 90px;
}
@media (max-width: 767px) {
  .work-process-one {
    padding: 80px 0 50px;
  }
}
.work-process-one .sec-title {
  text-align: center;
}
.work-process-one__border {
  width: 100%;
  height: 2px;
  background-color: var(--laboix-base, #021936);
  top: 103px;
  position: relative;
}
@media (max-width: 767px) {
  .work-process-one__border {
    display: none;
  }
}
.work-process-one__border::after {
  position: absolute;
  left: 0;
  top: -5px;
  width: 11px;
  height: 11px;
  background-color: var(--laboix-black, #000);
  border-radius: 50%;
  content: "";
}
.work-process-one__border::before {
  position: absolute;
  right: 0;
  top: -5px;
  width: 11px;
  height: 11px;
  background-color: var(--laboix-black, #000);
  border-radius: 50%;
  content: "";
}
.work-process-one__gradiant-left {
  height: 100%;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 5%;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(-45deg, rgba(var(--laboix-base-rgb, 2, 25, 54), 1) 0%, rgba(var(--laboix-black-rgb, 0, 0, 0), 1) 100%) 1;
}
.work-process-one__gradiant-right {
  height: 100%;
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  width: 5%;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(-45deg, rgba(var(--laboix-black-rgb, 0, 0, 0), 1) 0%, rgba(var(--laboix-base-rgb, 2, 25, 54), 1) 100%) 1;
}
.work-process-one__item {
  position: relative;
  counter-increment: count;
  margin-bottom: 30px;
}
.work-process-one__item__thumb {
  display: inline-block;
  width: 202px;
  height: 202px;
  border: 2px solid var(--laboix-base, #021936);
  background-color: var(--laboix-white, #fff);
  border-radius: 50%;
  padding: 5px;
  position: relative;
  margin-bottom: 30px;
}
.work-process-one__item__thumb-wrap {
  background-color: var(--laboix-black, #000);
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}
.work-process-one__item__thumb-wrap::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  background: rgba(var(--laboix-white-rgb, 255, 255, 255), 0.2);
  border-radius: 50%;
  transition: all 500ms linear;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 2;
}
.work-process-one__item__thumb-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  opacity: 0.3;
}
.work-process-one__item__thumb__number {
  width: 67px;
  height: 67px;
  background-color: var(--laboix-base, #021936);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--laboix-white, #fff);
  font-family: var(--laboix-heading-font, "Lexend", sans-serif);
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  right: -9px;
  top: 5px;
  transition: all 500ms linear;
  transition-delay: 0s;
  transition-delay: 0s;
  transition-delay: 0.1s;
  transform: scale(1);
}
.work-process-one__item__thumb__number::before {
  content: counters(count, ".", decimal-leading-zero);
  position: absolute;
  top: -6px;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.work-process-one__item__thumb__icon {
  width: 60px;
  height: 60px;
  font-size: 60px;
  color: var(--laboix-base, #021936);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.work-process-one__item:hover .work-process-one__item__thumb-wrap::after {
  -webkit-animation: zoom-hover 0.95s;
  animation: zoom-hover 0.95s;
}
.work-process-one__item:hover .work-process-one__item__thumb__number {
  transform: scale(0.95);
}
.work-process-one__item__content {
  position: relative;
  box-shadow: 0px 0px 60px 0px rgba(var(--laboix-black-rgb, 0, 0, 0), 0.07);
  background-color: var(--laboix-white, #fff);
  border-radius: 100px;
  padding: 24px 20px 26px;
}
.work-process-one__item__content::after {
  position: absolute;
  left: 0;
  right: 0;
  top: -10px;
  content: "";
  margin: auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 25px 10px 25px;
  border-color: transparent transparent var(--laboix-white, #fff) transparent;
}
.work-process-one__item__title {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 5px;
}
.work-process-one__item__text {
  font-size: 15px;
  line-height: 26px;
  margin: 0;
}
/*# sourceMappingURL=laboix.css.map */

