$teal:#004d40;
.react-slideshow-fadezoom-wrapper{
  height: 100vh !important;
}
.spanStyle{
  padding: 40px;
    background: #a2ddf3;
    color: rgb(1,18,28);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: x-large;
    height: 100vh;
    border-right: 20px solid #151528;
    align-content: center;
    width: 30vw;
    animation:topAni 4s ease-in-out infinite;
    border-top-right-radius: 700px;
    border-bottom-right-radius: 700px;

  // width: 0; 
  // height: 0; 
  // border-top: 15px solid transparent;
  // border-bottom: 15px solid transparent;
  // border-left: 15px solid green;
}
.subTitle{
  font-size: medium;
}
@media screen and (max-width:"700px") {
  .spanStyle{
    width: 50vw;
  }
  
}

