.main-slider-one2 {
    position: relative;
  }
  .main-slider-one2__carousel {
    position: relative;
    width: 100%;
  }
  .main-slider-one2__carousel .owl-dots {
    display: flex;
    gap: 10px;
    position: absolute;
    bottom: 66px;
    left: 19.45%;
  }
  .main-slider-one2__carousel .owl-dots .owl-dot {
    width: 8px;
    border-radius: 50%;
    background-color: rgba(var(--laboix-white-rgb, 255, 255, 255), 0.2);
    height: 8px;
  }
  .main-slider-one2__carousel .owl-dots .owl-dot.active,
  .main-slider-one2__carousel .owl-dots .owl-dot:hover {
    background-color: rgba(var(--laboix-secondary-rgb, 14, 189, 102), 1);
  }
  .main-slider-one2__item {
    background-color: var(--laboix-base, #021936);
    position: relative;
    z-index: 3;
    padding-top: 280px;
    padding-bottom: 250px;
  }
  @media (max-width: 1800px) {
    .main-slider-one2__item {
      padding-top: 150px;
      padding-bottom: 200px;
    }
  }
  @media (max-width: 1199px) {
    .main-slider-one2__item {
      padding-top: 130px;
      padding-bottom: 150px;
    }
  }
  @media (max-width: 991px) {
    .main-slider-one2__item {
      padding-top: 120px;
      padding-bottom: 130px;
    }
  }
  .main-slider-one2__bg {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: url("../../images/backgrounds/slider-1-2.webp");
    background-position: top right;
    transition: transform 1000ms ease, opacity 1000ms ease;
    /* transform: translatex(50%) scalex(2); */
    filter: blur(1px);
    opacity: 46;
  }
  @media (max-width: 991px) {
    .main-slider-one2__bg {
      display: none;
    }
  }
  .main-slider-one2__bg::before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 79%;
    content: "";
    z-index: -2;
    -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 185.000000"> <g transform="translate(0.000000,185.000000) scale(0.050000,-0.050000)"> <path d="M0 1850 l0 -1850 2993 0 2993 0 -37 41 c-21 23 -341 322 -713 664 -372 343 -676 630 -676 637 0 8 148 157 329 331 l329 317 -810 855 -809 855 -1800 0 -1799 0 0 -1850z"/> </g> </svg>');
    mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 185.000000"> <g transform="translate(0.000000,185.000000) scale(0.050000,-0.050000)"> <path d="M0 1850 l0 -1850 2993 0 2993 0 -37 41 c-21 23 -341 322 -713 664 -372 343 -676 630 -676 637 0 8 148 157 329 331 l329 317 -810 855 -809 855 -1800 0 -1799 0 0 -1850z"/> </g> </svg>');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: top center;
    mask-position: top center;
    -webkit-mask-size: auto;
    mask-size: auto;
    background: linear-gradient(180deg, #0EBD66 0%, rgba(14, 189, 102, 0) 100%);
  }
  @media (max-width: 1400px) {
    .main-slider-one2__bg::before {
      width: 100%;
      height: 100%;
    }
  }
  @media (max-width: 991px) {
    .main-slider-one2__bg::before {
      display: none;
    }
  }
  .main-slider-one2__bg::after {
    content: "";
    -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 193.000000"> <g transform="translate(0.000000,193.000000) scale(0.050000,-0.050000)"> <path d="M0 1930 l0 -1930 2990 0 c1645 0 2990 4 2990 8 0 5 -337 320 -748 700 l-748 691 293 284 c161 156 319 308 350 338 l57 53 -846 893 -845 893 -1746 0 -1747 0 0 -1930z"/> </g> </svg>');
    mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 193.000000"> <g transform="translate(0.000000,193.000000) scale(0.050000,-0.050000)"> <path d="M0 1930 l0 -1930 2990 0 c1645 0 2990 4 2990 8 0 5 -337 320 -748 700 l-748 691 293 284 c161 156 319 308 350 338 l57 53 -846 893 -845 893 -1746 0 -1747 0 0 -1930z"/> </g> </svg>');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: top center;
    mask-position: top center;
    -webkit-mask-size: auto;
    mask-size: auto;
    position: absolute;
    left: 0;
    top: 0;
    width: 75%;
    height: 101%;
    z-index: -1;
    background-color: var(--laboix-base, #021936);
  }
  @media (max-width: 1400px) {
    .main-slider-one2__bg::after {
      width: 95%;
      height: 100%;
    }
  }
  @media (max-width: 991px) {
    .main-slider-one2__bg::after {
      display: none;
    }
  }
  .main-slider-one2__content {
    position: relative;
    display: inline-block;
    z-index: 3;
    animation: topAni 4s ease-in-out infinite;
    overflow: hidden;
  }
  .main-slider-one2__sub-title {
    color: var(--laboix-secondary, #0EBD66);
    font-family: var(--laboix-font, "Lexend", sans-serif);
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    margin: -7px 0 10px;
    transition: transform 1000ms ease, opacity 1000ms ease;
    transform: translateY(-200px);
  }
  .main-slider-one2__title {
    color: var(--laboix-white, #fff);
    display: contents;
    overflow: hidden;
    margin: 0 0 17px;
    opacity: 0;
    font-size: 70px;
    font-weight: 600;
    line-height: 114.286%;
    letter-spacing: -2.1px;
    text-shadow: 0 0 1px currentColor;
    transition: transform 1200ms ease, opacity 1200ms ease;
    transform: translateX(200px);
    animation: heroleft 4s ease-in-out infinite;
  }
  @media (max-width: 1199px) {
    .main-slider-one2__title {
      font-size: 60px;
    }
  }
  @media (max-width: 991px) {
    .main-slider-one2__title {
      font-size: 50px;
    }
  }
  @media (max-width: 767px) {
    .main-slider-one2__title {
      font-size: 40px;
    }
  }
  .main-slider-one2__title::after {
    content: "";
    width: 101%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 100%;
    // background: currentColor;
    transition: 1s cubic-bezier(0.858, 0.01, 0.068, 0.99);
    z-index: 3;
    transform: translateX(-100%);
    transition-delay: 1s;
  }
  .main-slider-one2__btn {
    position: relative;
    z-index: 5;
    overflow: hidden;
    opacity: 0;
    transform: perspective(400px) rotateY(0deg) translateY(80px);
    transform-origin: bottom;
    transition: all 1500ms ease;
  }
  .main-slider-one2__btn .laboix-btn::before {
    background-color: var(--laboix-white, #fff);
  }
  .main-slider-one2__btn .laboix-btn:hover {
    color: var(--laboix-base, #021936);
  }
  .main-slider-one2__shape {
    position: absolute;
  }
  .main-slider-one2__shape--one {
    bottom: 0%;
    left: 0%;
    transition: all 1500ms ease;
    transform: perspective(400px) rotateY(0deg) translateY(80px);
    transform-origin: left;
    // opacity: 0;
    animation: topAni 4s ease-in-out infinite
  }
  @media (max-width: 1199px) {
    .main-slider-one2__shape--one {
      display: none;
    }
  }
  .main-slider-one2__shape--two {
    top: 0%;
    left: 0%;
    transform: perspective(400px) rotateY(0deg) translateY(80px);
    transform-origin: bottom;
    // opacity: 0;
    // animation: topAni 4s ease-in-out infinite;
    transition: all 1500ms ease;
  }
  @keyframes scale3 {
    0% {
      transform: scale(0.9);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.9);
    }
  }
  .main-slider-one2__shape--three {
    bottom: 0;
    right: 0;
    transition: all 1500ms ease;
    animation: topAni 4s ease-in-out infinite;
    transform: perspective(400px) rotateY(0deg) translateY(80px);
    transform-origin: right;
    // opacity: 0;
  }
  @media (max-width: 991px) {
    .main-slider-one2__shape--three {
      display: none;
    }
  }
  .main-slider-one2__shape--fore {
    top: 0;
    left: 0;
    transition: all 1500ms ease;
    transform: perspective(400px) rotateY(0deg) translateY(80px);
    transform-origin: left;
    // opacity: 0;
    animation: topAni 4s ease-in-out infinite;
  }
  @media (max-width: 1199px) {
    .main-slider-one2__shape--fore {
      display: none;
    }
  }
  .main-slider-one2 .active .steap {
    animation: dash 0.5s linear;
  }
  @keyframes dash {
    0% {
      stroke-width: 0;
    }
    100% {
      stroke-width: 200px;
    }
  }
  .main-slider-one2 .active .main-slider-one2__sub-title {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 1100ms;
  }
  .main-slider-one2 .active .main-slider-one2__bg {
    opacity: 1;
    transform: translatex(0) scalex(1);
    filter: blur(0);
    transition-delay: 1000ms;
  }
  .main-slider-one2 .active .main-slider-one2__shape--two {
    transform: perspective(400px) rotateY(0deg) translateY(80px);
    transition-delay: 1700ms;
    animation: scale3 6s ease-in-out infinite;
    opacity: 1;
  }
  .main-slider-one2 .active .main-slider-one2__shape--one,
  .main-slider-one2 .active .main-slider-one2__shape--fore {
    transform: perspective(400px) rotateY(0deg) translateY(0px);
    transition-delay: 1700ms;
    opacity: 1;
  }
  .main-slider-one2 .active .main-slider-one2__shape--three {
    transform: perspective(400px) rotateY(0deg) translateY(0px);
    transition-delay: 1700ms;
    animation: topAni 4s ease-in-out infinite;
    opacity: 1;
  }
  .main-slider-one2 .active .main-slider-one2__title {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 1300ms;
  }
  .main-slider-one2 .active .main-slider-one2__title::after {
    transform: translateX(1%);
    transition-delay: 1500ms;
  }
  .main-slider-one2 .active .main-slider-one2__btn {
    opacity: 1;
    transform: perspective(400px) rotateY(0deg) translateY(0px);
    transition-delay: 1700ms;
  }
  
  .main-slider-one1 {
    position: relative;
  }
  .main-slider-one1__carousel {
    position: relative;
    width: 100%;
  }
  .main-slider-one1__carousel .owl-dots {
    display: flex;
    gap: 10px;
    position: absolute;
    bottom: 66px;
    left: 19.45%;
  }
  .main-slider-one1__carousel .owl-dots .owl-dot {
    width: 8px;
    border-radius: 50%;
    background-color: rgba(var(--laboix-white-rgb, 255, 255, 255), 0.2);
    height: 8px;
  }
  .main-slider-one1__carousel .owl-dots .owl-dot.active,
  .main-slider-one1__carousel .owl-dots .owl-dot:hover {
    background-color: rgba(var(--laboix-secondary-rgb, 14, 189, 102), 1);
  }
  .main-slider-one1__item {
    background-color: var(--laboix-base, #021936);
    position: relative;
    z-index: 3;
    padding-top: 280px;
    padding-bottom: 250px;
  }
  @media (max-width: 1800px) {
    .main-slider-one1__item {
      padding-top: 150px;
      padding-bottom: 200px;
    }
  }
  @media (max-width: 1199px) {
    .main-slider-one1__item {
      padding-top: 130px;
      padding-bottom: 150px;
    }
  }
  @media (max-width: 991px) {
    .main-slider-one1__item {
      padding-top: 120px;
      padding-bottom: 130px;
    }
  }
  .main-slider-one1__bg {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: url("../../images/14.jpeg");
    background-position: top right;
    transition: transform 1000ms ease, opacity 1000ms ease;
    /* transform: translatex(50%) scalex(2); */
    filter: blur(1px);
    opacity: 46;
  }
  @media (max-width: 991px) {
    .main-slider-one1__bg {
      display: none;
    }
  }
  .main-slider-one1__bg::before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 79%;
    content: "";
    z-index: -2;
    -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 185.000000"> <g transform="translate(0.000000,185.000000) scale(0.050000,-0.050000)"> <path d="M0 1850 l0 -1850 2993 0 2993 0 -37 41 c-21 23 -341 322 -713 664 -372 343 -676 630 -676 637 0 8 148 157 329 331 l329 317 -810 855 -809 855 -1800 0 -1799 0 0 -1850z"/> </g> </svg>');
    mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 185.000000"> <g transform="translate(0.000000,185.000000) scale(0.050000,-0.050000)"> <path d="M0 1850 l0 -1850 2993 0 2993 0 -37 41 c-21 23 -341 322 -713 664 -372 343 -676 630 -676 637 0 8 148 157 329 331 l329 317 -810 855 -809 855 -1800 0 -1799 0 0 -1850z"/> </g> </svg>');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: top center;
    mask-position: top center;
    -webkit-mask-size: auto;
    mask-size: auto;
    background: linear-gradient(180deg, #0EBD66 0%, rgba(14, 189, 102, 0) 100%);
  }
  @media (max-width: 1400px) {
    .main-slider-one1__bg::before {
      width: 100%;
      height: 100%;
    }
  }
  @media (max-width: 991px) {
    .main-slider-one1__bg::before {
      display: none;
    }
  }
  .main-slider-one1__bg::after {
    content: "";
    -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 193.000000"> <g transform="translate(0.000000,193.000000) scale(0.050000,-0.050000)"> <path d="M0 1930 l0 -1930 2990 0 c1645 0 2990 4 2990 8 0 5 -337 320 -748 700 l-748 691 293 284 c161 156 319 308 350 338 l57 53 -846 893 -845 893 -1746 0 -1747 0 0 -1930z"/> </g> </svg>');
    mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 193.000000"> <g transform="translate(0.000000,193.000000) scale(0.050000,-0.050000)"> <path d="M0 1930 l0 -1930 2990 0 c1645 0 2990 4 2990 8 0 5 -337 320 -748 700 l-748 691 293 284 c161 156 319 308 350 338 l57 53 -846 893 -845 893 -1746 0 -1747 0 0 -1930z"/> </g> </svg>');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: top center;
    mask-position: top center;
    -webkit-mask-size: auto;
    mask-size: auto;
    position: absolute;
    left: 0;
    top: 0;
    width: 75%;
    height: 101%;
    z-index: -1;
    background-color: var(--laboix-base, #021936);
  }
  @media (max-width: 1400px) {
    .main-slider-one1__bg::after {
      width: 95%;
      height: 100%;
    }
  }
  @media (max-width: 991px) {
    .main-slider-one1__bg::after {
      display: none;
    }
  }
  .main-slider-one1__content {
    position: relative;
    display: inline-block;
    z-index: 3;
    animation: topAni 4s ease-in-out infinite;
    overflow: hidden;
  }
  .main-slider-one1__sub-title {
    color: var(--laboix-secondary, #0EBD66);
    font-family: var(--laboix-font, "Lexend", sans-serif);
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    margin: -7px 0 10px;
    transition: transform 1000ms ease, opacity 1000ms ease;
    transform: translateY(-200px);
  }
  .main-slider-one1__title {
    color: var(--laboix-white, #fff);
    display: contents;
    overflow: hidden;
    margin: 0 0 17px;
    opacity: 0;
    font-size: 70px;
    font-weight: 600;
    line-height: 114.286%;
    letter-spacing: -2.1px;
    text-shadow: 0 0 1px currentColor;
    transition: transform 1200ms ease, opacity 1200ms ease;
    transform: translateX(200px);
  }
  @media (max-width: 1199px) {
    .main-slider-one1__title {
      font-size: 60px;
    }
  }
  @media (max-width: 991px) {
    .main-slider-one1__title {
      font-size: 50px;
    }
  }
  @media (max-width: 767px) {
    .main-slider-one1__title {
      font-size: 40px;
    }
  }
  .main-slider-one1__title::after {
    content: "";
    width: 101%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 100%;
    // background: currentColor;
    transition: 1s cubic-bezier(0.858, 0.01, 0.068, 0.99);
    z-index: 3;
    transform: translateX(-100%);
    transition-delay: 1s;
  }
  .main-slider-one1__btn {
    position: relative;
    z-index: 5;
    overflow: hidden;
    opacity: 0;
    transform: perspective(400px) rotateY(0deg) translateY(80px);
    transform-origin: bottom;
    transition: all 1500ms ease;
  }
  .main-slider-one1__btn .laboix-btn::before {
    background-color: var(--laboix-white, #fff);
  }
  .main-slider-one1__btn .laboix-btn:hover {
    color: var(--laboix-base, #021936);
  }
  .main-slider-one1__shape {
    position: absolute;
  }
  .main-slider-one1__shape--one {
    bottom: 0%;
    left: 0%;
    transition: all 1500ms ease;
    transform: perspective(400px) rotateY(0deg) translateY(80px);
    transform-origin: left;
    // opacity: 0;
    animation: topAni 4s ease-in-out infinite;
  }
  @media (max-width: 1199px) {
    .main-slider-one1__shape--one {
      display: none;
    }
  }
  .main-slider-one1__shape--two {
    top: 0%;
    left: 0%;
    transform: perspective(400px) rotateY(0deg) translateY(80px);
    transform-origin: bottom;
    // opacity: 0;
    animation: borderScale 4s ease-in-out infinite;
    transition: all 1500ms ease;
  }
  @keyframes scale3 {
    0% {
      transform: scale(0.9);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.9);
    }
  }
  .main-slider-one1__shape--three {
    bottom: 0;
    right: 0;
    transition: all 1500ms ease;
    transform: perspective(400px) rotateY(0deg) translateY(80px);
    transform-origin: right;
    // opacity: 0;
    animation: topAni 4s ease-in-out infinite;
  }
  @media (max-width: 991px) {
    .main-slider-one1__shape--three {
      display: none;
    }
  }
  .main-slider-one1__shape--fore {
    top: 0;
    left: 0;
    transition: all 1500ms ease;
    transform: perspective(400px) rotateY(0deg) translateY(80px);
    transform-origin: left;
    // opacity: 0;
    animation: topAni 4s ease-in-out infinite;
  }
  @media (max-width: 1199px) {
    .main-slider-one1__shape--fore {
      display: none;
    }
  }
  .main-slider-one1 .active .steap {
    animation: dash 0.5s linear;
  }
  @keyframes dash {
    0% {
      stroke-width: 0;
    }
    100% {
      stroke-width: 200px;
    }
  }
  .main-slider-one1 .active .main-slider-one1__sub-title {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 1100ms;
  }
  .main-slider-one1 .active .main-slider-one1__bg {
    opacity: 1;
    transform: translatex(0) scalex(1);
    filter: blur(0);
    transition-delay: 1000ms;
  }
  .main-slider-one1 .active .main-slider-one1__shape--two {
    transform: perspective(400px) rotateY(0deg) translateY(80px);
    transition-delay: 1700ms;
    animation: scale3 6s ease-in-out infinite;
    opacity: 1;
  }
  .main-slider-one1 .active .main-slider-one1__shape--one,
  .main-slider-one1 .active .main-slider-one1__shape--fore {
    transform: perspective(400px) rotateY(0deg) translateY(0px);
    transition-delay: 1700ms;
    opacity: 1;
  }
  .main-slider-one1 .active .main-slider-one1__shape--three {
    transform: perspective(400px) rotateY(0deg) translateY(0px);
    transition-delay: 1700ms;
    animation: topAni 4s ease-in-out infinite;
    opacity: 1;
  }
  .main-slider-one1 .active .main-slider-one1__title {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 1300ms;
  }
  .main-slider-one1 .active .main-slider-one1__title::after {
    transform: translateX(1%);
    transition-delay: 1500ms;
  }
  .main-slider-one1 .active .main-slider-one1__btn {
    opacity: 1;
    transform: perspective(400px) rotateY(0deg) translateY(0px);
    transition-delay: 1700ms;
  }
  .main-slider-one3 {
    position: relative;
  }
  .main-slider-one3__carousel {
    position: relative;
    width: 100%;
  }
  .main-slider-one3__carousel .owl-dots {
    display: flex;
    gap: 10px;
    position: absolute;
    bottom: 66px;
    left: 19.45%;
  }
  .main-slider-one3__carousel .owl-dots .owl-dot {
    width: 8px;
    border-radius: 50%;
    background-color: rgba(var(--laboix-white-rgb, 255, 255, 255), 0.2);
    height: 8px;
  }
  .main-slider-one3__carousel .owl-dots .owl-dot.active,
  .main-slider-one3__carousel .owl-dots .owl-dot:hover {
    background-color: rgba(var(--laboix-secondary-rgb, 14, 189, 102), 1);
  }
  .main-slider-one3__item {
    background-color: var(--laboix-base, #021936);
    position: relative;
    z-index: 3;
    padding-top: 280px;
    padding-bottom: 250px;
  }
  @media (max-width: 1800px) {
    .main-slider-one3__item {
      padding-top: 150px;
      padding-bottom: 200px;
    }
  }
  @media (max-width: 1199px) {
    .main-slider-one3__item {
      padding-top: 130px;
      padding-bottom: 150px;
    }
  }
  @media (max-width: 991px) {
    .main-slider-one3__item {
      padding-top: 120px;
      padding-bottom: 130px;
    }
  }
  .main-slider-one3__bg {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: url("../../images/backgrounds/T1.jpeg");
    background-position: top right;
    transition: transform 1000ms ease, opacity 1000ms ease;
    /* transform: translatex(50%) scalex(2); */
    filter: blur(1px);
    opacity: 46;
  }
  @media (max-width: 991px) {
    .main-slider-one3__bg {
      display: none;
    }
  }
  .main-slider-one3__bg::before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 79%;
    content: "";
    z-index: -2;
    -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 185.000000"> <g transform="translate(0.000000,185.000000) scale(0.050000,-0.050000)"> <path d="M0 1850 l0 -1850 2993 0 2993 0 -37 41 c-21 23 -341 322 -713 664 -372 343 -676 630 -676 637 0 8 148 157 329 331 l329 317 -810 855 -809 855 -1800 0 -1799 0 0 -1850z"/> </g> </svg>');
    mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 185.000000"> <g transform="translate(0.000000,185.000000) scale(0.050000,-0.050000)"> <path d="M0 1850 l0 -1850 2993 0 2993 0 -37 41 c-21 23 -341 322 -713 664 -372 343 -676 630 -676 637 0 8 148 157 329 331 l329 317 -810 855 -809 855 -1800 0 -1799 0 0 -1850z"/> </g> </svg>');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: top center;
    mask-position: top center;
    -webkit-mask-size: auto;
    mask-size: auto;
    background: linear-gradient(180deg, #0EBD66 0%, rgba(14, 189, 102, 0) 100%);
  }
  @media (max-width: 1400px) {
    .main-slider-one3__bg::before {
      width: 100%;
      height: 100%;
    }
  }
  @media (max-width: 991px) {
    .main-slider-one3__bg::before {
      display: none;
    }
  }
  .main-slider-one3__bg::after {
    content: "";
    -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 193.000000"> <g transform="translate(0.000000,193.000000) scale(0.050000,-0.050000)"> <path d="M0 1930 l0 -1930 2990 0 c1645 0 2990 4 2990 8 0 5 -337 320 -748 700 l-748 691 293 284 c161 156 319 308 350 338 l57 53 -846 893 -845 893 -1746 0 -1747 0 0 -1930z"/> </g> </svg>');
    mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 193.000000"> <g transform="translate(0.000000,193.000000) scale(0.050000,-0.050000)"> <path d="M0 1930 l0 -1930 2990 0 c1645 0 2990 4 2990 8 0 5 -337 320 -748 700 l-748 691 293 284 c161 156 319 308 350 338 l57 53 -846 893 -845 893 -1746 0 -1747 0 0 -1930z"/> </g> </svg>');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: top center;
    mask-position: top center;
    -webkit-mask-size: auto;
    mask-size: auto;
    position: absolute;
    left: 0;
    top: 0;
    width: 75%;
    height: 101%;
    z-index: -1;
    background-color: var(--laboix-base, #021936);
  }
  @media (max-width: 1400px) {
    .main-slider-one3__bg::after {
      width: 95%;
      height: 100%;
    }
  }
  @media (max-width: 991px) {
    .main-slider-one3__bg::after {
      display: none;
    }
  }
  .main-slider-one3__content {
    position: relative;
    display: inline-block;
    z-index: 3;
    overflow: hidden;
    animation: topAni 4s ease-in-out infinite;
  }
  .main-slider-one3__sub-title {
    color: var(--laboix-secondary, #0EBD66);
    font-family: var(--laboix-font, "Lexend", sans-serif);
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    margin: -7px 0 10px;
    transition: transform 1000ms ease, opacity 1000ms ease;
    transform: translateY(-200px);
  }
  .main-slider-one3__title {
    color: var(--laboix-white, #fff);
    display: contents;
    overflow: hidden;
    margin: 0 0 17px;
    opacity: 0;
    font-size: 70px;
    font-weight: 600;
    line-height: 114.286%;
    letter-spacing: -2.1px;
    text-shadow: 0 0 1px currentColor;
    transition: transform 1200ms ease, opacity 1200ms ease;
    transform: translateX(200px);
  }
  @media (max-width: 1199px) {
    .main-slider-one3__title {
      font-size: 60px;
    }
  }
  @media (max-width: 991px) {
    .main-slider-one3__title {
      font-size: 50px;
    }
  }
  @media (max-width: 767px) {
    .main-slider-one3__title {
      font-size: 40px;
    }
  }
  .main-slider-one3__title::after {
    content: "";
    width: 101%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 100%;
    // background: currentColor;
    transition: 1s cubic-bezier(0.858, 0.01, 0.068, 0.99);
    z-index: 3;
    transform: translateX(-100%);
    transition-delay: 1s;
  }
  .main-slider-one3__btn {
    position: relative;
    z-index: 5;
    overflow: hidden;
    opacity: 0;
    transform: perspective(400px) rotateY(0deg) translateY(80px);
    transform-origin: bottom;
    transition: all 1500ms ease;
  }
  .main-slider-one3__btn .laboix-btn::before {
    background-color: var(--laboix-white, #fff);
  }
  .main-slider-one3__btn .laboix-btn:hover {
    color: var(--laboix-base, #021936);
  }
  .main-slider-one3__shape {
    position: absolute;
  }
  .main-slider-one3__shape--one {
    bottom: 0%;
    left: 0%;
    transition: all 1500ms ease;
    transform: perspective(400px) rotateY(0deg) translateY(80px);
    transform-origin: left;
    // opacity: 0;
    animation: topAni 4s ease-in-out infinite;
  }
  @media (max-width: 1199px) {
    .main-slider-one3__shape--one {
      display: none;
    }
  }
  .main-slider-one3__shape--two {
    top: 0%;
    left: 0%;
    transform: perspective(400px) rotateY(0deg) translateY(80px);
    transform-origin: bottom;
    // opacity: 0;
    animation: borderScale 4s ease-in-out infinite;
    transition: all 1500ms ease;
  }
  @keyframes scale3 {
    0% {
      transform: scale(0.9);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.9);
    }
  }
  .main-slider-one3__shape--three {
    bottom: 0;
    right: 0;
    transition: all 1500ms ease;
    transform: perspective(400px) rotateY(0deg) translateY(80px);
    transform-origin: right;
    // opacity: 0;
    animation: topAni 4s ease-in-out infinite;
  }
  @media (max-width: 991px) {
    .main-slider-one3__shape--three {
      display: none;
    }
  }
  .main-slider-one3__shape--fore {
    top: 0;
    left: 0;
    transition: all 1500ms ease;
    transform: perspective(400px) rotateY(0deg) translateY(80px);
    transform-origin: left;
    // opacity: 0;
    animation: topAni 4s ease-in-out infinite;
  }
  @media (max-width: 1199px) {
    .main-slider-one3__shape--fore {
      display: none;
    }
  }
  .main-slider-one3 .active .steap {
    animation: dash 0.5s linear;
  }
  @keyframes dash {
    0% {
      stroke-width: 0;
    }
    100% {
      stroke-width: 200px;
    }
  }
  .main-slider-one3 .active .main-slider-one3__sub-title {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 1100ms;
  }
  .main-slider-one3 .active .main-slider-one3__bg {
    opacity: 1;
    transform: translatex(0) scalex(1);
    filter: blur(0);
    transition-delay: 1000ms;
  }
  .main-slider-one3 .active .main-slider-one3__shape--two {
    transform: perspective(400px) rotateY(0deg) translateY(80px);
    transition-delay: 1700ms;
    animation: scale3 6s ease-in-out infinite;
    opacity: 1;
  }
  .main-slider-one3 .active .main-slider-one3__shape--one,
  .main-slider-one3 .active .main-slider-one3__shape--fore {
    transform: perspective(400px) rotateY(0deg) translateY(0px);
    transition-delay: 1700ms;
    opacity: 1;
  }
  .main-slider-one3 .active .main-slider-one3__shape--three {
    transform: perspective(400px) rotateY(0deg) translateY(0px);
    transition-delay: 1700ms;
    animation: topAni 4s ease-in-out infinite;
    opacity: 1;
  }
  .main-slider-one3 .active .main-slider-one3__title {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 1300ms;
  }
  .main-slider-one3 .active .main-slider-one3__title::after {
    transform: translateX(1%);
    transition-delay: 1500ms;
  }
  .main-slider-one3 .active .main-slider-one3__btn {
    opacity: 1;
    transform: perspective(400px) rotateY(0deg) translateY(0px);
    transition-delay: 1700ms;
  }
  