.p-component{
    font-size: 13px;
    margin:10px !important
}
.my-5 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}
.p-5 {
    padding: 15px !important;
}
.custom-upload-btn,.custom-cancel-btn{
    width: fit-content !important;
    padding: 5px !important;
}
.p-fileupload .p-fileupload-content {
    background: #ffffff;
    padding: 0rem 1rem !important;
    border: 1px solid #e5e7eb;
    color: #4b5563;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}
.p-button{
    background:#1d7584
}
.p-button.p-button-outlined,.p-button{
    border: none !important;
    padding: 0px !important;
}
.p-button .p-button-icon-left{
    margin-left: 0.5rem;
    margin-right: 0.1rem;
}
.p-button-label{
    padding: 5px;
    padding-right: 10px;
}
.p-component{
    border-radius: 5px;
}
.p-fileupload-content > .p-progressbar{
    display: none;
}
.p-fileupload-row > div{
    width: unset;
}
.p-badge{
    display: none;
}
.p-button.p-button-danger.p-button-text, .p-button-group.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text, .p-fileupload-choose.p-button-danger.p-button-text{
    background: transparent !important;
}