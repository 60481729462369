.matrix-container {
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(50px, 1fr)); */
    gap: 1px;
  }
  
  .matrix-row {
    display: flex;
  }
  
  .matrix-cell1 {
    background: blue;
    width: 200px;
    height: 200px;
    align-content: center;
    text-align: center;
  }
  .matrix-cell2 {
    background: pink;
width: 200px;
height: 200px;
align-content: center;
text-align: center;
  }