/* @font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?dx5nct');
  src:  url('fonts/icomoon.eot?dx5nct#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?dx5nct') format('truetype'),
    url('fonts/icomoon.woff?dx5nct') format('woff'),
    url('fonts/icomoon.svg?dx5nct#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
} */

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clock1:before {
  content: "\e92c";
}
.icon-quite:before {
  content: "\e92b";
}
.icon-checked:before {
  content: "\e92a";
}
.icon-play:before {
  content: "\e929";
}
.icon-left-arrow:before {
  content: "\e928";
}
.icon-email:before {
  content: "\e927";
}
.icon-mark2:before {
  content: "\e926";
}
.icon-chat2:before {
  content: "\e925";
}
.icon-clock-1:before {
  content: "\e924";
}
.icon-user:before {
  content: "\e91c";
}
.icon-chatting2:before {
  content: "\e91d";
}
.icon-right-arrow1:before {
  content: "\e91e";
}
.icon-arrow:before {
  content: "\e91f";
}
.icon-shear:before {
  content: "\e920";
}
.icon-chatting:before {
  content: "\e921";
}
.icon-trolley:before {
  content: "\e922";
}
.icon-search:before {
  content: "\e923";
}
.icon-email1:before {
  content: "\e900";
}
.icon-pin:before {
  content: "\e901";
}
.icon-accuracy-1::before {
  content: "\e902";
}
.icon-artificial-intelligence-2:before {
  content: "\e903";
}
.icon-blood-test-1:before {
  content: "\e904";
}
.icon-case:before {
  content: "\e905";
}
.icon-chatting-1:before {
  content: "\e906";
}
.icon-chemistry-1:before {
  content: "\e907";
}
.icon-clients:before {
  content: "\e908";
}
.icon-clock-11:before {
  content: "\e909";
}
.icon-diagonostic:before {
  content: "\e90a";
}
.icon-dna-1:before {
  content: "\e90b";
}
.icon-experiment-results-1:before {
  content: "\e90c";
}
.icon-flask:before {
  content: "\e90d";
}
.icon-medical-assistance-2:before {
  content: "\e90e";
}
.icon-microscope-1:before {
  content: "\e90f";
}
.icon-microscope:before {
  content: "\e910";
}
.icon-pathology-2-1:before {
  content: "\e911";
}
.icon-research-1:before {
  content: "\e912";
}
.icon-right-arrow:before {
  content: "\e913";
}
.icon-safe:before {
  content: "\e914";
}
.icon-share-1:before {
  content: "\e915";
}
.icon-target-1:before {
  content: "\e916";
}
.icon-telephone-call-1:before {
  content: "\e917";
}
.icon-test-tube-1:before {
  content: "\e918";
}
.icon-test-tubes-1:before {
  content: "\e919";
}
.icon-trophy-1:before {
  content: "\e91a";
}
.icon-trophy-2:before {
  content: "\e91b";
}
